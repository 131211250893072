import * as settingApi from '2_services/settingsApi';
import _ from 'lodash';
import {
  getSettingFromServer,
  resetSetting,
  saveToSettingDone,
} from '1_reduxs/reducers/settingReducer';

export function initSettingThunk() {
  return async (dispatch, getState) => {
    try {
      const res = await settingApi.fetchSettingsV2();

      // if empty setting, save default setting
      if (_.isEmpty(res.data)) {
        console.log('initial settings');
        const setting = getState().setting;
        await settingApi.saveSettingV2(setting);
      } else {
        dispatch(getSettingFromServer({ customROI: res.data }));
      }
    } catch (err) {
      // const res = await settingApi.saveSettingV2(setting);
      console.error(err);
    }
  };
}
export function saveSettingServerThunk() {
  return async (dispatch, getState) => {
    try {
      const setting = getState().setting;
      await settingApi.saveSettingV2(setting);
      dispatch(saveToSettingDone());
    } catch (err) {
      alert('Failed to save the settings.');
    }
  };
}
export function resetSettingThunk(productName) {
  return async (dispatch, getState) => {
    dispatch(resetSetting({ productName }));
    try {
      const setting = getState().setting;
      await settingApi.saveSettingV2(setting);
    } catch (err) {
      alert('Failed to save the settings.');
    }
  };
}
