import React, { useState } from 'react';
import styles from './ReportPage2.module.scss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { AnalysisTableForm } from '0_variables/analysisConfigs';
import {
  reportAmyloidDATtable2,
  createReportTableData1,
} from '0_variables/utils';
import { QuantTable, ReportGraph } from '5_components';
import { reportIcon2 as MEDIA_reportIcon2 } from '6_media';
import ReportLayout from '../ReportLayout/ReportLayout';
import Watermark from '../watermark';

export default function ReportPage2(props) {
  const { selectedFile, fileID, pageNumber, pageLength, RF } = props;
  const { product: productName } = useParams();
  const { type1Selector, type2Selector } = coreItem[productName];
  const settingOfProduct = useSelector((state) => state.setting[productName]);
  const [trClick, setTrClick] = useState([]);
  const [sortClick, setSortClick] = useState({});
  const defaultAtlas = settingOfProduct.defaultAtlas;

  const isShowFirstTable =
    pageLength !== 4 || pageNumber.split('/').at(0) !== '3';

  const isShowSecondTable =
    (props.pageLength !== 4 || props.pageNumber.split('/').at(0) !== '2') &&
    ['amyloid', 'dat'].includes(productName) &&
    selectedFile['Tracer'] !== '[18F]FPN';

  // NOTE amyloid, dat, fdg, tau, perfusion 모두 같은 table head를 사용하므로 title을 이용하여 table head를 가져옴
  const table1TableHead = AnalysisTableForm.find(
    ({ title }) => title === 'Regional SUVR',
  ).tableHead;

  const table1Data = isShowFirstTable
    ? createReportTableData1(productName, {
        settingOfProduct: settingOfProduct,
        typeSelect: type1Selector,
        tableItems: { tableHead: table1TableHead },
        selectedFile: selectedFile,
        defaultAtlas: defaultAtlas,
        RF: RF,
      })
    : { tableHead: [], items: [] };

  const table2TableHead = AnalysisTableForm.find(
    ({ title }) => title === 'Regional Centiloid',
  ).tableHead;

  const table2Data = isShowSecondTable
    ? reportAmyloidDATtable2({
        settingOfProduct: settingOfProduct,
        typeSelect: type2Selector,
        tableItems: { tableHead: table2TableHead },
        selectedFile: selectedFile,
        defaultAtlas: defaultAtlas,
        RF: RF,
      })
    : { tableHead: [], items: [] };

  return (
    <ReportLayout
      pageNumber={pageNumber}
      selectedFile={selectedFile}
      PatientID={selectedFile.PatientID}
    >
      <Watermark />
      <div className={styles.QuantContainer}>
        <div className={styles.ReportTitle}>
          <img src={MEDIA_reportIcon2} alt="" />
          &nbsp;{'Quantification Results'}
        </div>

        {isShowFirstTable && (
          <>
            <ReportGraph
              productName={productName}
              selectedFile={selectedFile}
              fileID={fileID}
              RF={RF}
            />
            <div className={styles.typeTableWrapper}>
              <div className={styles.tableTitle}>
                {productName === 'dat' ? 'Regional SBR' : 'Regional SUVR'}
              </div>
              <div className={`report_suvr_table ${styles.tableContainer}`}>
                <QuantTable
                  eventEnabled={false}
                  tableHead={table1Data.tableHead}
                  items={table1Data.items}
                  trClick={trClick}
                  setTrClick={setTrClick}
                  sortClick={sortClick}
                  setSortClick={setSortClick}
                  reportTooltip={false}
                />
              </div>
            </div>
          </>
        )}

        {isShowSecondTable && (
          <div className={styles.typeTableWrapper}>
            <div className={styles.tableTitle}>
              {productName === 'amyloid'
                ? 'Regional Centiloid'
                : 'Semiquantification'}
            </div>
            <div className={`report_second_table ${styles.tableContainer}`}>
              <QuantTable
                eventEnabled={false}
                tableHead={table2Data.tableHead}
                items={table2Data.items}
                trClick={trClick}
                setTrClick={setTrClick}
                sortClick={sortClick}
                setSortClick={setSortClick}
              />
            </div>
          </div>
        )}
      </div>
    </ReportLayout>
  );
}
