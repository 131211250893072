import axios from 'axios';

export const fetchSettingsV2 = (data) => {
  return axios.get('testing/setting_v2/');
};

export function saveSettingV2(obj) {
  return axios.put('testing/setting_v2/', obj);
}

export const fetchSettings = async () => {
  return axios.get('testing/setting/');
};

export function saveSetting(obj) {
  return axios.put('testing/setting/', obj);
}
