import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import chroma from 'chroma-js';
import { RiArrowLeftSFill } from 'react-icons/ri';
import { CiImageOff } from 'react-icons/ci';
import { dequeueImage, enqueueImage } from '1_reduxs/reducers/modalReducer';
import {
  colormapJet as MEDIA_colormapJet,
  reportIcon3 as MEDIA_reportIcon3,
} from '6_media';
import styles from './ReportPage3.module.scss';
import ReportLayout from '../ReportLayout/ReportLayout';

// NOTE pdf export기능에 사용되는 html2canvas를 위해 이미지를 blob으로 변환하는 함수
// img url이 domain이 다른경우 capture한 canvas에 이미지가 표시가 안되는 문제가 있음
function ImageBlob({
  altDesc,
  imgURL,
  decrementImagesToLoad,
  invertColor = false,
}) {
  const [imgSrc, setImgSrc] = useState(null);

  function invertImageColors(imgSrc, callback) {
    const img = new Image();
    img.src = imgSrc;
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0, img.width, img.height);

      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        data[i] = 255 - data[i]; // red
        data[i + 1] = 255 - data[i + 1]; // green
        data[i + 2] = 255 - data[i + 2]; // blue
      }

      ctx.putImageData(imageData, 0, 0);

      callback(canvas.toDataURL());
    };
  }

  useEffect(() => {
    fetch(imgURL)
      .then((response) => {
        if (!response.ok) {
          decrementImagesToLoad();
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        decrementImagesToLoad();

        const reader = new FileReader();
        reader.onloadend = () => {
          if (invertColor) {
            invertImageColors(reader.result, setImgSrc);
          } else {
            setImgSrc(reader.result);
          }
        };

        reader.readAsDataURL(blob);
      });
  }, [decrementImagesToLoad, imgURL, invertColor]);

  return imgSrc ? (
    <img src={imgSrc} alt={altDesc} />
  ) : (
    <div className={styles.skeletonDiv}>
      <CiImageOff size={'50%'} color="rgb(157 157 157 / 34%)" />
      <div className={styles.skeletonText}>
        No Image
        <br />
        Available
      </div>
    </div>
  );
}

const ColorbarContainer = ({ direction, refName }) => {
  const hotColorSample = chroma.scale([
    '#fffedf',
    '#ffff3d',
    '#ffc700',
    '#ff6100',
    '#fe3c00',
  ]);
  const hotCmap = [...Array(101).keys()].map((v) =>
    hotColorSample(v / 100).toString(),
  );
  const hotCmapStr = hotCmap.join(',').toString();

  const sliceRefScale = {
    cg: { min: 0.8, max: 2.6 },
    wc: { min: 0.6, max: 1.9 },
    pons: { min: 0.4, max: 1.3 },
    wm: { min: 0.4, max: 1.3 },
  };

  const surfaceRefScale = {
    cg: { max: 3.0, min: 1.6 },
    wc: { max: 2.25, min: 1.2 },
    pons: { max: 1.5, min: 0.8 },
    wm: { max: 1.5, min: 0.8 },
  };

  const RefScale = direction === 'surface' ? surfaceRefScale : sliceRefScale;

  return (
    <div className={styles.colorbarContainer}>
      <div className={styles.colorbar}>
        {direction !== 'surface' && (
          <img src={MEDIA_colormapJet} alt="jet colormap" />
        )}

        {direction === 'surface' && (
          <div
            style={{
              background: `linear-gradient(to bottom, ${hotCmapStr})`,
              width: '15px',
              height: '250px',
              borderRadius: '10px',
            }}
          />
        )}

        <div className={styles.colorbarLegned}>
          <div>
            <RiArrowLeftSFill size={'25px'} />
            {RefScale[refName]?.max}
          </div>
          <div>
            <RiArrowLeftSFill size={'25px'} />
            {RefScale[refName]?.min}
          </div>
        </div>
      </div>
      <div className={styles.colorbarLabel}>SUVR</div>
    </div>
  );
};

const ImageContainers = ({
  name,
  refName,
  imageURLs,
  direction,
  decrementImagesToLoad,
}) => {
  return (
    <div className={`${name} ${styles.imageWrapper}`}>
      <div className={styles.imageContent}>
        {imageURLs.map((imageURLRow, index) => (
          <div key={`iamges_row${index}`} className={styles.imageRow}>
            {imageURLRow.map((imagURL, nestedIdx) => (
              <div key={`image_${nestedIdx}`} className={styles.imageBox}>
                <ImageBlob
                  imgURL={imagURL}
                  altDesc={`axial ${nestedIdx}`}
                  decrementImagesToLoad={decrementImagesToLoad}
                />
              </div>
            ))}
          </div>
        ))}
      </div>

      <ColorbarContainer refName={refName} direction={direction} />
    </div>
  );
};

const MipContainer = ({ fileID, decrementImagesToLoad }) => {
  const getImagePath = (sliceID) => {
    return `${process.env.REACT_APP_BASE_URL}result/download/_imgs_archive/${fileID}/output_mip_${sliceID}.png`;
  };

  const mipImageURLs = [5, 10, 15, 20, 25, 30, 35, 40].map((sliceID) =>
    getImagePath(sliceID),
  );

  return (
    <div className={`mip_image ${styles.MIPImageContainer}`}>
      {mipImageURLs.map((imagURL, index) => (
        <ImageBlob
          key={`mip${index}`}
          imgURL={imagURL}
          altDesc={`mip_${index}`}
          invertColor={true}
          decrementImagesToLoad={decrementImagesToLoad}
        />
      ))}
    </div>
  );
};

const SurfaceContainer = ({
  name,
  fileID,
  username,
  refName,
  decrementImagesToLoad,
}) => {
  const getImgPath = (direction) => {
    return `${process.env.REACT_APP_BASE_URL}result/download/${username}/database/${fileID}/_${direction}_1.6.png`;
  };

  const surfaceImageURLs = [
    ['rlat', 'rmed'],
    ['llat', 'lmed'],
  ].map((sliceIDRow) => sliceIDRow.map((driection) => getImgPath(driection)));

  return (
    <>
      <div className={styles.imageTitle}>SUVR rendered on brain surface</div>
      <ImageContainers
        name={name}
        refName={refName}
        imageURLs={surfaceImageURLs}
        direction="surface"
        decrementImagesToLoad={decrementImagesToLoad}
      />
    </>
  );
};

export default function ReportPage3(props) {
  const { refName, selectedFile, pageNumber, isReportWrapper } = props;
  const dispatch = useDispatch();
  const username = sessionStorage.getItem('username');
  const { product: productName } = useParams();
  const fileID = selectedFile.fileID;
  // dat = axial : 5 + mip : 8, others = axial : 8 + surface : 4
  const imageToLoadRef = useRef(productName === 'dat' ? 13 : 12);

  const decrementImagesToLoad = useCallback(() => {
    imageToLoadRef.current = imageToLoadRef.current - 1;

    if (imageToLoadRef.current === 0) {
      dispatch(dequeueImage({ fileID, isReportWrapper }));
    }
  }, [dispatch, fileID, isReportWrapper]);

  useEffect(() => {
    // dat = axial : 5 + mip : 8, others = axial : 8 + surface : 4
    imageToLoadRef.current = productName === 'dat' ? 13 : 12;
    dispatch(enqueueImage(fileID));
  }, [dispatch, fileID, productName]);

  const petImageTitles = {
    amyloid: 'Amyloid-beta PET Image',
    dat: 'DAT PET Image',
    fdg: 'FDG PET Image',
    tau: 'Tau PET Image',
    perfusion: 'Perfusion Image',
  };

  const imageTitles = {
    amyloid: 'Spatially normalized SUVR Image',
    dat: 'Spatially normalized SBR image',
    fdg: 'Spatially normalized SUVR Image',
    tau: 'Spatially normalized SUVR Image',
    perfusion: 'Spatially normalized SUVR Image',
  };

  let axialSliceIDs = [
    [60, 55, 50, 45],
    [40, 35, 30, 25],
  ];

  if (productName === 'dat') {
    axialSliceIDs = [[40, 37, 34, 31, 28]];
  }

  const getImagePath = (sliceID) => {
    return `${process.env.REACT_APP_BASE_URL}result/download/_imgs_archive/${fileID}/report_output_axial_${sliceID}.png`;
  };

  const axialImageURLs = axialSliceIDs.map((sliceIDRow) =>
    sliceIDRow.map((sliceID) => getImagePath(sliceID)),
  );

  return (
    <ReportLayout
      pageNumber={pageNumber}
      selectedFile={selectedFile}
      PatientID={selectedFile.PatientID}
    >
      {/* <Watermark /> */}
      <div className={styles.report3_content}>
        <div className={styles.ReportTitle}>
          <img src={MEDIA_reportIcon3} alt="" />
          &nbsp;{petImageTitles[productName]}
        </div>
        <div className={styles.imageTitle}>{imageTitles[productName]}</div>

        {/* Axial Slice */}
        <ImageContainers
          name={'axial_image'}
          refName={refName}
          imageURLs={axialImageURLs}
          direction="axial"
          decrementImagesToLoad={decrementImagesToLoad}
        />

        {/* mip slice */}
        {productName === 'dat' && (
          <MipContainer
            fileID={fileID}
            decrementImagesToLoad={decrementImagesToLoad}
          />
        )}

        {/* Brain Surface */}
        {productName !== 'dat' && (
          <SurfaceContainer
            name={'surface_image'}
            fileID={selectedFile.fileID}
            refName={refName}
            username={username}
            decrementImagesToLoad={decrementImagesToLoad}
          />
        )}
      </div>
    </ReportLayout>
  );
}
