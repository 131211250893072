import React from 'react';
import './Watermark.scss'; // 워터마크 스타일을 위한 CSS 파일 import

const Watermark = ({ text }) => {
  const logoName = process.env.REACT_APP_LOGO_NAME;
  if (logoName && logoName.length > 0) {
    return (
      <div className="watermark-container">
        <img src="/watermark.png" alt="watermark" className="watermark" />
        {/* <p className="watermark-text">{text}</p> */}
      </div>
    );
  }

  return null;
};

export default Watermark;
