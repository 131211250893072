import React from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlinePivotTableChart, MdPlayArrow } from 'react-icons/md';
import { TfiLink, TfiUnlink } from 'react-icons/tfi';
import { coreItem } from '0_variables/coreItem';
import { updateViewOption } from '1_reduxs/reducers/viewOptionReducer';
import {
  viewCrosshairOff as MEDIA_viewCrosshairOff,
  viewCrosshairOn as MEDIA_viewCrosshairOn,
  viewSnOff as MEDIA_viewSnOff,
  viewSnOn as MEDIA_viewSnOn,
  viewInvertOff as MEDIA_viewInvertOff,
  viewInvertOn as MEDIA_viewInvertOn,
} from '6_media';
import styles from './../ViewControlPanel.module.scss';
import '5_components/14_InputRange/InputRange.scss';

const ViewCtrlBtnGroup = (props) => {
  const { isSubtractMode, iconScaleUp, iconScaleReset } = props;
  const dispatch = useDispatch();
  const {
    showCrosshair,
    showNormalized,
    showInverted,
    layoutPivot,
    syncCrosshair,
  } = useSelector((state) => state.viewOption);

  const { product: productName } = useParams();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;

  const changeViewOption = (optionName, updateValue) => {
    dispatch(
      updateViewOption({
        optionName,
        updateValue,
      }),
    );
  };

  return (
    <div
      className={styles.btnGroup}
      style={{
        width: isSubtractMode ? '18%' : '10%',
        gap: '0.7vw',
      }}
    >
      <span
        onClick={() => changeViewOption('showCrosshair', !showCrosshair)}
        className={`${styles.reactIcon} tooltip-target`}
        style={{ width: '1.7vw', height: '1.7vw' }}
        data-tooltip-content={
          showCrosshair ? 'Show Crosshair' : 'Hide Crosshair'
        }
        role="button"
        aria-label="crosshair on/off button"
      >
        <img
          src={showCrosshair ? MEDIA_viewCrosshairOn : MEDIA_viewCrosshairOff}
          style={{ width: '80%', height: '80%' }}
          alt=""
          className="reactIcon"
          onMouseEnter={iconScaleUp}
          onMouseLeave={iconScaleReset}
        />
      </span>
      {!CTN_Mode && (
        <span
          onClick={() => changeViewOption('showNormalized', !showNormalized)}
          className={`${styles.reactIcon} tooltip-target`}
          style={{ width: '1.7vw', height: '1.7vw' }}
          data-tooltip-content={showNormalized ? 'Normalized' : 'Original'}
          role="button"
          aria-label="normalize on/off button"
        >
          <img
            src={showNormalized ? MEDIA_viewSnOn : MEDIA_viewSnOff}
            style={{ width: '80%', height: '80%' }}
            alt=""
            className="reactIcon"
            onMouseEnter={iconScaleUp}
            onMouseLeave={iconScaleReset}
          />
        </span>
      )}
      {!CTN_Mode && isSubtractMode && (
        <>
          <span
            onClick={() => changeViewOption('layoutPivot', !layoutPivot)}
            className={`${styles.reactIcon} tooltip-target`}
            style={{ width: '1.7vw', height: '1.7vw' }}
            data-tooltip-content={'Rotate'}
            role="button"
            aria-label="Layout Rotate"
          >
            <MdOutlinePivotTableChart
              className="reactIcon"
              color={'white'}
              size={'1.7vw'}
              onMouseEnter={iconScaleUp}
              onMouseLeave={iconScaleReset}
            />
          </span>
          <span
            onClick={() => changeViewOption('syncCrosshair', !syncCrosshair)}
            className={`${styles.reactIcon} tooltip-target`}
            data-tooltip-content={
              syncCrosshair ? 'Synchronized' : 'Unsynchronized'
            }
            role="button"
            aria-label="synchronize Crosshair"
          >
            {syncCrosshair ? (
              <TfiLink
                className="reactIcon"
                color={'white'}
                size={'1.3vw'}
                onMouseEnter={iconScaleUp}
                onMouseLeave={iconScaleReset}
              />
            ) : (
              <TfiUnlink
                className="reactIcon"
                color={'white'}
                size={'1.3vw'}
                onMouseEnter={iconScaleUp}
                onMouseLeave={iconScaleReset}
              />
            )}
          </span>
        </>
      )}

      <span
        onClick={() => changeViewOption('showInverted', !showInverted)}
        className={`${styles.reactIcon} tooltip-target`}
        style={{ width: '1.7vw', height: '1.7vw' }}
        data-tooltip-content={showInverted ? 'Invert' : 'Original'}
        role="button"
        aria-label="invert on/off button"
      >
        <img
          src={showInverted ? MEDIA_viewInvertOn : MEDIA_viewInvertOff}
          style={{ width: '80%', height: '80%' }}
          alt=""
          className="reactIcon"
          onMouseEnter={iconScaleUp}
          onMouseLeave={iconScaleReset}
        />
      </span>
    </div>
  );
};

export default ViewCtrlBtnGroup;
