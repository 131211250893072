import _ from 'lodash';
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { initProductInfo, coreItem } from '0_variables/coreItem';

const IS_ENABLE_PAGINATION = process.env.REACT_APP_ENABLE_PAGINATION === 'true';

// amyloid, dat, fdg, tau, perfusion
const productNames = initProductInfo.map((v) => v.productShortName);
const productTracers = productNames.reduce((acc, productName) => {
  acc[productName] = coreItem[productName].tracers.map(
    (tracer) => tracer.shortname,
  );
  return acc;
}, {});

const getInitialFiles = () =>
  productNames.reduce((acc, productName) => {
    acc[productName] = [];
    return acc;
  }, {});

const filterInit = {
  searchType: 'PatientName', // PatientID, PatientName, AcquisitionDateTime (scan date)
  searchKeyword: '',
};

const paginationInit = {
  orderBy: 'Update',
  orderDirection: 'desc',
  currentPage: 1,
  itemsPerPage: IS_ENABLE_PAGINATION ? 10 : 0, // 0: disable pagination
};

const initialState = {
  currentProduct: 'amyloid', // amyloid, dat, fdg, tau, perfusion
  totalFiles: getInitialFiles(),
  worklistFiles: [],

  /**
   * NOTE : for upload Table
   * 1. uploadSelectFileIds: selected file ids for upload
   * 2. The state previously managed in the component is now stored in the store for compatibility with existing behavior.
   */
  uploadSelectFileIds: [],
  filter: filterInit,
  pagination: paginationInit,

  // for worklist Table
  worklistPagination: paginationInit,
};

const filesSlice = createSlice({
  name: 'fileList',
  initialState,
  reducers: {
    fetchFilesStore: (state, action) => {
      const { items } = action.payload;

      // initial
      state.totalFiles = getInitialFiles();

      items.forEach((item) => {
        const { Tracer } = item;

        const productName = productNames.find((productName) =>
          productTracers[productName].includes(Tracer),
        );

        state.totalFiles[productName].push(item);
      });

      // update worklist
      state.worklistFiles = state.totalFiles[state.currentProduct].filter(
        ({ Group }) => Group !== 0,
      );
    },
    updateFile: (state, action) => {
      const updateFile = action.payload;
      const { fileID } = updateFile;

      state.totalFiles[state.currentProduct] = state.totalFiles[
        state.currentProduct
      ].map((el) => (el.fileID === fileID ? { ...el, ...updateFile } : el));

      // update worklist
      state.worklistFiles = state.totalFiles[state.currentProduct].filter(
        ({ Group }) => Group !== 0,
      );
    },
    removeFiles: (state, action) => {
      const removeFileIds = action.payload;

      state.totalFiles[state.currentProduct] = state.totalFiles[
        state.currentProduct
      ].filter(({ fileID }) => removeFileIds.includes(fileID) === false);

      // update worklist
      state.worklistFiles = state.totalFiles[state.currentProduct].filter(
        ({ Group }) => Group !== 0,
      );
    },
    changeCurrentProduct: (state, action) => {
      state.currentProduct = action.payload;

      // update paginationConfig
      state.pagination = {
        ...state.pagination,
        currentPage: 1,
      };

      // update worklist
      state.worklistFiles = state.totalFiles[state.currentProduct].filter(
        ({ Group }) => Group !== 0,
      );

      // update paginationConfig
      state.worklistPagination = {
        ...state.worklistPagination,
        currentPage: 1,
      };
    },
    updateSelectFileIds: (state, action) => {
      state.uploadSelectFileIds = action.payload;
    },
    // for only upload Table
    updateFilter: (state, action) => {
      state.filter = action.payload;

      // update pagination current index
      state.pagination.currentPage = 1;
    },
    updatePagination: (state, action) => {
      state.pagination = action.payload;
    },
    updateWorklistPagination: (state, action) => {
      state.worklistPagination = action.payload;
    },
    addAndRemoveWorklist: (state, action) => {
      const { status, targetFileIDs } = action.payload;

      state.totalFiles[state.currentProduct] = state.totalFiles[
        state.currentProduct
      ].map((el) =>
        targetFileIDs.includes(el.fileID)
          ? { ...el, Group: status ? 1 : 0 }
          : el,
      );

      // update worklist
      state.worklistFiles = state.totalFiles[state.currentProduct].filter(
        ({ Group }) => Group !== 0,
      );

      // update paginationConfig
      state.worklistPagination = {
        ...state.worklistPagination,
        currentPage: 1,
      };
    },
  },
});

export const {
  fetchFilesStore,
  updateFile,
  removeFiles,
  changeCurrentProduct,
  updateFilter,
  updatePagination,
  updateWorklistPagination,
  addAndRemoveWorklist,
  updateSelectFileIds,
} = filesSlice.actions;

export default filesSlice.reducer;

export const selectFilesByIds = createSelector(
  [
    (state) => state.fileList.totalFiles[state.fileList.currentProduct],
    (state, selectFileIds) => selectFileIds,
  ],
  (totalFiles, selectFileIds) => {
    return totalFiles.filter((el) => selectFileIds.includes(el.fileID));
  },
);

export const selectFileById = createSelector(
  [
    (state) => state.fileList.totalFiles[state.fileList.currentProduct],
    (state, selectFileId) => selectFileId,
  ],
  (totalFiles, selectFileId) => {
    return totalFiles.find((el) => el.fileID === selectFileId);
  },
);

export const selectFilteredFiles = createSelector(
  [
    (state) => state.fileList.totalFiles[state.fileList.currentProduct],
    (state) => state.fileList.filter,
  ],
  (totalFiles, filter) => {
    const { searchType, searchKeyword } = filter || filterInit;
    const lowcaseKeyword = searchKeyword.toLowerCase();

    if (searchKeyword === '') return totalFiles;

    // NOTE searchType: PatientName, PatientID, AcquisitionDateTime
    return totalFiles.filter((file) => {
      const searchField = file[searchType]?.toLowerCase();
      return searchField?.includes(lowcaseKeyword);
    });
  },
);

export const selectPaginatedFiles = createSelector(
  [selectFilteredFiles, (state) => state.fileList.pagination],
  (filteredFiles, pagination) => {
    const totalSize = filteredFiles.length;
    const { orderBy, orderDirection, currentPage, itemsPerPage } =
      pagination || paginationInit;

    const sortedFiles = _.orderBy(filteredFiles, [orderBy], [orderDirection]);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(currentPage * itemsPerPage, totalSize);

    // NOTE if itemsPerPage is 0, return all files
    const sliceFiles =
      itemsPerPage === 0
        ? sortedFiles
        : sortedFiles.slice(startIndex, endIndex);

    const paginationItems = sliceFiles.map((file) => ({
      id: file.id,
      fileID: file.fileID,
      Tracer: file.Tracer,
      Complete: file.Complete,
      PatientName: file.PatientName,
      PatientID: file.PatientID,
      Age: file.Age,
      Sex: file.Sex,
      AcquisitionDateTime: file.AcquisitionDateTime,
      Update: file.Update.substring(0, 16),

      // amyloid
      Composite_C: file['Composite_C'] ? file['Composite_C'] : 0,
      // DAT
      DAT_Dorsal_striatum: file['DAT_Dorsal_striatum']
        ? file['DAT_Dorsal_striatum']
        : 0,
    }));

    return { items: paginationItems, totalSize };
  },
);

export const selectWorklistFiles = createSelector(
  [
    (state) => state.fileList.worklistFiles,
    (state) => state.fileList.worklistPagination,
  ],
  (worklistFiles, pagination) => {
    const totalSize = worklistFiles.length;
    const { orderBy, orderDirection, currentPage, itemsPerPage } =
      pagination || paginationInit;

    const sortedFiles = _.orderBy(worklistFiles, [orderBy], [orderDirection]);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(currentPage * itemsPerPage, totalSize);

    // NOTE if itemsPerPage is 0, return all files
    const sliceFiles =
      itemsPerPage === 0
        ? sortedFiles
        : sortedFiles.slice(startIndex, endIndex);

    const paginationItems = sliceFiles.map((file) => ({
      id: file.id,
      fileID: file.fileID,
      Tracer: file.Tracer,
      Complete: file.Complete,
      PatientName: file.PatientName,
      PatientID: file.PatientID,
      Age: file.Age,
      Sex: file.Sex,
      AcquisitionDateTime: file.AcquisitionDateTime,
      Update: file.Update.substring(0, 16),

      // amyloid
      Composite_C: file['Composite_C'] ? file['Composite_C'] : 0,
      // DAT
      DAT_Dorsal_striatum: file['DAT_Dorsal_striatum']
        ? file['DAT_Dorsal_striatum']
        : 0,
    }));
    return { items: paginationItems, totalSize };
  },
);
