import chroma from 'chroma-js';
import { ANALYSIS_CARD } from './analysisTypes';

// const amyloidCsample = chroma.scale(["#FFFEFD","#FFDFBE","#F9C697","#F7AB7E","#FF8075","#F26162","#E14349","#CF282B","#850303"])
const amyloidCsample = chroma.scale(['#ffebda', '#FFDFBE', '#F9C697', '#F7AB7E', '#FF8075', '#F26162', '#E14349', '#CF282B', '#850303']);
const amyloidCmap = [...Array(101).keys()].map((v) => amyloidCsample(v / 100).toString());
// const datCsample     = chroma.scale(["#2400d8","#181cf7","#2857ff","#3d87ff","#56b0ff","#75d3ff","#99eaff","#bcf9ff","#eaffff"]);
const datCsample = chroma.scale(['#2400d8', '#181cf7', '#2857ff', '#3d87ff', '#56b0ff', '#75d3ff', '#99eaff', '#bcf9ff', '#ddfcff']);
const datCmap = [...Array(101).keys()].map((v) => datCsample(v / 100).toString());
const fdgCsample = chroma.scale(['#000093', '#0007ff', '#005bff', '#00a7ff', '#00eeff', '#2fffcf', '#53ffab', '#83ff7b', '#cbff33', '#fcff02', '#ff8a00', '#ff1300', '#970000']);
const fdgCmap = [...Array(101).keys()].map((v) => fdgCsample(v / 100).toString());
// const perfusionCsample = chroma.scale(["#000093","#0007ff","#005bff","#00a7ff","#00eeff","#2fffcf","#53ffab","#83ff7b","#cbff33","#fcff02","#ff8a00","#ff1300","#970000"])
// const perfusionCmap = [...Array(101).keys()].map(v => fdgCsample(v/100).toString())

const REFERNECE_STEP_MAP = {
  // reference region : [labelMin, labelMax, labelCount]
  wcrbll: [0.8, 2.4, 7],
  wcrbll_spect: [0.3, 0.9, 7],
  crbllGM: [1.0, 2.2, 7],
  crbllGM_spect: [0.5, 1.1, 7],
  pons: [0.6, 1.6, 6],
  cerebralWM: [0.6, 1.6, 6],
  Occipital: [1, 4, 7],
  Zscore: [-4, 4, 9],
};

const BrainLobeFormats = {
  amyloid: {
    title: 'Lobar SUVR',
    colorcode: amyloidCmap,
    legend: 'SUVR',
    componentName: ANALYSIS_CARD.BRAIN_LOBE,
    content: [
      { atlas: 'all', lobe: 'Global Lobe', Left: 'Bilat.', Right: 'Bilat.', Lvar: 'Global', Rvar: 'Global' },
      { atlas: 'all', lobe: 'Frontal Lobe', Left: 'L', Right: 'R', Lvar: 'Frontal_L', Rvar: 'Frontal_R' },
      { atlas: 'all', lobe: 'Parietal Lobe', Left: 'L', Right: 'R', Lvar: 'Parietal_L', Rvar: 'Parietal_R' },
      { atlas: 'all', lobe: 'Temporal Lobe', Left: 'L', Right: 'R', Lvar: 'Temporal_L', Rvar: 'Temporal_R' },
      { atlas: 'all', lobe: 'Occipital Lobe', Left: 'L', Right: 'R', Lvar: 'Occipital_L', Rvar: 'Occipital_R' },
    ],
  },
  dat: {
    title: 'Striatal SBR',
    colorcode: datCmap,
    legend: 'BR',
    componentName: ANALYSIS_CARD.BRAIN_LOBE,
    content: [
      { atlas: 'Melbourne Subcortical', lobe: 'Dorsal striatum', Left: 'L', Right: 'R', Lvar: 'DAT_Dorsal_striatum_L', Rvar: 'DAT_Dorsal_striatum_R' },
      { atlas: 'Melbourne Subcortical', lobe: 'Caudate', Left: 'L', Right: 'R', Lvar: 'DAT_Caudate_nucleus_L', Rvar: 'DAT_Caudate_nucleus_R' },
      { atlas: 'Melbourne Subcortical', lobe: 'Putamen A', Left: 'L', Right: 'R', Lvar: ['DAT_Dorsoant_putamen_L', 'DAT_Ventroant_putamen_L'], Rvar: ['DAT_Dorsoant_putamen_R', 'DAT_Ventroant_putamen_R'] },
      { atlas: 'Melbourne Subcortical', lobe: 'Putamen P', Left: 'L', Right: 'R', Lvar: ['DAT_Dorsopost_putamen_L', 'DAT_Ventropost_Putamen_L'], Rvar: ['DAT_Dorsopost_putamen_R', 'DAT_Ventropost_Putamen_R'] },

      { atlas: 'AAL3', lobe: 'Striatum', Left: 'L', Right: 'R', Lvar: ['DAT_AAL3_Caudate_L', 'DAT_AAL3_Putamen_L'], Rvar: ['DAT_AAL3_Caudate_R', 'DAT_AAL3_Putamen_R'] },
      { atlas: 'AAL3', lobe: 'Caudate', Left: 'L', Right: 'R', Lvar: 'DAT_AAL3_Caudate_L', Rvar: 'DAT_AAL3_Caudate_R' },
      { atlas: 'AAL3', lobe: 'Putamen', Left: 'L', Right: 'R', Lvar: 'DAT_AAL3_Putamen_L', Rvar: 'DAT_AAL3_Putamen_R' },

      { atlas: 'Harvard-Oxford', lobe: 'Striatum', Left: 'L', Right: 'R', Lvar: ['DAT_HO_Caudate_L', 'DAT_HO_Putamen_L'], Rvar: ['DAT_HO_Caudate_R', 'DAT_HO_Putamen_R'] },
      { atlas: 'Harvard-Oxford', lobe: 'Caudate', Left: 'L', Right: 'R', Lvar: 'DAT_HO_Caudate_L', Rvar: 'DAT_HO_Caudate_R' },
      { atlas: 'Harvard-Oxford', lobe: 'Putamen', Left: 'L', Right: 'R', Lvar: 'DAT_HO_Putamen_L', Rvar: 'DAT_HO_Putamen_R' },
    ],
  },
  fdg: {
    title: 'Lobar SUVR',
    colorcode: fdgCmap,
    legend: 'SUVR',
    componentName: ANALYSIS_CARD.BRAIN_LOBE,
    content: [
      { atlas: 'all', lobe: 'Global Lobe', Left: 'Bilat.', Right: 'Bilat.', Lvar: 'Global', Rvar: 'Global' },
      { atlas: 'all', lobe: 'Frontal Lobe', Left: 'L', Right: 'R', Lvar: 'Frontal_L', Rvar: 'Frontal_R' },
      { atlas: 'all', lobe: 'Parietal Lobe', Left: 'L', Right: 'R', Lvar: 'Parietal_L', Rvar: 'Parietal_R' },
      { atlas: 'all', lobe: 'Temporal Lobe', Left: 'L', Right: 'R', Lvar: 'Temporal_L', Rvar: 'Temporal_R' },
      { atlas: 'all', lobe: 'Occipital Lobe', Left: 'L', Right: 'R', Lvar: 'Occipital_L', Rvar: 'Occipital_R' },
    ],
  },
  tau: {
    title: 'Lobar SUVR',
    colorcode: amyloidCmap,
    legend: 'SUVR',
    componentName: ANALYSIS_CARD.BRAIN_LOBE,
    content: [
      { atlas: 'all', lobe: 'Global Lobe', Left: 'Bilat.', Right: 'Bilat.', Lvar: 'Global', Rvar: 'Global' },
      { atlas: 'all', lobe: 'Frontal Lobe', Left: 'L', Right: 'R', Lvar: 'Frontal_L', Rvar: 'Frontal_R' },
      { atlas: 'all', lobe: 'Parietal Lobe', Left: 'L', Right: 'R', Lvar: 'Parietal_L', Rvar: 'Parietal_R' },
      { atlas: 'all', lobe: 'Temporal Lobe', Left: 'L', Right: 'R', Lvar: 'Temporal_L', Rvar: 'Temporal_R' },
      { atlas: 'all', lobe: 'Occipital Lobe', Left: 'L', Right: 'R', Lvar: 'Occipital_L', Rvar: 'Occipital_R' },
    ],
  },
  perfusion: {
    title: 'Lobar SUVR',
    // #19 brainTable colorcode 변경 fdg와 동일하게
    colorcode: fdgCmap,
    // colorcode:amyloidCmap,
    legend: 'SUVR',
    componentName: ANALYSIS_CARD.BRAIN_LOBE,
    content: [
      { atlas: 'all', lobe: 'Global Lobe', Left: 'Bilat.', Right: 'Bilat.', Lvar: 'Global', Rvar: 'Global' },
      { atlas: 'all', lobe: 'Frontal Lobe', Left: 'L', Right: 'R', Lvar: 'Frontal_L', Rvar: 'Frontal_R' },
      { atlas: 'all', lobe: 'Parietal Lobe', Left: 'L', Right: 'R', Lvar: 'Parietal_L', Rvar: 'Parietal_R' },
      { atlas: 'all', lobe: 'Temporal Lobe', Left: 'L', Right: 'R', Lvar: 'Temporal_L', Rvar: 'Temporal_R' },
      { atlas: 'all', lobe: 'Occipital Lobe', Left: 'L', Right: 'R', Lvar: 'Occipital_L', Rvar: 'Occipital_R' },
    ],
  },
};

export { REFERNECE_STEP_MAP, BrainLobeFormats};
