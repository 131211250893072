import jsPDF from 'jspdf';
import JSZip from 'jszip';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';

import { subRegions } from '0_variables/subRegions';
import { limitNum as UTILS_LIMITNUM } from '0_variables/utils';
import { uploadPDFToPACS } from '2_services/uploadApi';

import {
  logo as MEDIA_LOGO,
  reportIcon1 as MEDIA_reportIcon1,
  reportIcon2 as MEDIA_reportIcon2,
  reportIcon3 as MEDIA_reportIcon3,
} from '6_media';

import { montserrat_medium } from '7_font/montserrat/Montserrat-Medium-normal';
import { montserrat_medium_italic } from '7_font/montserrat/Montserrat-LightItalic-normal';
import { montserrat_bold } from '7_font/montserrat/Montserrat-Bold-normal';
import { montserrat_bold_semi } from '7_font/montserrat/Montserrat-SemiBold-normal';
import { montserrat_light } from '7_font/montserrat/Montserrat-Light-normal';
import { notosans_medium_normal } from '7_font/montserrat/NotoSans-Medium-normal';

const product_full_name = (productName) => {
  if (productName === 'amyloid') return 'Amyloid';
  else if (productName === 'dat') return 'Dopamine';
  else if (productName === 'tau') return 'Tau';
  else if (productName === 'fdg') return 'FDG';
  else if (productName === 'perfusion') return 'Perfusion';
  else return 'Amyloid';
};

const tracer_fullName = (tracer) => {
  if (tracer === '[18F]FBP') return '[18F]Florbetapir';
  else if (tracer === '[18F]FMM') return '[18F]Flutemetamol';
  else if (tracer === '[18F]FBB') return '[18F]Florbetaben';
  else if (tracer === '[18F]FPN') return '[18F]Florapronol';
  else if (tracer === '[18F]FPCIT') return '[18F]FP-CIT';
  else if (tracer === '[18F]FDG') return '[18F]FDG';
  else if (tracer === '[18F]TAU') return '[18F]TAU';
  else if (tracer === '[99mtc]ECD') return '[99mtc]ECD';
  else if (tracer === '[99mtc]HMPAO') return '[99mtc]HMPAO';
  else return '[18F]Florbetapir';
};

const setFont = (pdf) => {
  pdf.addFileToVFS('montserrat_italic.ttf', montserrat_medium_italic);
  pdf.addFont('montserrat_italic.ttf', 'montserrat', 'italic');
  pdf.addFileToVFS('montserrat_medium.ttf', montserrat_medium);
  pdf.addFont('montserrat_medium.ttf', 'montserrat', 'normal');
  pdf.addFileToVFS('montserrat_bold.ttf', montserrat_bold);
  pdf.addFont('montserrat_bold.ttf', 'montserrat', 'bold');
  pdf.addFileToVFS('montserrat_bold_semi.ttf', montserrat_bold_semi);
  pdf.addFont('montserrat_bold_semi.ttf', 'montserrat', 'bold_semi');
  pdf.addFileToVFS('montserrat_light.ttf', montserrat_light);
  pdf.addFont('montserrat_light.ttf', 'montserrat', 'light');
  pdf.addFileToVFS('notosans_medium_normal.ttf', notosans_medium_normal);
  pdf.addFont('notosans_medium_normal.ttf', 'noto', 'medium');
};

const header = (pdf) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const date = today.getDate();

  pdf.setFont('montserrat', 'italic');
  pdf.setFontSize(8);
  pdf.setTextColor(179, 179, 179);
  // if (productName==="perfusion"){
  //   pdf.text(20,10,'This product has not been approved by the MFDS and FDA')
  //   pdf.text(20,13,'and is not intended to diagnose, treat, cure, or prevent any disease.')
  // }
  pdf.text(200, 10, 'Last Printed', {
    align: 'right',
  });
  pdf.text(200, 13, date + '/' + month + '/' + year, {
    align: 'right',
  });
};

const mainTitle = (pdf, productName) => {
  const productFullName = product_full_name(productName);
  pdf.setFillColor(90, 122, 181);
  pdf.rect(20, 15, 1, 20, 'F');

  pdf.setFont('montserrat', 'bold');
  pdf.setFontSize(20);
  pdf.setTextColor(0, 0, 0);
  pdf.text(25, 23, 'BTXBrain-' + productFullName);

  pdf.setFont('montserrat', 'light');
  pdf.setFontSize(13);
  productName === 'perfusion'
    ? pdf.text(
        25,
        31,
        `AI-based Automatic ${productFullName} SPECT Quantification Report`,
      )
    : pdf.text(
        25,
        31,
        `AI-based Automatic ${productFullName} PET Quantification Report`,
      );
};

// todo : params 묶음으로 전달
const subTitle = (pdf, iconSrc, left, top, msg) => {
  const txtLeft = left + 10,
    txtTop = top + 4;
  pdf.addImage(iconSrc, 'PNG', left, top, 5.5, 5);
  pdf.setFont('montserrat', 'bold');
  pdf.setFontSize(13);
  pdf.setTextColor(90, 122, 181);
  pdf.text(txtLeft, txtTop, msg);
};

const tableTitle = (pdf, msg, x, y) => {
  pdf.setFont('montserrat', 'normal');
  pdf.setFontSize(12);
  pdf.setTextColor(0, 0, 0);
  pdf.text(x, y, msg);
};

const footer = (
  pdf,
  pageNum,
  opacity,
  pageLength,
  PatientID,
  defaultAtlas,
  refRegion,
) => {
  const TOP_POSITION = 280;
  pdf.setFont('montserrat', 'normal');
  pdf.setFontSize(8);
  pdf.setTextColor(179, 179, 179);
  pdf.text(20, TOP_POSITION, 'Quantification analysis details');
  pdf.setFont('montserrat', 'light');

  pdf.text(
    20,
    TOP_POSITION + 3,
    `Atlas used: ${defaultAtlas} / Reference region: ${refRegion}`,
  );
  pdf.addImage(MEDIA_LOGO, 'PNG', 20, TOP_POSITION + 6, 60, 4);

  pdf.text(200, TOP_POSITION + 6, `PatientID : ${PatientID}`, {
    align: 'right',
  });
  pdf.text(200, TOP_POSITION + 9, `page ${pageNum}/${pageLength}`, {
    align: 'right',
  });
  pdf.setFont('montserrat', 'bold');
  pdf.setFontSize(50);
  pdf.saveGraphicsState();
  pdf.setGState(new pdf.GState({ opacity: opacity }));
  pdf.saveGraphicsState();
  pdf.setGState(new pdf.GState({ opacity: 1 }));
};

const getImageMillimeterSize = (imgNode) => {
  const pageSizePx = {
    width: 945,
    height: 1273,
  };

  const a4Size = {
    width: 210, // mm
    height: 297, // mm
  };
  const { clientWidth, clientHeight } = imgNode;

  return {
    width: Math.round((clientWidth / pageSizePx.width) * a4Size.width),
    height: Math.round((clientHeight / pageSizePx.height) * a4Size.height),
  };
};

const insertImageAtPosition = async (pdf, pageNode, position, imgSelector) => {
  const imgNode = pageNode.querySelector(imgSelector);
  const { width, height } = getImageMillimeterSize(imgNode);

  const dataUrl = await domtoimage.toPng(imgNode, {
    style: { backgroundColor: 'white' },
  });

  return pdf.addImage(
    dataUrl,
    'PNG',
    position.left,
    position.top,
    width,
    height,
  );
};

// NOTE 원본 엘리먼트에 opacity가 적용되어 있어서 인지 domtoimage로 변환 시 이미지 보이질 않음
const insertWatermark = async (pdf, watermark) => {
  const { canvas, size } = watermark;
  const { width, height } = size;
  const logoName = process.env.REACT_APP_LOGO_NAME;

  if (!logoName || logoName.length === 0) {
    return null;
  }

  const a4Size = {
    width: 210, // mm
    height: 297, // mm
  };

  // page 중앙
  const position = {
    left: a4Size.width / 2 - width / 2,
    top: a4Size.height / 2 - height / 2,
  };

  return pdf.addImage(
    canvas,
    'PNG',
    position.left,
    position.top,
    width,
    height,
  );
};

const generatePDF = async ({
  selectedFile,
  productName,
  settingOfProduct,
  watermark,
  currentProgressVal,
  progressGap,
  updateSpinner,
  isReportWrapper,
}) => {
  const {
    fileID: currentFileId,
    PatientName,
    PatientID,
    Age: patientAge,
    Sex: patientSex,
    AcquisitionDateTime,
    Tracer: currentTracer,
  } = selectedFile;

  const containerSelector = `.a4-pages-container_${currentFileId}${
    isReportWrapper ? '_worklist' : ''
  }`;
  const reportContainer = document.querySelector(containerSelector);

  const defaultRegionLength = subRegions[productName]
    .map((v) => v.level)
    .filter((lev) => lev === 0).length;
  const settingRegionLength = settingOfProduct.list
    .map((v) => v.level)
    .filter((lev) => lev === 0).length;
  const limitNum = UTILS_LIMITNUM;

  const pageLength =
    settingRegionLength - defaultRegionLength > limitNum[productName]
      ? selectedFile.Tracer === '[18F]FPN'
        ? 3
        : 4
      : 3;
  let PAGE_NODE_INDEX = 0;
  let PAGE_NUMBER = 1;

  const patientInfoDisplaySettings = settingOfProduct.defaultPInfoState;
  const defaultAtlas = settingOfProduct.defaultAtlas;
  const refRegion = settingOfProduct.defaultRef[selectedFile.Tracer];

  const currentPDFDoc = new jsPDF('p', 'mm', 'a4');
  // portrait, millimeter, A4 size (210mm x 297mm)

  // common block
  const patient_info = [
    {
      label: 'Patient Name',
      value: PatientName,
      state: patientInfoDisplaySettings[0].state,
    },
    {
      label: 'Patient ID',
      value: PatientID,
      state: patientInfoDisplaySettings[1].state,
    },
    {
      label: 'DOB',
      value: patientAge,
      state: patientInfoDisplaySettings[2].state,
    },
    {
      label: 'Sex',
      value: patientSex,
      state: patientInfoDisplaySettings[3].state,
    },
    {
      label: 'Study Date',
      value: AcquisitionDateTime,
      state: patientInfoDisplaySettings[4].state,
    },
    {
      label: 'Study Description',
      value: tracer_fullName(currentTracer)
        .replace('18', '\u00B9\u2078')
        .replace('99mtc', '\u2079\u2079\u1d50Tc'),
      state: true,
    },
  ];

  const gapByFile = progressGap / pageLength;

  // NOTE : Page 1
  updateSpinner(
    true,
    `Making PDF : File ID - ${selectedFile.fileID} [Page ${PAGE_NUMBER}]`,
    currentProgressVal + gapByFile * PAGE_NUMBER,
    'PDF',
  );
  const reportPage1Node = reportContainer.childNodes[PAGE_NODE_INDEX];
  setFont(currentPDFDoc);
  header(currentPDFDoc);
  mainTitle(currentPDFDoc, productName);
  footer(
    currentPDFDoc,
    PAGE_NUMBER++,
    0.2,
    pageLength,
    PatientID,
    defaultAtlas,
    refRegion,
  );

  // patient info -  begin
  subTitle(currentPDFDoc, MEDIA_reportIcon1, 20, 42, 'Patient Information');

  currentPDFDoc.autoTable({
    body: patient_info
      .filter((info) => info.state)
      .map(({ label, value }) => {
        return { label, value };
      }),
    margin: { top: 53, left: 31 },
    theme: 'plain',

    didParseCell: (body) => {
      body.cell.styles.fontSize = 11;
      const header = [
        'Patient Name',
        'Patient ID',
        'DOB',
        'Sex',
        'Study Date',
        'Study Description',
      ];
      if (header.includes(body.cell.text[0])) {
        body.cell.styles.font = 'montserrat';
        body.cell.styles.fontStyle = 'bold_semi';
        body.cell.styles.cellWidth = 46;
      } else {
        body.cell.styles.font = 'noto';
        body.cell.styles.fontStyle = 'medium';
      }
    },
  });

  // brain table
  const tempPage2Top = productName === 'dat' ? 130 : 112;
  subTitle(
    currentPDFDoc,
    MEDIA_reportIcon2,
    20,
    tempPage2Top,
    'Quantification Results',
  );
  await insertImageAtPosition(
    currentPDFDoc,
    reportPage1Node,
    { left: 30, top: tempPage2Top + 8 },
    '.brain_table',
  );

  await insertWatermark(currentPDFDoc, watermark);

  // NOTE : Page 2
  currentPDFDoc.addPage();
  updateSpinner(
    true,
    `Making PDF : File ID - ${selectedFile.fileID} [Page ${PAGE_NUMBER}]`,
    currentProgressVal + gapByFile * PAGE_NUMBER,
    'PDF',
  );
  PAGE_NODE_INDEX++;
  const reportPage2Node = reportContainer.childNodes[PAGE_NODE_INDEX];
  header(currentPDFDoc);
  mainTitle(currentPDFDoc, productName);
  footer(
    currentPDFDoc,
    PAGE_NUMBER++,
    0.2,
    pageLength,
    PatientID,
    defaultAtlas,
    refRegion,
  );

  // d3 graph
  subTitle(currentPDFDoc, MEDIA_reportIcon2, 20, 42, 'Quantification Results');
  await insertImageAtPosition(
    currentPDFDoc,
    reportPage2Node,
    { left: 20, top: 50 },
    '.report_graph',
  );

  // page 2 table
  const table1Title = productName === 'dat' ? 'Regional SBR' : 'Regional SUVR';
  tableTitle(currentPDFDoc, table1Title, 30, 112);

  //*SUVR or SBR table
  const table1_startY = 115;
  // const table_fontSize = productName !== 'dat' ? 11 : 10;
  const table_fontSize = 10;

  currentPDFDoc.autoTable({
    html: `${containerSelector} .report_suvr_table table`,
    startY: table1_startY,
    margin: { left: 30 },
    tableWidth: 155,
    theme: 'plain',
    styles: {
      halign: 'center',
    },
    headStyles: {
      fillColor: [202, 202, 202],
    },
    didParseCell: (HookData) => {
      HookData.cell.styles.fontSize = table_fontSize;
      HookData.cell.styles.minCellHeight = 1;
      HookData.cell.styles.cellPadding = 1;
      if (HookData.section === 'head') {
        HookData.cell.styles.font = 'montserrat';
        HookData.cell.styles.fontStyle = 'bold_semi';
      } else {
        HookData.cell.styles.font = 'montserrat';
        HookData.cell.styles.fontStyle = 'light';
      }
    },
  });

  await insertWatermark(currentPDFDoc, watermark);

  // NOTE second table page no.2 or no.3
  const tempSecondDataTable = document.querySelector(
    `${containerSelector} .report_second_table table`,
  );

  if (tempSecondDataTable !== null) {
    // pageLength 가 4이면 pdf page 추가하고 추가된 page에 table 추가 아니면 기존 page에 table 추가
    if (pageLength === 4) {
      currentPDFDoc.addPage();
      updateSpinner(
        true,
        `Making PDF : File ID - ${selectedFile.fileID} [Page ${PAGE_NUMBER}]`,
        currentProgressVal + gapByFile * PAGE_NUMBER,
        'PDF',
      );
      PAGE_NODE_INDEX++;
      // const reportPage2_1Node = reportContainer.childNodes[PAGE_NODE_INDEX++];
      header(currentPDFDoc);
      mainTitle(currentPDFDoc, productName);
      footer(
        currentPDFDoc,
        PAGE_NUMBER++,
        0.2,
        pageLength,
        PatientID,
        defaultAtlas,
        refRegion,
      );
      subTitle(
        currentPDFDoc,
        MEDIA_reportIcon2,
        20,
        42,
        'Quantification Results',
      );
    }

    const titleContent =
      productName === 'dat' ? 'Semiquantification' : 'Regional Centiloid';
    const table2_startY =
      pageLength === 4 ? 60 : productName === 'dat' ? 212 : 195;
    tableTitle(currentPDFDoc, titleContent, 30, table2_startY - 3);
    //Centiloid table
    currentPDFDoc.autoTable({
      html: `${containerSelector} .report_second_table table`,
      startY: table2_startY,
      margin: { left: 30 },
      tableWidth: 155,
      theme: 'plain',
      styles: {
        halign: 'center',
      },
      headStyles: {
        fillColor: [202, 202, 202],
      },
      didParseCell: (body) => {
        body.cell.styles.fontSize = table_fontSize;
        if (body.section === 'head') {
          body.cell.styles.font = 'montserrat';
          body.cell.styles.fontStyle = 'bold_semi';
        } else {
          body.cell.styles.font = 'montserrat';
          body.cell.styles.fontStyle = 'light';
        }
      },
    });
  }

  // NOTE : Page 3 or 4 - last page
  currentPDFDoc.addPage();
  updateSpinner(
    true,
    `Making PDF : File ID - ${selectedFile.fileID} [Page ${PAGE_NUMBER}]`,
    currentProgressVal + gapByFile * PAGE_NUMBER,
    'PDF',
  );
  PAGE_NODE_INDEX++;
  const productFullName = product_full_name(productName);
  const reportLastPageNode = reportContainer.childNodes[PAGE_NODE_INDEX];
  header(currentPDFDoc);
  mainTitle(currentPDFDoc, productName);
  footer(
    currentPDFDoc,
    PAGE_NUMBER,
    0.2,
    pageLength,
    PatientID,
    defaultAtlas,
    refRegion,
  );

  subTitle(
    currentPDFDoc,
    MEDIA_reportIcon3,
    20,
    42,
    `${productFullName} PET Image`,
  );

  const suvrOrSbr = productName === 'dat' ? ' SBR ' : ' SUVR ';
  tableTitle(currentPDFDoc, `Spatially normalized ${suvrOrSbr} Image`, 35, 58);

  await insertImageAtPosition(
    currentPDFDoc,
    reportLastPageNode,
    { left: 20, top: 65 },
    '.axial_image',
  );

  if (productName === 'dat') {
    await insertImageAtPosition(
      currentPDFDoc,
      reportLastPageNode,
      { left: 20, top: 140 },
      '.mip_image',
    );
  } else {
    tableTitle(currentPDFDoc, 'SUVR rendered on brain surface', 35, 148);
    await insertImageAtPosition(
      currentPDFDoc,
      reportLastPageNode,
      { left: 20, top: 160 },
      '.surface_image',
    );
  }

  // NOTE final
  return {
    pdf: currentPDFDoc,
    fileName: `${currentFileId}_${PatientID}_${PatientName}_${selectedFile.Tracer}_${AcquisitionDateTime}_${refRegion}.pdf`,
  };
};

export const exportPDF = async ({
  spinnerDispatch,
  fileList,
  productName,
  settingOfProduct,
  isUploadToPacs = false,
  toggleShowReport = null,
  isReportWrapper = false,
}) => {
  const updateSpinner = (status, message, percent, target) => {
    spinnerDispatch({
      status,
      message,
      percent: Math.round(percent),
      target,
    });
  };

  updateSpinner(true, 'Making PDF Begin...', 0, 'PDF');

  const pdfResults = [];
  const logoName = process.env.REACT_APP_LOGO_NAME;

  let watermark = {
    size: { width: 0, height: 0 },
    canvas: null,
  };

  // get water mark
  if (!!logoName && logoName.length > 0) {
    const tempContainer = document.querySelector(
      `.a4-pages-container_${fileList[0].fileID}${
        isReportWrapper ? '_worklist' : ''
      }`,
    );
    const WATERMARK_SELECTOR = '.watermark-container img';
    const watermarkNode = tempContainer.querySelector(WATERMARK_SELECTOR);
    const watermarkSize = getImageMillimeterSize(watermarkNode);
    const watermarkCanvas = await html2canvas(watermarkNode, {
      backgroundColor: null,
      scale: 1.1,
    });

    watermark = {
      size: watermarkSize,
      canvas: watermarkCanvas,
    };
  }

  const totalProgressVal = fileList.length;
  const progressGap = 100 / totalProgressVal;
  let currentProgressVal = 0;

  for (const selectedFile of fileList) {
    updateSpinner(
      true,
      `Making PDF : File ID - ${selectedFile.fileID}`,
      parseInt(currentProgressVal),
      'PDF',
    );

    const result = await generatePDF({
      selectedFile,
      productName,
      settingOfProduct,
      watermark,
      currentProgressVal,
      progressGap,
      updateSpinner,
      isReportWrapper,
    });

    pdfResults.push(result);
    currentProgressVal += progressGap;
  }

  if (pdfResults.length > 0) {
    if (pdfResults.length === 1) {
      const { pdf, fileName } = pdfResults[0];

      if (isUploadToPacs) {
        // NOTE pacs 업로드는 단일 report만 가능
        const zip = new JSZip();
        zip.file(fileName, pdf.output('blob'));
        const zipContent = await zip.generateAsync({ type: 'base64' });

        await uploadPDFToPACS(zipContent);
      } else {
        pdf.save(fileName);
      }
    } else {
      // NOTE worklist 에서 다수의 report를 선택한 경우 zip으로 묶어서 다운로드
      // NOTE 현재까진 worklist 전용
      const zip = new JSZip();
      pdfResults.forEach(({ pdf, fileName }) => {
        zip.file(fileName, pdf.output('blob'));
      });

      const zipContent = await zip.generateAsync({ type: 'blob' });
      saveAs(zipContent, 'BRTNX(PDF).zip');
    }
  }
  if (isReportWrapper && toggleShowReport !== null) toggleShowReport(false);

  setTimeout(() => {
    updateSpinner(false, '', 0, '');
  }, 200);
};
