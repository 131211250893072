import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getVersionInfo } from '0_variables/utils';
import { openVersionModal } from '1_reduxs/reducers/modalReducer';
import styles from './VersionModal.module.scss';

export default function VersionModal() {
  const dispatch = useDispatch();
  const [serverVesion, setServerVersion] = useState(
    process.env.REACT_APP_BTX_SERVER_VERSION,
  );
  const [hiddenPasswd, setHiddenPasswd] = useState('');
  const [showHiddenVersion, setShowHiddenVersion] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setHiddenPasswd('');
      } else {
        const insertString = hiddenPasswd + event.key;
        const compareKeys = process.env.REACT_APP_HIDDEN_KEY || '';

        if (compareKeys.length > 0 && compareKeys === insertString) {
          setShowHiddenVersion(true);
          setHiddenPasswd('');
        } else {
          setHiddenPasswd(insertString);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [hiddenPasswd]);

  useEffect(() => {
    (async () => {
      const version = await getVersionInfo();
      setServerVersion(version.data);
    })();
  }, []);

  return (
    <div
      className={styles.container}
      onClick={() => {
        dispatch(openVersionModal({ isOn: false }));
      }}
    >
      <div className={styles.infoBox} onClick={(e) => e.stopPropagation()}>
        <div style={{ margin: '1vw' }}>
          <div className={styles.infoTitle}>Information</div>
          <div className={styles.contents}>
            <div className={styles.content}>
              <div>Manufacturer</div>
              <div>{process.env.REACT_APP_MANUFACTURER}</div>
            </div>
            <div className={styles.content}>
              <div>Hospital</div>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {process.env.REACT_APP_HOSPITAL.split(/\\n/i).map(
                  (line, index) => (
                    <div key={index}>{line}</div>
                  ),
                )}
              </div>
            </div>
            <div className={styles.content}>
              <div>Product name</div>
              <div>{process.env.REACT_APP_PRODUCT_NAME}</div>
            </div>
            <div className={styles.content}>
              <div>Front Version</div>
              <div>{process.env.REACT_APP_BTX_FRONT_VERSION}</div>
            </div>
            <div className={styles.content}>
              <div>Release</div>
              <div>{process.env.REACT_APP_RELEASE_DATE}</div>
            </div>
            <div className={styles.content}>
              <div>Copyright</div>
              <div>{process.env.REACT_APP_COPYRIGHT}</div>
            </div>

            {/* hidden front real version */}
            {showHiddenVersion && (
              <>
                <div className={styles.content}>
                  <div>Front Release Version</div>
                  <div>{process.env.REACT_APP_RELEASE_VERSION}</div>
                </div>
                <div className={styles.content}>
                  <div>Server Version</div>
                  <div>{serverVesion}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
