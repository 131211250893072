import axios from 'axios';

export function getRemainCount(productName) {
  const url =
    process.env.REACT_APP_IS_OCI === 'true'
      ? `testing/uploader/?product=${productName}`
      : 'testing/uploader/';
  return axios.get(url);
}

export function postPacs(data) {
  return axios.post('testing/pacs/', {
    Select: data.Select,
    Method: data.Method,
    PatientID: data.PatientID,
    PatientName: data.PatientName,
    StudyInstanceUID: data.StudyInstanceUID,
    SeriesInfo: data.SeriesInfo,
    Tracer: data.Tracer,
    StudyDate: data.StudyDate,
    StudyDescription: data.StudyDescription,
  });
}

export function deleteFilefromPacs(data) {
  /// uploader 기준 backend temp file 삭제, process kill
  return axios.delete('testing/pacs/', {
    headers: {
      Authorization: 'jwt ' + data.token,
    },
  });
}

// Kevin1116 changed here
export function postFile(data) {
  const results = axios.post('testing/uploader/', data.obj, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  return results;
}

export async function runFile(data) {
  const payload = {
    obj: data.obj.map((el) => ({ ...el, Delete: '' })),
    Tracer: data.Tracer,
    addToWorklist: data.addToWorklist,
  };
  if (process.env.REACT_APP_IS_OCI === 'true') {
    payload.product = data.product;
  }

  const results = await axios.put('testing/uploader/', payload);
  return results;
}

export function postFile_sended(data) {
  return axios.post('testing/uploader_sended/', data.obj);
}

export function runFile_sended(data) {
  return axios.put('testing/uploader_sended/', {
    obj: data.obj.map((el) => ({ ...el, Delete: '' })),
    Tracer: data.Tracer,
    addToWorklist: data.addToWorklist,
  });
}

export function uploadPDFToPACS(zipContent) {
  const formData = new FormData();
  formData.append('data', zipContent);
  return axios.post('testing/report_upload/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function saveReportToPacs(pid, fid) {
  return axios.put('testing/report_upload/', {
    FileID: fid,
    PatientID: pid,
  });
}

export function getFile_cached(data) {
  return axios.get(`testing/uploader_cached/${data.keyStr}/`);
}
