export const unitVolume = {
  AAL3: {
    Precentral:         {Left:3526,   Right:3381  },
    Frontal_Sup:        {Left:4870,   Right:5126  },
    Frontal_Mid:        {Left:4507,   Right:4860  },
    Frontal_Inf_Oper:   {Left:1038,   Right:1399  },
    Frontal_Inf_Tri:    {Left:2529,   Right:2151  },
    Frontal_Inf_Orb:    {Left:814,    Right:874   },
    Rolandic_Oper:      {Left:988,    Right:1331  },
    Supp_Motor_Area:    {Left:2147,   Right:2371  },
    Olfactory:          {Left:291,    Right:288   },
    Frontal_Sup_Med:    {Left:2992,   Right:2134  },
    Frontal_Med_Orb:    {Left:719,    Right:856   },
    Rectus:             {Left:852,    Right:745   },
    OFCmed:             {Left:550,    Right:621   },
    OFCant:             {Left:443,    Right:648   },
    OFCpost:            {Left:567,    Right:561   },
    OFClat:             {Left:197,    Right:188   },
    Insula:             {Left:1858,   Right:1770  },
    Cingulate_Ant_Sub:  {Left:168,    Right:132   },
    Cingulate_Ant_Pre:  {Left:627,    Right:648   },
    Cingulate_Ant_Sup:  {Left:605,    Right:533   },
    Cingulate_Mid:      {Left:1941,   Right:2203  },
    Cingulate_Post:     {Left:463,    Right:335   },
    Precuneus:          {Left:3528,   Right:3265  },
    Heschl:             {Left:225,    Right:249   },
    Temporal_Sup:       {Left:2296,   Right:3141  },
    Temporal_Pole_Sup:  {Left:1285,   Right:1338  },
    Temporal_Mid:       {Left:4942,   Right:4409  },
    Temporal_Pole_Mid:  {Left:755,    Right:1187  },
    Temporal_Inf:       {Left:3200,   Right:3557  },
    Postcentral:        {Left:3892,   Right:3823  },
    Parietal_Sup:       {Left:2065,   Right:2222  },
    Parietal_Inf:       {Left:2447,   Right:1345  },
    Supramarginal:      {Left:1256,   Right:1974  },
    Angular:            {Left:1173,   Right:1752  },
    Paracentral_Lobule: {Left:1349,   Right:836   },
    Hippocampus:        {Left:932,    Right:946   },
    Parahippocampal:    {Left:978,    Right:1132  },
    Amygdala:           {Left:220,    Right:248   },
    Calcarine:          {Left:2258,   Right:1861  },
    Cuneus:             {Left:1521,   Right:1424  },
    Lingual:            {Left:2095,   Right:2300  },
    Occipital_Sup:      {Left:1366,   Right:1413  },
    Occipital_Mid:      {Left:3265,   Right:2098  },
    Occipital_Inf:      {Left:941,    Right:989   },
    Fusiform:           {Left:2309,   Right:2518  },
    Caudate:            {Left:805,    Right:861   },
    Putamen:            {Left:999,    Right:1062  },
    Pallidum:           {Left:293,    Right:280   },
    Thalamus:           {Left:1089,   Right:1088  },
    N_Acc:              {Left:163,    Right:143   },
    VTA:                {Left:10,     Right:9     },
    SN_pc:              {Left:32,     Right:33    },
    SN_pr:              {Left:53,     Right:60    },
    Red_N:              {Left:57,     Right:58    },
    LC:                 {Left:3,      Right:5     },
    Raphe_D:            {Left:18,     Right:18    },
    Raphe_M:            {Left:6,      Right:6     },
  },
  HO: { // 아틀라스 이름에 맞게 변경
    Frontal_Pole:                  {Left:7053,  Right:8208}, 
    Insula:                        {Left:1334,  Right:1344}, 
    Frontal_Sup:                   {Left:2970,  Right:2819}, 
    Frontal_Mid:                   {Left:2927,  Right:2740}, 
    Frontal_Inf_Tri:               {Left:650,   Right:555}, 
    Frontal_Inf_Oper:              {Left:766,   Right:686}, 
    Precentral:                    {Left:4480,  Right:4396}, 
    Frontal_Med:                   {Left:523,   Right:566}, 
    Supp_Motor_Area:               {Left:771,   Right:842}, 
    Cingulate_Ant:                 {Left:1320,  Right:1595}, 
    Frontal_Orb:                   {Left:1680,  Right:1442}, 
    Frontal_Oper:                  {Left:355,   Right:313}, 
    Central_Oper:                  {Left:978,   Right:877}, 
    Subcallosal:                   {Left:639,   Right:620}, 
    Paracingulate:                 {Left:1551,  Right:1599}, 
    Cingulate_Post:                {Left:1229,  Right:1400}, 
    Precuneus:                     {Left:2809,  Right:3128}, 
    Temporal_Pole:                 {Left:2366,  Right:2383}, 
    Temporal_Sup_Ant:              {Left:280,   Right:278}, 
    Temporal_Sup_Post:             {Left:392,   Right:417}, 
    Temporal_Mid_Ant:              {Left:449,   Right:409}, 
    Temporal_Mid_Post:             {Left:1382,  Right:1357}, 
    Temporal_Mid_Temporooccipital: {Left:866,   Right:1162}, 
    Temporal_Inf_Ant:              {Left:336,   Right:326}, 
    Temporal_Inf_Post:             {Left:1020,  Right:946}, 
    Temporal_Inf_Temporooccipital: {Left:698,   Right:781}, 
    Planum_Polare:                 {Left:358,   Right:377}, 
    Heschl:                        {Left:309,   Right:282}, 
    Planum_Temporale:              {Left:565,   Right:440}, 
    Temporal_fusiform_Ant:         {Left:317,   Right:292}, 
    Temporal_fusiform_Post:        {Left:860,   Right:719}, 
    Temporal_Occipital_Fusiform:   {Left:652,   Right:815}, 
    Postcentral:                   {Left:3673,  Right:3249}, 
    Parietal_Sup_Lobule:           {Left:1460,  Right:1476}, 
    Supramarginal_Ant:             {Left:954,   Right:801}, 
    Supramarginal_Post:            {Left:1064,  Right:1235}, 
    Angular:                       {Left:950,   Right:1469}, 
    Parietal_Oper:                 {Left:567,   Right:538}, 
    Parahippocampal_Ant:           {Left:582,   Right:656}, 
    Parahippocampal_Post:          {Left:390,   Right:319}, 
    Hippocampus:                   {Left:689,   Right:711}, 
    Amygdala:                      {Left:308,   Right:363}, 
    Occipital_Lat_Sup:             {Left:4967,  Right:4813}, 
    Occipital_Lat_Inf:             {Left:2042,  Right:2045}, 
    Intracalcarine:                {Left:661,   Right:772}, 
    Occipital_Pole:                {Left:2757,  Right:2616}, 
    Cuneal:                        {Left:596,   Right:718}, 
    Lingual:                       {Left:1632,  Right:1848}, 
    Occipital_Fusiform:            {Left:932,   Right:888}, 
    Supracalcarine:                {Left:122,   Right:192}, 
    Caudate:                       {Left:493,   Right:517}, 
    Putamen:                       {Left:805,   Right:799}, 
    Pallidum:                      {Left:271,   Right:267}, 
    Thalamus:                      {Left:1309,  Right:1272}, 
    Accumbens:                     {Left:90,    Right:85}, 
    Brainstem:                     {Left:4788,  Right:4788}, 
  },
  MS:{
    dorsoant_caudate:      {Left:165, Right:165},
    ventroant_caudate:     {Left:121, Right:121},
    caudate_tail:          {Left:42,  Right:42},
    caudate_body:          {Left:107, Right:107},
    dorsoant_putamen:      {Left:207, Right:207},
    ventroant_putamen:     {Left:206, Right:206},
    dorsopost_putamen:     {Left:240, Right:240},
    ventropost_putamen:    {Left:245, Right:245},
    ant_pallidum:          {Left:108, Right:108},
    post_pallidum:         {Left:90,  Right:90},
    ventral_striatum_sub1: {Left:116, Right:116},
    ventral_striatum_sub2: {Left:90,  Right:90},
  },
  DAT_AAL3:{
    caudate:      {Left:1, Right:1},
    putamen:      {Left:1, Right:1},
  },
  DAT_HO:{
    caudate:      {Left:1, Right:1},
    putamen:      {Left:1, Right:1},
  }
}