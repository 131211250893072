import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import {
  RefFactor,
  analysisTable1,
  analysisAmyloidDATcard2_2,
} from '0_variables/utils';
import { ANALYSIS_CARD } from '0_variables/analysisConfigs';
import { selectFileById } from '1_reduxs/reducers/filesReducer';
import { QuantTable } from '5_components';
import { spinnerV2 as MEDIA_spinnerV2 } from '6_media';
import styles from './AnalysisTable.module.scss';

export default function AnalysisTable(props) {
  const { tableType, tableHead } = props;
  const { product: productName, fileID: targetFildId } = useParams();
  const { type1Selector, type2Selector } = coreItem[productName];

  const selectedFile = useSelector((state) =>
    selectFileById(state, targetFildId),
  );
  const settingOfProduct = useSelector((state) => state.setting[productName]);
  const defaultAtlas = settingOfProduct.defaultAtlas;
  const [trClick, setTrClick] = useState([]);
  const [sortClick, setSortClick] = useState({ id: 'ascend' });
  const [items, setItems] = useState([]);

  useEffect(() => {
    const tracerName = selectedFile['Tracer'];
    const refName = settingOfProduct.defaultRef[tracerName];
    const defaultAtlas = settingOfProduct.defaultAtlas;

    if (
      tableType === ANALYSIS_CARD.ANALYSIS_TABLE_1 ||
      tableType === ANALYSIS_CARD.ANALYSIS_TABLE_2
    ) {
      const RF = RefFactor({
        productName,
        refName,
        ponsRF: selectedFile['ratio_gry2pons'],
        crblRF: selectedFile['ratio_gry2crbl'],
        wmRF: selectedFile['ratio_gry2wm'],
        cgRF: selectedFile['ratio_gry2cg'],
        global: 1 / selectedFile['Global'],
      });

      const dataForCard =
        tableType === ANALYSIS_CARD.ANALYSIS_TABLE_1
          ? analysisTable1({
              settingOfProduct,
              typeSelect: type1Selector,
              tableItems: { tableHead }, // tableItem.tableHead, 기존형태 맞춤
              selectedFile,
              defaultAtlas,
              RF,
              isSelectMultiAtlas: productName === 'dat',
            })
          : analysisAmyloidDATcard2_2({
              settingOfProduct,
              typeSelect: type2Selector,
              tableItems: { tableHead },
              selectedFile,
              defaultAtlas,
            });

      setItems(dataForCard.items);
    }
  }, [
    tableType,
    tableHead,
    productName,
    selectedFile,
    settingOfProduct,
    type1Selector,
    type2Selector,
  ]);

  return (
    <div className={styles.table_content}>
      {!items.length && (
        <div className={styles.loading}>
          <span className={styles.loadIcon}>
            <img
              src={MEDIA_spinnerV2}
              style={{ width: '50%' }}
              alt="spinnerV2"
            />
          </span>
          <span className={styles.loadMsg}>{'Loading...'}</span>
        </div>
      )}

      {items.length && (
        <QuantTable
          tableHead={tableHead}
          items={items}
          trClick={trClick}
          setTrClick={setTrClick}
          sortClick={sortClick}
          setSortClick={setSortClick}
          defaultAtlas={defaultAtlas}
          reportTooltip={true}
        />
      )}
    </div>
  );
}
