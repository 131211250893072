import React, { useState } from 'react';
import styles from './DropDownDiv.module.scss';
import {
  arrow_up as MEDIA_arrow_up,
  arrow_down as MEDIA_arrow_down,
} from '6_media';

function DropDownDiv(props) {
  const { style, item, styleName } = props;
  const { options: dropDownOptions, selectItem, setItem } = item;
  const [showDropdown, setShowDropdown] = useState(false);

  const ColorMapLabels = {
    invertGray: 'Gray scale',
    pet: 'GE',
    jet: 'Jet',
    hot: 'Hot',
  };

  const itemName = ColorMapLabels[selectItem] || selectItem;

  return (
    <div className={styles[styleName]} aria-expanded={showDropdown}>
      <div
        tabIndex={0}
        className={styles['container']}
        aria-label="choose custom item"
        style={style}
        onBlur={() => setShowDropdown(false)}
      >
        <div
          role="button"
          //className props로 받아 바꾸기
          style={{ background: style?.background }}
          className={styles['title_container']}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div>{itemName}</div>
          <div className={styles['arrow_img']}>
            <img
              src={showDropdown ? MEDIA_arrow_up : MEDIA_arrow_down}
              alt={showDropdown ? 'arrow_up' : 'arrow_down'}
            />
          </div>
        </div>
        <div
          className={styles['drop_container']}
          style={{
            display: `${showDropdown ? '' : 'none'}`,
            background: style?.background,
          }}
          role="listbox"
          aria-label="dropdown list"
        >
          {dropDownOptions.map((itemLabel, i) => {
            const itemName = ColorMapLabels[itemLabel] || itemLabel;

            return (
              <div
                key={`dropdown_item_${i}`}
                role="option"
                aria-selected={itemName === selectItem}
                onClick={() => {
                  setShowDropdown(false);
                  setItem(itemLabel);
                }}
              >
                {itemName}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DropDownDiv;
