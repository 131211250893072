import Dexie from 'dexie';

const targetVersion = Number(process.env.REACT_APP_BTXDB_VERSION);
const DB_NAME = process.env.REACT_APP_BTXDB_NAME;

class BTXDexie extends Dexie {
  async open() {
    if (this.isOpen()) return super.open();

    return Dexie.Promise.resolve()
      .then(() => Dexie.exists(this.name))
      .then(async (exists) => {
        if (!exists) {
          // no need to check database version since it doesn't exist
          return;
        }

        // Open separate instance of dexie to get current database version
        return new Dexie(this.name).open().then(async (db) => {
          if (db.verno >= targetVersion) {
            // database up to date (or newer)
            return db.close();
          }

          console.log(
            `Database schema out of date, resetting all data. (currentVersion: ${db.verno}, expectedVersion: ${targetVersion})`,
          );
          await db.delete();

          // ensure the delete was successful
          const exists = await Dexie.exists(this.name);
          if (exists) {
            throw new Error('Failed to remove mock backend database.');
          }
        });
      })
      .then(() => super.open());
  }
}

const BTXDB = new BTXDexie(DB_NAME);
BTXDB.version(targetVersion).stores({
  slices: '++sliceId, CaseID',
});

export default BTXDB;
