import React from 'react';
import styles from './ReportPage1.module.scss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BrainLobeFormats } from '0_variables/analysisConfigs';
import { RefFactor, dataFormatForBrainTable } from '0_variables/utils';
import { BrainTable, PatientInfoTable } from '5_components';
import {
  reportIcon1 as MEDIA_reportIcon1,
  reportIcon2 as MEDIA_reportIcon2,
} from '6_media';
import ReportLayout from '../ReportLayout/ReportLayout';
import Watermark from '../watermark';

export default function ReportPage1(props) {
  const { selectedFile, fileID, pageNumber } = props;
  const { product: productName } = useParams();
  const setting = useSelector((state) => state.setting);
  const { legend, colorcode, content } = BrainLobeFormats[productName];
  const settingOfProduct = setting[productName];
  const tracerName = selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName];

  const tracerNames = {
    '[18F]FMM': '[18F]Flutemetamol',
    '[18F]FBP': '[18F]Florbetapir',
    '[18F]FBB': '[18F]Florbetaben',
    '[18F]FPN': '[18F]Florapronol',
    '[18F]FPCIT': '[18F]FP-CIT',
    '[18F]FDG': '[18F]FDG',
    '[18F]TAU': '[18F]TAU',
    '[99mtc]HMPAO': '[99mtc]HMPAO',
    '[99mtc]ECD': '[99mtc]ECD',
  };

  const RF = RefFactor({
    productName,
    refName,
    ponsRF: selectedFile['ratio_gry2pons'],
    crblRF: selectedFile['ratio_gry2crbl'],
    wmRF: selectedFile['ratio_gry2wm'],
    cgRF: selectedFile['ratio_gry2cg'],
    global: 1 / selectedFile['Global'],
  });

  const dataForCard1 = dataFormatForBrainTable({
    productName,
    columnList: content,
    selectedFile: selectedFile,
    settingOfProduct: setting[productName],
    RF,
  });
  return (
    <>
      <ReportLayout
        pageNumber={pageNumber}
        selectedFile={selectedFile}
        PatientID={selectedFile.PatientID}
      >
        <Watermark />
        <div className={styles.PatientInfoContainer}>
          <div className={styles.ReportTitle}>
            <img src={MEDIA_reportIcon1} alt="" />
            &nbsp;{'Patient Information'}
            <div style={{ visibility: 'hidden' }}>{fileID}</div>
          </div>
          <div className={styles.PatientInfoTable}>
            <PatientInfoTable
              contents={{
                'Patient ID': selectedFile.PatientID,
                Name: selectedFile.PatientName,
                DOB: selectedFile.Age,
                Sex: selectedFile.Sex,
                'Study Date': selectedFile.AcquisitionDateTime,
                'Study Description': tracerNames[selectedFile.Tracer],
              }}
            />
          </div>
        </div>
        <div className={styles.BrainTableContainer}>
          <div className={styles.ReportTitle}>
            <img src={MEDIA_reportIcon2} alt="" />
            &nbsp;{'Quantification Results'}
          </div>
          <div className={styles.BrainTable}>
            <div>
              <BrainTable
                borderEnable={false}
                legend={legend}
                colorcode={colorcode}
                items={dataForCard1}
                refName={refName}
              />
            </div>
          </div>
        </div>
      </ReportLayout>
    </>
  );
}
