import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  ANALYSIS_CARD,
  AnalysisLayout,
  AnalysisTableForm,
} from '0_variables/analysisConfigs';
import { selectFileById } from '1_reduxs/reducers/filesReducer';
import { PageLayout, CardLayout, Information } from '5_components';
import { BrainLobarCard, AnalysisTable, BrainSurfaceCard } from './components';

export default function Analysis() {
  const history = useHistory();
  const { product: productName, fileID: targetFildId } = useParams();
  const selectedFile = useSelector((state) =>
    selectFileById(state, targetFildId),
  );
  const selectedFileID = useSelector((state) => state.control.selectedFileID);

  const analysisPageTop = () => {
    const burgerProps = {
      Enable: true,
      isViewContainer: false,
      isWorklist: false,
    };

    return <Information burgerProps={burgerProps} isSubtractMode={false} />;
  };

  const analysisPageBottom = () => {
    if (!selectedFileID) return null;

    const tracerName = selectedFile['Tracer'];
    let cardGroups = AnalysisLayout[productName].bottomCards;

    if (productName === 'amyloid' && tracerName === '[18F]FPN') {
      cardGroups = cardGroups.map((cards) =>
        cards.filter(({ title }) => title !== 'Regional Centiloid'),
      );
    }

    const getComponent = (componentName, cardTitle) => {
      const ANALYSIS_TABLES = [
        ANALYSIS_CARD.ANALYSIS_TABLE_1,
        ANALYSIS_CARD.ANALYSIS_TABLE_2,
      ];

      const tableForm = AnalysisTableForm.find(
        ({ title }) => title === cardTitle,
      );
      const tableHead = tableForm ? tableForm.tableHead : [];

      switch (componentName) {
        case ANALYSIS_CARD.BRAIN_LOBE:
          return <BrainLobarCard />;
        case ANALYSIS_CARD.BRAIN_SURFACE:
          return <BrainSurfaceCard />;
        default:
          if (ANALYSIS_TABLES.includes(componentName)) {
            return (
              <AnalysisTable tableType={componentName} tableHead={tableHead} />
            );
          }
          return null;
      }
    };

    const cardItemList = cardGroups.map((cards) => {
      return cards.map(({ title, componentName }) => ({
        componentName,
        title,
        content: getComponent(componentName, title),
      }));
    });

    return (
      <React.Fragment>
        {cardItemList.map((cardItem, i) => (
          <CardLayout key={`card_layout_${i}`} cards={cardItem} />
        ))}
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (targetFildId !== selectedFileID) {
      if (!selectedFileID) {
        history.push(`/${productName}/upload`);
        return;
      }

      history.push(`/${productName}/analysis/${selectedFileID}`);
      return;
    }
  }, [history, productName, targetFildId, selectedFileID]);

  return (
    <PageLayout
      topContent={analysisPageTop}
      bottomContent={analysisPageBottom}
    />
  );
}
