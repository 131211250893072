import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetPasswdThunk } from '1_reduxs/actions/loginAction';
import { HomePassButton, InputText } from '5_components';
import styles from './Forgot.module.scss';

export default function Forgot() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [forgotValues, setForgotValues] = useState({
    username: '',
    email: '',
    password1: '',
    password2: '',
  });
  const validPassword = forgotValues.password1 === forgotValues.password2;

  const processFunc = async () => {
    if (validPassword === false) {
      alert('The password confirmation doses not match.');
      return;
    }

    const resObj = await dispatch(
      resetPasswdThunk({
        username: forgotValues.username,
        email: forgotValues.email,
        password1: forgotValues.password1,
        password2: forgotValues.password2,
      }),
    );

    if (resObj.response) {
      // reset success
      alert(resObj.message);
      history.push(`/signin`);
      setForgotValues({
        username: '',
        email: '',
        password1: '',
        password2: '',
      });
    } else {
      // reset failed
      if (resObj.code === undefined)
        alert(
          'Failed to reset the password due to connection error. Please check your connection to the server, and try again.',
        );
      else {
        alert('Please enter a valid ID and email.');
      }
    }
  };

  const propsForgot = {
    processFunc,
    btnClassName: 'forgot-btn',
    title: 'SUBMIT',
  };

  return (
    <div className={styles['container']}>
      {/* <div>{location.pathname}</div> */}
      <div>
        <h1 className={`${styles['title']}`}>{'PASSWORD'}</h1>
        <InputText
          title="USERNAME"
          name="username"
          placeholder="Enter username"
          value={forgotValues}
          setValue={setForgotValues}
        />
        <InputText
          title="EMAIL"
          name="email"
          placeholder="Enter email"
          value={forgotValues}
          setValue={setForgotValues}
        />
        <InputText
          title="NEW PASSWORD"
          type="password"
          name="password1"
          placeholder="Enter new password"
          value={forgotValues}
          setValue={setForgotValues}
        />
        <InputText
          title="CONFIRM PASSWORD"
          type="password"
          name="password2"
          placeholder="Enter confirm password"
          value={forgotValues}
          setValue={setForgotValues}
        />
        <div
          className={`${styles['CheckPassword']}`}
          style={{ display: `${validPassword ? 'none' : ''}` }}
        >
          The password confirmation doses not match.
        </div>
        <HomePassButton item={propsForgot} />
        {/* <div><button onClick={()=>history.push('/signin')}>Submit</button></div> */}
      </div>
    </div>
  );
}
