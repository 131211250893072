import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { coreItem } from '0_variables/coreItem';
import {
  getShortName,
  RefFactor,
  generateCSV,
  analysisTable1,
  analysisAmyloidDATcard2_2,
  structAmyloidCSV,
  structDATCSV,
  structFDGTauCSV,
} from '0_variables/utils';
import { AnalysisTableForm } from '0_variables/analysisConfigs';
import * as downloadApi from '2_services/downloadApi';
import { viewBurger as MEDIA_viewBurger } from '6_media';
import styles from './Burger.module.scss';
import { selectFilesByIds } from '1_reduxs/reducers/filesReducer';

/**
 * * view, analysis, worklist에 있는 기능들을 하나로 병합하여 burger에서 처리
 * ! refValue는 view, analysis와 worklist가 동일하지 않았다. 지금은 view, analysis를 기준으로 했다.
 *   동작에 문제는 없어보인다.
 */

export default function Burger(props) {
  const {
    Enable,
    isViewContainer,
    isWorklist,
    pdfExportWorklist,
    selectFileIds = [],
  } = props;
  const history = useHistory();
  const { product: productName, fileID: targetFildId } = useParams();
  const SUFFIX = productName === 'dat' ? 'SBR' : 'SUVR';
  const username = sessionStorage.getItem('username');

  const productCoreItem = coreItem[productName];
  const { type1Selector, type2Selector } = productCoreItem;

  const totalFileList = useSelector(
    (state) => state.fileList.totalFiles[productName],
  );

  const settingOfProduct = useSelector((state) => state.setting[productName]);
  const defaultAtlas = settingOfProduct.defaultAtlas;
  const defaultCSVFormat = settingOfProduct.defaultCSVFormat;
  const isCSVTranspose = defaultCSVFormat[3].state;

  const worklistSelectedFiles = useSelector((state) =>
    selectFilesByIds(state, selectFileIds),
  );
  const completedFiles = worklistSelectedFiles.filter(
    (item) => item.Complete === true,
  );

  const [clickState, setClickState] = useState(false);

  const getRefName = (file) => {
    const tracerName = file['Tracer'];
    const refName = settingOfProduct.defaultRef[tracerName];
    return { refName, shortRefName: getShortName(refName) };
  };

  const getDownloadFileName = (selectedFile, fileSuffix) => {
    return (
      selectedFile.PatientID.replaceAll(/[^0-9/-]/gi, '') +
      '_' +
      selectedFile.Tracer.replaceAll(/[^\w]/gi, '').replace('18F', 'F18') +
      '_' +
      selectedFile.AcquisitionDateTime.replaceAll(
        /([0-9])[/-]([0-9])/gi,
        '$1$2',
      ) +
      fileSuffix
    );
  };

  const getRefValue = (selectedFile, refName) => {
    return RefFactor({
      productName,
      refName,
      ponsRF: selectedFile['ratio_gry2pons'],
      crblRF: selectedFile['ratio_gry2crbl'],
      wmRF: selectedFile['ratio_gry2wm'],
      cgRF: selectedFile['ratio_gry2cg'],
      // TODO :  worklist와 view, analysis에서 global을 다르게 쓰고있음. 확인필요.
      global: 1 / selectedFile['Global'],
    });
  };

  // csv export begin
  const exportCSV = () => {
    if (productName === 'amyloid') return amyloidExportCSV();
    else if (productName === 'dat') return datExportCSV();
    else if (productName === 'fdg') return fdg_tau_perfusionExportCSV();
    else if (productName === 'tau') return fdg_tau_perfusionExportCSV();
    else if (productName === 'perfusion') return fdg_tau_perfusionExportCSV();
    else return amyloidExportCSV();
  };

  const getExportFiles = () => {
    return isWorklist
      ? completedFiles
      : [totalFileList.find((item) => item.fileID === targetFildId)];
  };

  const downloadCSV = (targetFiles, structuredData) => {
    let fileName = 'brtnx_quantified.csv';

    if (isWorklist === false) {
      const selectedFile = targetFiles[0];
      const { shortRefName } = getRefName(selectedFile);

      const fileSuffix = `_${SUFFIX}_${shortRefName}_${selectedFile.fileID}.csv`;
      fileName = getDownloadFileName(selectedFile, fileSuffix);
    }
    generateCSV({
      data: structuredData,
      transpose: isCSVTranspose,
      fname: fileName,
    });
  };

  const amyloidExportCSV = () => {
    const targetFiles = getExportFiles();

    const structuredData = targetFiles.reduce((result, srcData, idx) => {
      const selectedFile = srcData;
      const { refName, shortRefName } = getRefName(selectedFile);
      const refValue = getRefValue(selectedFile, refName);
      const ref = { fullName: refName, shortName: shortRefName };

      const table1TableHead = AnalysisTableForm.find(
        ({ title }) => title === 'Regional SUVR',
      ).tableHead;

      const table2TableHead = AnalysisTableForm.find(
        ({ title }) => title === 'Regional Centiloid',
      ).tableHead;

      const godThanksSUVR = analysisTable1({
        settingOfProduct,
        typeSelect: type1Selector,
        tableItems: {
          tableHead: table1TableHead,
        },
        selectedFile,
        defaultAtlas,
        RF: refValue,
        isSelectMultiAtlas: false,
      });

      const godThanksCentiloid = analysisAmyloidDATcard2_2({
        settingOfProduct,
        typeSelect: type2Selector,
        tableItems: {
          tableHead: table2TableHead,
        },
        selectedFile,
        defaultAtlas,
      });

      const structData = structAmyloidCSV({
        ref,
        atlas: defaultAtlas,
        srcData: selectedFile,
        godThanksSUVR,
        godThanksCentiloid,
        defaultCSVFormat,
      });

      return idx === 0
        ? (result = [...structData.headRows, ...structData.bodyRows])
        : (result = [...result, ...structData.bodyRows]);
    }, []);

    downloadCSV(targetFiles, structuredData);
  };

  const datExportCSV = () => {
    const targetFiles = getExportFiles();

    const structuredData = targetFiles.reduce((result, srcData, idx) => {
      const selectedFile = srcData;
      const { refName, shortRefName } = getRefName(selectedFile);
      const refValue = getRefValue(selectedFile, refName);
      const ref = { fullName: refName, shortName: shortRefName };

      const table1TableHead = AnalysisTableForm.find(
        ({ title }) => title === 'Regional SBR',
      ).tableHead;

      const table2TableHead = AnalysisTableForm.find(
        ({ title }) => title === 'Semiquantification',
      ).tableHead;

      const godThanksSBR = analysisTable1({
        settingOfProduct,
        typeSelect: type1Selector,
        tableItems: {
          tableHead: table1TableHead,
        },
        selectedFile,
        defaultAtlas,
        RF: refValue,
        isSelectMultiAtlas: true,
      });

      const godThanksSemiquatification = analysisAmyloidDATcard2_2({
        settingOfProduct,
        typeSelect: type2Selector,
        tableItems: {
          tableHead: table2TableHead,
        },
        selectedFile,
        defaultAtlas,
      });

      const structData = structDATCSV({
        ref,
        atlas: defaultAtlas,
        srcData: selectedFile,
        godThanksSBR,
        godThanksSemiquatification,
        defaultCSVFormat,
      });

      return idx === 0
        ? (result = [...structData.headRows, ...structData.bodyRows])
        : (result = [...result, ...structData.bodyRows]);
    }, []);

    downloadCSV(targetFiles, structuredData);
  };

  const fdg_tau_perfusionExportCSV = () => {
    const targetFiles = getExportFiles();

    const structuredData = targetFiles.reduce((result, srcData, idx) => {
      const selectedFile = srcData;
      const { refName, shortRefName } = getRefName(selectedFile);
      const refValue = getRefValue(selectedFile, refName);
      const ref = { fullName: refName, shortName: shortRefName };

      const table1TableHead = AnalysisTableForm.find(
        ({ title }) => title === 'Regional SUVR',
      ).tableHead;

      const godThanksSUVR = analysisTable1({
        settingOfProduct,
        typeSelect: type1Selector,
        tableItems: {
          tableHead: table1TableHead,
        },
        selectedFile: selectedFile,
        defaultAtlas: defaultAtlas,
        RF: refValue,
        isSelectMultiAtlas: false,
      });

      const structData = structFDGTauCSV({
        ref,
        atlas: defaultAtlas,
        srcData: selectedFile,
        godThanksSUVR,
        defaultCSVFormat,
      });

      return idx === 0
        ? (result = [...structData.headRows, ...structData.bodyRows])
        : (result = [...result, ...structData.bodyRows]);
    }, []);

    downloadCSV(targetFiles, structuredData);
  };
  // csv export end

  // NIFTI export begin
  const downloadNifitiSUVR_Individaul = () => {
    const selectedFile = totalFileList.find(
      (item) => item.fileID === targetFildId,
    );
    const { shortRefName } = getRefName(selectedFile);
    const link = `${process.env.REACT_APP_BASE_URL}result/download/${username}/database/${selectedFile.fileID}/surv_output_${selectedFile.fileID}.nii`;
    const fileSuffix = `_${SUFFIX}_${shortRefName}_${selectedFile.fileID}.nii`;
    const fname = getDownloadFileName(selectedFile, fileSuffix);

    const getFile = async (link, fname) => {
      const res = await axios.get(link, { responseType: 'blob' });
      const file = new Blob([res.data]);
      let element = document.createElement('a');
      element.href = URL.createObjectURL(file);
      element.download = fname;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    return getFile(link, fname);
  };

  const downloadNifitiIntensity_Individaul = () => {
    const selectedFile = totalFileList.find(
      (item) => item.fileID === targetFildId,
    );
    const link = `${process.env.REACT_APP_BASE_URL}result/download/${username}/database/${selectedFile.fileID}/output_${selectedFile.fileID}.nii`;
    const fileSuffix = `_intensity_${selectedFile.fileID}.nii`;
    const fname = getDownloadFileName(selectedFile, fileSuffix);

    const getFile = async (link, fname) => {
      const res = await axios.get(link, { responseType: 'blob' });
      const file = new Blob([res.data]);
      let element = document.createElement('a');
      element.href = URL.createObjectURL(file);
      element.download = fname;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    return getFile(link, fname);
  };

  const downloadNifitiSUVR_Group = async () => {
    const res = await downloadApi.zipNiftiSUVR(completedFiles);
    if (res.status === 200) {
      const downloadUrl =
        process.env.REACT_APP_BASE_URL +
        'result/download/' +
        username +
        '/downloader/brightonix_imaging_niftii_suvr.zip';
      setTimeout(() => window.open(downloadUrl, '_blank'), 1000);
    } else {
      alert('Download failed');
    }
  };

  const downloadNifitiIntensity_Group = async () => {
    const res = await downloadApi.zipNiftiIntensity(completedFiles);
    if (res.status === 200) {
      const downloadUrl =
        process.env.REACT_APP_BASE_URL +
        'result/download/' +
        username +
        '/downloader/brightonix_imaging_niftii_intensity.zip';
      setTimeout(() => window.open(downloadUrl, '_blank'), 1000);
    } else {
      alert('Download failed');
    }
  };
  // NIFTI export end

  const tempMenuItems = isWorklist
    ? [
        {
          title: 'Generate a report',
          handleClick: pdfExportWorklist,
        },
        { title: 'Export to CSV', handleClick: exportCSV },
        {
          title: `Export to NIFTI (${SUFFIX})`,
          handleClick: downloadNifitiSUVR_Group,
        },
        {
          title: 'Export to NIFTI (Raw)',
          handleClick: downloadNifitiIntensity_Group,
        },
      ]
    : [
        {
          title: 'Generate a report',
          handleClick: () =>
            history.push(`/${productName}/report/${targetFildId}`),
        },
        { title: 'Export to CSV', handleClick: exportCSV },
        {
          title: `Export to NIFTI (${SUFFIX})`,
          handleClick: downloadNifitiSUVR_Individaul,
        },
        {
          title: 'Export to NIFTI (Raw)',
          handleClick: downloadNifitiIntensity_Individaul,
        },
      ];

  return (
    <div
      tabIndex={0}
      className={isViewContainer ? styles.view_burger : styles.container}
      role="button"
      aria-label="burger menu"
      onClick={() => {
        if (Enable) {
          setClickState(!clickState);
        } else {
          alert(
            'Cannot open the menu when no files or files being processed are selected.',
          );
        }
      }}
      onBlur={() => setClickState(false)}
      style={{ opacity: `${Enable ? '1' : '0.2'}` }}
    >
      <div className={styles.burger_container}>
        <img src={MEDIA_viewBurger} alt="menu" />
      </div>
      <div
        className={
          isViewContainer ? styles.view_dropdown : styles.list_container
        }
        style={{ display: `${clickState ? '' : 'none'}` }}
      >
        {tempMenuItems.map((item, idx) => (
          <div
            key={idx}
            onClick={() => {
              item.handleClick();
              setClickState(false);
            }}
            role="button"
          >
            <div>{item.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
