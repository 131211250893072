import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { BsExclamationCircle } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import {
  openSpinner,
  openReportPacsModal,
} from '1_reduxs/reducers/modalReducer';
import { selectFileById } from '1_reduxs/reducers/filesReducer';
import { saveReportToPacs } from '2_services/uploadApi';
import styles from './ReportPacsModal.module.scss';

export default function ReportPacsModal() {
  const dispatch = useDispatch();
  const { fileID: targetFildId } = useParams();
  const selectedFile = useSelector((state) =>
    selectFileById(state, targetFildId),
  );
  const [patientID, setPatientID] = useState(selectedFile.PatientID || '');

  const handleChange = (e) => {
    setPatientID(e);
  };

  // Kevin 1130 upload to pacs
  const confirmHandler = async (pid, selectedFile) => {
    const spinnerController = (onoff) => {
      if (onoff)
        dispatch(
          openSpinner({
            status: true,
            percent: 0,
            message: 'Uploading to PACS...',
            target: 'REPORT',
          }),
        );
      else
        dispatch(
          openSpinner({
            status: false,
            percent: 0,
            message: 'Uploading to PACS...',
            target: 'REPORT',
          }),
        );
    };

    spinnerController(true);

    let res;
    try {
      res = await saveReportToPacs(pid, selectedFile.id);
      spinnerController(false);
    } catch {
      console.log(res);
      alert('Uplading to PACS was failed!');
      spinnerController(false);
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.xIcon}
        onClick={() => {
          dispatch(openReportPacsModal({ isOn: false }));
        }}
      >
        <AiOutlineClose size={'0.8vw'} />
      </div>
      <div className={styles.contents_container}>
        {/* <div>{selectedFile?.PatientID}</div> */}
        <div className={styles.contents}>
          <div className={styles.title}>
            <BsExclamationCircle size={'0.8vw'} />
            <div>Please verify patient ID</div>
          </div>
          <input
            placeholder={patientID}
            value={patientID}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
        <div
          className={styles.confirm_btn}
          onClick={async () => {
            await confirmHandler(patientID, selectedFile);
            dispatch(openReportPacsModal({ isOn: false }));
          }}
        >
          Confirm
        </div>
      </div>
    </div>
  );
}
