import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BiTrash } from 'react-icons/bi';
import { useInterval } from 'react-use';
import { coreItem } from '0_variables/coreItem';
import {
  openGroupFiles,
  closeSingleFile,
  isAnySelected,
  isThisAFile,
} from '0_variables/utils';
import {
  checkFileThunk,
  updateWorklistThunk,
  deleteApiThunk,
} from '1_reduxs/actions/filesAction';
import {
  selectFilesByIds,
  selectPaginatedFiles,
  updatePagination,
  updateSelectFileIds,
} from '1_reduxs/reducers/filesReducer';
import {
  openSpinner,
  openPacsModal,
  openSendedDicomModal,
  openUploadModal,
  openExpiredModal,
  openSpinnerThunk,
} from '1_reduxs/reducers/modalReducer';
import { updateLayoutState } from '1_reduxs/reducers/layoutReducer';
import * as services from '2_services/uploadApi';
import {
  PageLayout,
  Buttons,
  FileTable,
  PacsModal,
  SendedDicomModal,
  UploadModal,
  ExpiredModal,
} from '5_components';
import styles from './Upload.module.scss';

// todo pagination componnet 추가 page 바뀔때 selectfileids clear
export default function Upload() {
  const PAGE_NAME = 'upload';
  const history = useHistory();
  const dispatch = useDispatch();

  const IS_ENABLE_PAGINATION =
    process.env.REACT_APP_ENABLE_PAGINATION === 'true';
  // const [selectFileIds, setSelectFileIds] = useState([]);
  const modalState = useSelector((state) => state.modal);
  const productName = useSelector((state) => state.fileList.currentProduct);
  const selectFileIds = useSelector(
    (state) => state.fileList.uploadSelectFileIds,
  );
  const pagination = useSelector((state) => state.fileList.pagination);
  const fileListFilter = useSelector((state) => state.fileList.filter);
  const { endDate, remainCounts } = useSelector((state) => state.layoutState);
  const { items: paginatedFiles, totalSize } =
    useSelector(selectPaginatedFiles);
  const selectedFileList = useSelector((state) =>
    selectFilesByIds(state, selectFileIds),
  );
  const pendingFile = paginatedFiles.filter((file) => !file.Complete);

  const fileInputRef = useRef(null);
  const prevCurrentPageRef = useRef(1); // for selectFileIds clear
  const prevProductName = useRef(productName); // for selectFileIds clear
  const productCoreItem = coreItem[productName];
  const { CTN_Mode, Cloud_Mode } = productCoreItem;
  const [uploadResult, setUploadResult] = useState([]);
  const [getResult, setGetResult] = useState([]);

  const [dragState, setDragState] = useState({ active: false, type: '' });
  const tableHead = productCoreItem[PAGE_NAME].bottom.tableHead;

  useInterval(() => {
    if (pendingFile.length) {
      const pendingFileIds = pendingFile.map((el) => el.fileID);
      dispatch(checkFileThunk({ check_list: pendingFileIds, productName }));
    }
  }, 1000 * 15);

  const setSelectFileIds = useCallback(
    (fileIds) => {
      dispatch(updateSelectFileIds(fileIds));
    },
    [dispatch],
  );

  const setSortClick = (tableHead) => {
    const { title, fieldName } = tableHead;
    const { orderBy, orderDirection } = pagination;

    if (title === 'Select') {
      return;
    }
    if (orderBy === fieldName) {
      dispatch(
        updatePagination({
          ...pagination,
          orderDirection: orderDirection === 'asc' ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(
        updatePagination({
          ...pagination,
          orderBy: fieldName,
          orderDirection: 'desc',
        }),
      );
    }
  };

  const viewAnalysisHandler = (targetPage) => {
    const completedFiles = selectedFileList.filter(
      (file) => file.Complete === true,
    );

    const selectedFileID = completedFiles.at(-1).fileID;

    openGroupFiles({
      targetPage, // view or analysis
      completedFiles,
      selectedFileID,
      productName,
      dispatch,
      history,
    });
  };

  const closeHandler = () => {
    selectedFileList.forEach((item) => {
      closeSingleFile(dispatch, item, productName);
    });
  };

  const worklistHandler = (status) => {
    dispatch(
      updateLayoutState({ optionName: 'isOpenedWorklist', updateValue: true }),
    );

    dispatch(updateWorklistThunk(status, selectedFileList));
  };

  const deleteHandler = () => {
    if (window.confirm('Are you sure to delete?')) {
      dispatch(deleteApiThunk(selectedFileList, productName));

      selectedFileList.forEach((item) => {
        closeSingleFile(dispatch, item, productName);
      });
    }
  };

  const clickedFileInput = () => {
    window.addEventListener('focus', handleFocusBack);
  };

  const handleFocusBack = (e) => {
    window.removeEventListener('focus', handleFocusBack);
  };

  const closeModal = (type) => {
    if (type === 'pacs') {
      dispatch(openPacsModal({ isOn: false }));
    } else if (type === 'upload') {
      dispatch(openUploadModal({ isOn: false }));
    } else if (type === 'sendedDicom') {
      dispatch(openSendedDicomModal({ isOn: false }));
    } else {
      dispatch(openExpiredModal({ isOn: false }));
    }
  };

  const openModal = (type) => {
    if (type === 'pacs') {
      dispatch(openPacsModal({ isOn: true }));
    } else if (type === 'sendedDicom') {
      dispatch(openSendedDicomModal({ isOn: true }));
    } else if (type === 'upload') {
      dispatch(openUploadModal({ isOn: true }));
    } else {
      dispatch(openExpiredModal({ isOn: true }));
    }
  };

  const pacsModalProps = {
    openModal: () => {
      openModal('pacs');
    },
    closeModal: () => closeModal('pacs'),
  };

  const sendedDicomModalProps = {
    openModal: () => {
      openModal('sendedDicom');
    },
    closeModal: () => closeModal('sendedDicom'),
    uploadResult,
    setUploadResult,
    getResult,
    setGetResult,
  };

  const expiredModalProps = {
    openModal: () => {
      openModal('expired');
    },
    closeModal: () => closeModal('expired'),
  };

  const uploadModalProps = {
    openModal: () => openModal('upload'),
    closeModal: () => closeModal('upload'),
    uploadResult,
    setUploadResult,
    getResult,
    setGetResult,
  };

  const uploadFiles = async (files) => {
    openModal('upload');

    const uploadResults = [];

    try {
      dispatch(
        openSpinner({
          status: true,
          length: {
            totalCount: files.length,
            currentValue: 0,
            processMessage: 'Upload starts',
          },
          message: 'Uploading...',
          target: 'UPLOAD_PROGRESS_BAR',
        }),
      );

      const keyStr =
        process.env.REACT_APP_IS_OCI === 'true'
          ? Math.random().toString(36).substring(2, 10)
          : '';

      for (let i = 0; i < files.length; i += 1) {
        let message = 'Uploading...';
        const uploadFile = files[i];

        const formData = new FormData();
        formData.append('myfiles', uploadFile);

        if (process.env.REACT_APP_IS_OCI !== 'true') {
          // on-premise version
          if (i === 0) {
            formData.append('isFirst', true);
          }

          if (i === files.length - 1) {
            formData.append('isLast', true);
          }
        } else {
          // cloud version
          formData.append('uploadIdx', i);
          formData.append('keyStr', keyStr);
          formData.append('product', productName);
        }

        if (i === files.length - 1) {
          message = 'Converting...';
        }

        const spinnerState = await dispatch(
          openSpinnerThunk({
            length: {
              totalCount: files.length,
              currentValue: i,
              processMessage: `${uploadFile.name}`,
            },
            message,
          }),
        );

        let result = await services.postFile({
          obj: formData,
        });

        // cloud version only
        if (process.env.REACT_APP_IS_OCI === 'true') {
          if (i === files.length - 1) {
            result = await services.getFile_cached({ keyStr });
          }
        }

        // 마지막 upload에서만 데이터 들어옴.
        uploadResults.push(...result.data);

        // modal이 닫히면 upload spinner 종료
        if (spinnerState.status === false) {
          throw new Error('Upload is canceled');
        }
      }
    } catch (e) {
      console.log(e);
      // TODO: 파일 직접업로드 실패시 예외처리 필요.
      alert(
        'Failed to upload data. Please check your connection to the server.',
      );
      dispatch(
        openSpinner({
          status: false,
          message: 'Upload Failed',
          target: 'UPLOAD_PROGRESS_BAR',
        }),
      );
      closeModal('upload');
    } finally {
      setGetResult(uploadResults);
      dispatch(
        openSpinner({
          status: false,
          length: { totalCount: files.length - 1, currentValue: files.length },
          message: 'Upload Done',
          target: 'UPLOAD_PROGRESS_BAR',
        }),
      );
    }
  };

  const uploadPageTop = () => {
    const { top } = productCoreItem[PAGE_NAME];

    const downloadSampleData = () => {
      const link =
        process.env.REACT_APP_BASE_URL + 'result/download/samples.zip';
      const fname = 'samples.zip';
      const getFile = async (link, fname) => {
        const res = await axios.get(link, { responseType: 'blob' });
        const file = new Blob([res.data]);
        let element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = fname;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      };
      return getFile(link, fname);
    };

    const sendedDicom = async () => {
      openModal('sendedDicom');
      let res;
      const token = sessionStorage.getItem('token');
      dispatch(
        openSpinner({
          status: true,
          length: 0,
          message: 'Running...',
          target: '',
        }),
      );
      try {
        res = await services.postFile_sended({ token: token });
        // setGetResult(res.data);
      } catch {
        alert(
          'There is data that cannot be analyzed. Please check the files sent to you.',
        );
        return;
      }
      setGetResult(res.data);
      dispatch(
        openSpinner({
          status: false,
          length: 0,
          message: '',
          target: '',
        }),
      );
      // window.removeEventListener('focus', handleFocusBack);
    };

    const uploadClickHandler = async (e) => {
      uploadFiles(e.target.files);
    };

    const funcSelector = {
      view: () => viewAnalysisHandler('view'),
      analysis: () => viewAnalysisHandler('analysis'),
      close: closeHandler,
      worklist_add: () => worklistHandler(true),
      worklist_delete: () => worklistHandler(false),
      file_delete: deleteHandler,
      pacs: () => openModal('pacs'),
      sample: downloadSampleData,
      upload: clickedFileInput,
    };

    const isEnableButton = () => {
      return isAnySelected(selectedFileList);
    };

    const topBtnGroup1 = top.btnGroup1.filter((v) => {
      const shouldIncludeButton =
        (CTN_Mode ? v.title !== 'Analysis' : true) &&
        (IS_ENABLE_PAGINATION ? v.title !== 'Close' : true);
      return shouldIncludeButton;
    });

    return (
      <div className={styles.btnContainer}>
        <div className={styles.btnGroup1}>
          {topBtnGroup1.map((v, idx) => {
            const { icon, title, alt, funcName } = v;

            return (
              <Buttons
                key={idx}
                styleName="leftBtn"
                imgSrc={icon}
                title={title}
                alt={alt || title}
                Enable={isEnableButton()}
                func={{ onClickHandler: funcSelector[funcName] }}
              />
            );
          })}
        </div>
        {!CTN_Mode && (
          <div className={styles.btnGroup2}>
            {top.btnGroup2.map((v, idx) => {
              const { icon, title, alt, funcName } = v;

              return (
                <Buttons
                  key={idx}
                  styleName="leftBtn"
                  imgSrc={icon}
                  title={title}
                  alt={alt || title}
                  Enable={isEnableButton()}
                  func={{ onClickHandler: funcSelector[funcName] }}
                />
              );
            })}
          </div>
        )}

        <div className={styles.btnGroup3}>
          {top.btnGroup3.map((v, idx) => (
            <Buttons
              key={idx}
              {...{
                Enable: selectedFileList.length > 0,
                styleName: 'deleteBtn',
                imgSrc: <BiTrash size={'1vw'} />,
                title: v.title,
                alt: v.alt ? v.alt : v.title,
                func: { onClickHandler: funcSelector[v.funcName] },
              }}
            />
          ))}
        </div>

        <div className={styles.btnGroup4}>
          {top.btnGroup4.map((v, idx) => {
            return (
              <Buttons
                key={idx}
                {...{
                  Cloud_Mode: Cloud_Mode,
                  Enable: true,
                  styleName: 'rightBtn',
                  imgSrc: v.icon,
                  title: v.title,
                  alt: v.alt ? v.alt : v.title,
                  func: {
                    onClickHandler: funcSelector[v.funcName],
                    onClickHandler2: sendedDicom,
                    onChangeHandler: uploadClickHandler,
                    onChangeHandler2: uploadClickHandler,
                  },
                }}
                endDate={endDate}
                remainCounts={remainCounts}
                openModal={openModal}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const uploadPageBottom = (bottom) => {
    return (
      <div className={styles.tableContainer} id="upload_table">
        <FileTable
          identifier={'upload'}
          productName={productName}
          tableHead={tableHead}
          FileList={paginatedFiles}
          totalSize={totalSize}
          setSortClick={setSortClick}
          selectFileIds={selectFileIds}
          setSelectFileIds={setSelectFileIds}
          pagination={pagination}
          filter={fileListFilter}
        />
      </div>
    );
  };

  const inputHandler = async (e) => {
    const isDirectory = e.target.webkitdirectory;
    if (isDirectory) {
      console.log('this is folder type');
    } else {
      console.log('this is file type');
    }
    setDragState({ ...dragState, active: false, type: '' });
    uploadFiles(e.target.files);
  };

  useEffect(() => {
    if (prevCurrentPageRef.current !== pagination.currentPage) {
      setSelectFileIds([]);
      prevCurrentPageRef.current = pagination.currentPage;
    }
    if (prevProductName.current !== productName) {
      setSelectFileIds([]);
      prevProductName.current = productName;
    }
  }, [pagination, productName, setSelectFileIds]);

  return (
    <div
      onDragEnter={(e) => {
        if (dragState.active === false) {
          setDragState({ ...dragState, active: true, type: '' });
        }
      }}
    >
      <PageLayout topContent={uploadPageTop} bottomContent={uploadPageBottom} />
      {modalState.pacsModal.status && <PacsModal {...pacsModalProps} />}
      {modalState.sendedDicomModal.status && (
        <SendedDicomModal {...sendedDicomModalProps} />
      )}
      {modalState.uploadModal.status && <UploadModal {...uploadModalProps} />}
      {modalState.expiredModal.status && (
        <ExpiredModal
          {...expiredModalProps}
          endDate={endDate}
          remainCounts={remainCounts}
        />
      )}

      {dragState.active && (
        <div
          style={{
            boxSizing: 'border-box',
            // border:"1px solid red",
            // padding:"80px",
            width: '100%',
            height: '100%',
            position: 'fixed',
            zIndex: '3',
            top: '0',
            left: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '2vw',
            background: 'rgba(0,0,0,0.9)',
          }}
          onDragEnter={(e) => {
            e.stopPropagation();
          }}
          onDragLeave={() => {
            if (dragState.active && dragState.type === '') {
              setDragState({ ...dragState, active: false, type: '' });
            }
          }}
          onClick={() => {
            setDragState({ ...dragState, active: false, type: '' });
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: `${
                dragState.type === 'file' ? '#118af7' : '#383c41'
              }`,
              width: '30%',
              height: '45%',
              boxSizing: 'border-box',
              borderRadius: '10px',
              overflow: 'hidden',
              position: 'relative',
            }}
            onDragOver={(e) => {
              e.stopPropagation();
              if (dragState.type !== 'file')
                setDragState({ ...dragState, type: 'file' });
            }}
            onDragLeave={(e) => {
              e.stopPropagation();
              if (dragState.type === 'file')
                setDragState({ ...dragState, type: '' });
            }}
          >
            <label
              style={{
                color: 'white',
                marginBottom: '1.5vw',
                fontSize: '2.5vw',
              }}
            >
              {'Drag & Drop Files'}
              <input
                ref={fileInputRef}
                type="file"
                multiple
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  opacity: '0',
                  width: '100%',
                  height: '100%',
                }}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  const file = e.target.files[0];

                  isThisAFile(file)
                    .then((validFile) => {
                      let files_name = [];
                      for (var i = 0; i < e.target.files.length; i++) {
                        const file = e.target.files[i].name;
                        // debugger;
                        const notAllow_file_types = [
                          'avi',
                          'csv',
                          'gif',
                          'img',
                          'png',
                          'jpg',
                          'jpeg',
                          'mp3',
                          'mp4',
                          'mov',
                          'ppt',
                          'wfx',
                          'wma',
                          'zip',
                          'hwp',
                          'exe',
                          'txt',
                          'xls',
                          'xlm',
                          'xlc',
                        ];
                        const file_types_status = notAllow_file_types.some(
                          (item) => file.includes(item),
                        );
                        files_name.push(!file_types_status);
                      }

                      if (files_name.every((item) => item)) inputHandler(e);
                      else {
                        alert(
                          'Medical file formats (nii, dicom) are supported only, please make sure not to upload other file formats.',
                        );
                        setDragState({ ...dragState, active: false, type: '' });
                      }
                    })
                    // .then(validFile => console.log('got a file:', validFile))
                    .catch((error) => {
                      alert(
                        'Folder is not recognized by "Files uploader", please make sure to upload files.',
                      );
                      setDragState({ ...dragState, active: false, type: '' });
                    })
                    .catch((error) =>
                      console.log('looks like a dir', file, error),
                    );
                }}
              />
            </label>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: `${
                dragState.type === 'folder' ? '#118af7' : '#383c41'
              }`,
              width: '30%',
              height: '45%',
              boxSizing: 'border-box',
              borderRadius: '10px',
              overflow: 'hidden',
              position: 'relative',
            }}
            onDragOver={() => {
              if (dragState.type !== 'folder')
                setDragState({ ...dragState, type: 'folder' });
            }}
            onDragLeave={(e) => {
              e.stopPropagation();
              if (dragState.type === 'folder')
                setDragState({ ...dragState, type: '' });
            }}
          >
            <label
              style={{
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5vw',
                fontSize: '2.5vw',
              }}
            >
              {'Drag & Drop a Folder'}
              <input
                type="file"
                // multiple
                webkitdirectory="true"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  opacity: '0',
                  width: '100%',
                  height: '100%',
                }}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  let files_name = [];
                  for (var i = 0; i < e.target.files.length; i++) {
                    const file = e.target.files[i].name;
                    const notAllow_file_types = [
                      'avi',
                      'csv',
                      'gif',
                      'img',
                      'png',
                      'jpg',
                      'jpeg',
                      'mp3',
                      'mp4',
                      'mov',
                      'ppt',
                      'wfx',
                      'wma',
                      'zip',
                      'hwp',
                      'exe',
                      'txt',
                      'xls',
                      'xlm',
                      'xlc',
                    ];
                    const file_types_status = notAllow_file_types.some((item) =>
                      file.includes(item),
                    );
                    files_name.push(!file_types_status);
                  }
                  console.log(files_name);
                  if (files_name.every((item) => item)) {
                    inputHandler(e);
                  } else {
                    alert(
                      'Medical file formats (nii, dicom) are supported only, please make sure not to upload other file formats.',
                    );
                    setDragState({ ...dragState, active: false, type: '' });
                  }
                }}
              />
              <div style={{ fontSize: '0.8vw' }}>
                Please select one folder at a time.
              </div>
            </label>
          </div>
        </div>
      )}
    </div>
  );
}
