import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getRFParamsByFileId, initImageLoader } from '0_variables/utils';
import { selectFileById } from '1_reduxs/reducers/filesReducer';
import { spinnerV2 as MEDIA_spinnerV2 } from '6_media';
import styles from './View.module.scss';
import { Container } from './components';

export default function View() {
  const history = useHistory();
  const { product: productName, fileID: targetFildId } = useParams();
  const selectedFile = useSelector((state) =>
    selectFileById(state, targetFildId),
  );
  const selectedFileID = useSelector((state) => state.control.selectedFileID);
  const [containerProps, setContainerProps] = useState(null);
  const viewFileId = useRef(null);

  useEffect(() => {
    if (targetFildId !== selectedFileID) {
      if (!selectedFileID) {
        history.push(`/${productName}/upload`);
        return;
      }

      history.push(`/${productName}/view/${selectedFileID}`);
      return;
    }

    const loadImages = async () => {
      viewFileId.current = targetFildId;
      const targetFileID = null;

      const fileRFSubset = getRFParamsByFileId(selectedFile);
      let targetFileRFSubset = null;

      if (fileRFSubset === null) {
        console.error('file not found');
        return;
      }

      await initImageLoader(productName, 'view', targetFildId, targetFileID);

      setContainerProps({
        productName,
        fileRFSubset,
        targetFileRFSubset,
        selectedFileID: targetFildId,
        targetFileID,
      });
    };

    if (targetFildId !== null && viewFileId.current !== targetFildId) {
      setContainerProps(null);

      loadImages().catch((error) => {
        console.error(error);
        alert(
          'Image slices are not prepared yet. Please try again later.\n\n' +
            'If you continue to receive this message, it may be due to hardware issue.\n\n' +
            'Please inform it Brighotnix Imaging Inc.(support@brtnx.com)',
        );
        history.push(`/${productName}/upload`);
      });
    }
  }, [history, productName, targetFildId, selectedFileID, selectedFile]);

  if (!targetFildId || containerProps === null) {
    return (
      <div className={styles.loading}>
        <span className={styles.loadIcon}>
          <img src={MEDIA_spinnerV2} style={{ width: '50%' }} alt="spinnerV2" />
        </span>
        <span className={styles.loadMsg}>{'Loading...'}</span>
      </div>
    );
  }

  return (
    <>
      {containerProps && (
        <Container
          productName={containerProps.productName}
          fileRFSubset={containerProps.fileRFSubset}
          selectedFileID={containerProps.selectedFileID}
          targetFileID={containerProps.targetFileID}
          targetFileRFSubset={containerProps.targetFileRFSubset}
        />
      )}
    </>
  );
}
