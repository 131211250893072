import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';
import { coreItem } from '0_variables/coreItem';
import { getLicenseInfo } from '0_variables/utils';
import { startLoginThunk } from '1_reduxs/actions/loginAction';
import { updateLicenseInfo } from '1_reduxs/reducers/licenseReducer';
import { InputText, HomePassButton } from '5_components';
import styles from './Signin.module.scss';

export default function Signin() {
  const history = useHistory();
  const dispatch = useDispatch();
  const productCoreItem = coreItem['amyloid'];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const Cloud_Mode = productCoreItem.Cloud_Mode;
  const loginState = useSelector((state) => state.login);
  const [licenseRequired, toggleLicenseRequirement] = useToggle(true);
  const amyloidLicenseInfo = useSelector((state) => state.license.amyloid);
  const [signinValues, setSigninValues] = useState({
    username: '',
    password: '',
  });

  useEffect(() => {
    const username = sessionStorage.getItem('username');
    if (CTN_Mode) {
      async function updateLicense() {
        // CNT_MODE일때는 amyloid license 정보만 가져온다.
        const licenseResult = await getLicenseInfo(['amyloid']);
        if (licenseResult.amyloid) {
          dispatch(
            updateLicenseInfo({
              productName: 'amyloid',
              ...licenseResult.amyloid,
            }),
          );
        }
      }

      if (licenseRequired) {
        toggleLicenseRequirement();
        updateLicense();
      }
      if (!(username === null)) history.push(`/amyloid/dashboard`);
    } else {
      if (!(username === null)) history.push(`/selectProduct`);
    }
  }, [CTN_Mode, dispatch, history, licenseRequired, toggleLicenseRequirement]);

  const processFunc = async () => {
    if (loginState.logged) {
      if (CTN_Mode) history.push(`/amyloid/dashboard`);
      else history.push(`/selectProduct`);
      console.log('login: Connected');
    } else {
      // alert('processFunc not logged')
      const resObj = await dispatch(
        startLoginThunk({
          username: signinValues.username,
          password: signinValues.password,
        }),
      );

      if (resObj.response) {
        // 통신 또는 로그인 계정인증 성공
        if (CTN_Mode) {
          // CTN모드
          history.push(`/amyloid/dashboard`);
        } else if (Cloud_Mode) {
          // 클라우드모드
          if (resObj.is_active_email) history.push(`/selectProduct`);
          else {
            alert(
              'Email authentiacation is required you need to sign in to your account.',
            );
            console.error(resObj.message);
          }
        } else {
          // 일반모드
          history.push(`/selectProduct`);
          // if (resObj.is_active_email) history.push(`/selectProduct`);
          // else {
          //   alert("it is connected the cloud server, but currently on-premise mode");
          // }
        }
      } else {
        // 통신 또는 로그인 계정인증 실패
        if (resObj.code === undefined)
          alert(
            'Failed to sign in due to connection error. Please check your connection to the server, and try again.',
          );
        else alert('Login failed. Please enter a valid username and password.');
      }
      console.log(`${resObj.message} (${resObj.code}) `);
    }
  };

  const propsSignIn = {
    processFunc,
    title: 'LOG IN',
    btnClassName: 'pass-btn',
  };

  return (
    <div className={styles['container']}>
      <div>
        <h1 className={`${styles['title']}`}>{'SIGN IN'}</h1>

        {
          <>
            <InputText
              title="USERNAME"
              type="text"
              name="username"
              placeholder="Enter Username"
              value={signinValues}
              setValue={setSigninValues}
              isSignin={true}
            />
            <InputText
              title="PASSWORD"
              type="password"
              name="password"
              placeholder="Enter Password"
              value={signinValues}
              setValue={setSigninValues}
              isSignin={true}
            />
          </>
        }

        <HomePassButton item={propsSignIn} />

        <div
          id="forgot_password"
          className={`${styles['forgot-password']}`}
          onClick={() => history.push('/forgot')}
        >
          {'Forgot password?'}
        </div>

        {CTN_Mode && (
          <div
            className={`${styles.license} ${
              amyloidLicenseInfo.valid ? styles.auth : styles.fails
            }`}
          >
            {amyloidLicenseInfo.valid
              ? 'License has authorized'
              : 'A product license is required to use this service'}
          </div>
        )}
        {/* <div className={`${styles[`license auth`]}`}>{'License has authorized ' + licenseInfo.amyloid.valid.toString()}</div> */}
      </div>
      {/* <div style={{fontSize:"0.6vw", opacity:"50%"}}>This product has not been approved by the MFDS and FDA and is not intended to diagnose, treat, cure, or prevent any disease.</div> */}
    </div>
  );
}
