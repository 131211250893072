import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import {
  getSizebyProduct,
  ratio2Width,
  getMNIImageID,
  getSliceImageID,
  getSliceIndexForFile,
} from '0_variables/utils';
import { VIEWER_TYPE } from '0_variables/btxConstants';
import { updateAllSliceIndices } from '1_reduxs/reducers/controllerReducer';
import { StackViewer } from '4_routers/3_View/components';
import ColorbarWrapper from '../ColorbarWrapper';
import styles from './ViewerGroup.module.scss';

import * as cornerstone from 'cornerstone-core';
import * as cornerstoneMath from 'cornerstone-math';
import * as cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export default function ViewerGroup(props) {
  const { fileID, productName, fileRFSubset } = props;

  const dispatch = useDispatch();
  const [viewerWidth, setViewerWidth] = useState(25); // 25 or 35

  // const pageName = location.pathname.split('/')[2];
  const pageName = 'view';

  const storedIndexes = getSliceIndexForFile(fileID);

  const initAxialIndex = storedIndexes.axial;
  const initCoronalIndex = storedIndexes.coronal;
  const initSagittalIndex = storedIndexes.sagittal;
  const initMipIndex = storedIndexes.mip;

  const getViewHeight = (width, Direction) => {
    const size = getSizebyProduct(Direction);
    const ratio = ratio2Width(size);
    return width * ratio;
  };

  const createStack = useCallback(
    (direction, imageCount, sliceImageOptions, currentImageIdIndex) => {
      const { productName, pageName, fileID } = sliceImageOptions;
      return [
        {
          imageIds: [...Array(imageCount).keys()].map((i) =>
            getMNIImageID(direction, i),
          ),
          currentImageIdIndex,
          stackType: 'MNI_STACK',
          isMNIStack: true,
        },
        {
          imageIds: [...Array(imageCount).keys()].map((i) =>
            getSliceImageID({
              productName,
              pageName,
              fileID,
              imageType: 'output',
              direction,
              sliceIndex: i,
            }),
          ),
          currentImageIdIndex,
          stackType: `SLICE_STACK_output`,
          isMNIStack: false,
        },
        {
          imageIds: [...Array(imageCount).keys()].map((i) =>
            getSliceImageID({
              productName,
              pageName,
              fileID,
              imageType: 'input',
              direction,
              sliceIndex: i,
            }),
          ),
          currentImageIdIndex,
          stackType: `SLICE_STACK_input`,
          isMNIStack: false,
        },
      ];
    },
    [],
  );

  const commonOptions = useMemo(
    () => ({
      productName: productName,
      pageName: pageName,
      fileID: fileID,
    }),
    [productName, pageName, fileID],
  );

  const CORONAL_LENGTH = 109 + 20 - 1;
  const coronalStacks = useMemo(
    () =>
      createStack('coronal', CORONAL_LENGTH, commonOptions, initCoronalIndex),
    [CORONAL_LENGTH, commonOptions, createStack, initCoronalIndex],
  );

  const SAGITTAL_LENGTH = 91 + 40;
  const sagittalStacks = useMemo(
    () =>
      createStack(
        'sagittal',
        SAGITTAL_LENGTH,
        commonOptions,
        initSagittalIndex,
      ),
    [SAGITTAL_LENGTH, commonOptions, createStack, initSagittalIndex],
  );

  const AXIAL_LENGTH = 91;
  const axialStacks = useMemo(
    () => createStack('axial', AXIAL_LENGTH, commonOptions, initAxialIndex),
    [AXIAL_LENGTH, commonOptions, createStack, initAxialIndex],
  );

  const mipStacks = [
    {
      imageIds: [...Array(45).keys()].map((v, i) =>
        getSliceImageID({
          ...commonOptions,
          imageType: 'output',
          direction: 'mip',
          sliceIndex: i,
        }),
      ),
      currentImageIdIndex: initMipIndex,
      stackType: `SLICE_STACK_output`,
      isMNIStack: false,
    },
    {
      imageIds: [...Array(45).keys()].map((v, i) =>
        getSliceImageID({
          ...commonOptions,
          imageType: 'input',
          direction: 'mip',
          sliceIndex: i,
        }),
      ),
      currentImageIdIndex: initMipIndex,
      stackType: `SLICE_STACK_input`,
      isMNIStack: false,
    },
  ];

  const wwwcsynchronizer = useMemo(
    () =>
      new cornerstoneTools.Synchronizer(
        'cornerstoneimagerendered',
        cornerstoneTools.wwwcSynchronizer,
      ),
    [],
  );

  const referenceLinesync = useMemo(
    () =>
      new cornerstoneTools.Synchronizer(
        'cornerstonenewimage',
        cornerstoneTools.updateImageSynchronizer,
      ),
    [],
  );

  const synchronizerProps = {
    wwwcsynchronizer,
    referenceLinesync,
  };

  useEffect(() => {
    const payload = {
      productName,
      fileID,
      coronalIndex: initCoronalIndex,
      sagittalIndex: initSagittalIndex,
      axialIndex: initAxialIndex,
    };

    dispatch(updateAllSliceIndices(payload));
  }, [
    dispatch,
    fileID,
    initAxialIndex,
    initCoronalIndex,
    initSagittalIndex,
    productName,
  ]);

  useEffect(() => {
    return () => {
      wwwcsynchronizer.destroy();
      referenceLinesync.destroy();
    };
  }, [referenceLinesync, wwwcsynchronizer]);

  useEffect(() => {
    const handleResize = debounce(() => {
      let updateViewWith = viewerWidth;
      const size = {
        width: window.innerWidth || document.body.clientWidth,
        height: window.innerHeight || document.body.clientHeight,
      };
      if (size.width > size.height) {
        updateViewWith = 25;
      } else {
        updateViewWith = 35;
      }

      if (viewerWidth !== updateViewWith) {
        setViewerWidth(updateViewWith);
      }
    }, 100);

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [viewerWidth]);

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
      }}
    >
      <div className={styles.imageWrapper}>
        <div
          className={styles.divUpper}
          style={{ height: `${getViewHeight(viewerWidth, 'coronal')}vw` }}
        >
          <div style={{ width: `${viewerWidth}vw` }}>
            <StackViewer
              selectedFileId={fileID}
              productName={productName}
              positionFn={{
                getX: (positionData) => positionData.sagittal,
                getY: (positionData) => 90 - positionData.axial,
                getZ: (positionData) => positionData.coronal,
              }}
              type={'slice'}
              direction={'coronal'}
              viewerType={VIEWER_TYPE.BASE}
              width={`102%`}
              height={`105%`}
              Stacks={coronalStacks}
              synchronizerProps={synchronizerProps}
              fileRFSubset={fileRFSubset}
            />
          </div>
          <div style={{ width: `${viewerWidth}vw` }}>
            <StackViewer
              selectedFileId={fileID}
              positionFn={{
                getX: (positionData) => positionData.coronal,
                getY: (positionData) => 90 - positionData.axial,
                getZ: (positionData) => positionData.sagittal,
              }}
              productName={productName}
              type={'slice'}
              direction={'sagittal'}
              viewerType={VIEWER_TYPE.BASE}
              width={`102%`}
              height={`102%`}
              Stacks={sagittalStacks}
              fileRFSubset={fileRFSubset}
              synchronizerProps={synchronizerProps}
            />
          </div>
        </div>
        <div
          className={styles.divLower}
          style={{ height: `${getViewHeight(viewerWidth, 'axial')}vw` }}
        >
          <div style={{ width: `${viewerWidth}vw` }}>
            <StackViewer
              selectedFileId={fileID}
              productName={productName}
              positionFn={{
                getX: (positionData) => positionData.sagittal,
                getY: (positionData) => 128 - positionData.coronal,
                getZ: (positionData) => positionData.axial,
              }}
              type={'slice'}
              direction={'axial'}
              viewerType={VIEWER_TYPE.BASE}
              width={`102%`}
              height={`103%`}
              Stacks={axialStacks}
              fileRFSubset={fileRFSubset}
              synchronizerProps={synchronizerProps}
            />
          </div>
          <div className={styles.divMip} style={{ width: `${viewerWidth}vw` }}>
            <StackViewer
              selectedFileId={fileID}
              productName={productName}
              positionFn={{
                getX: (positionData) => positionData.coronal,
                getY: (positionData) => 90 - positionData.axial,
                getZ: (positionData) => positionData.defaultMipIndex,
              }}
              type={'mip'}
              direction={'mip'}
              viewerType={VIEWER_TYPE.BASE}
              width={`140%`}
              height={`100%`}
              Stacks={mipStacks}
              fileRFSubset={fileRFSubset}
              synchronizerProps={synchronizerProps}
            />
          </div>
        </div>
      </div>
      <div>
        <ColorbarWrapper
          fileRFSubset={fileRFSubset}
          viewerType={VIEWER_TYPE.BASE}
          showHorizontal={false}
          isSlimMode={false}
        />
      </div>
    </div>
  );
}
