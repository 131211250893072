import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as actionSetting from '1_reduxs/actions/settingAction';
import styles from './MenuButton.module.scss';

export default function MenuButton({ item }) {
  const history = useHistory();
  const location = useLocation();
  const pageName = location.pathname.split('/')[2];
  const settingChanged = useSelector((state) => state.setting.changed);
  const dispatch = useDispatch();

  const isEnabled = item.enabled ? styles.enabled : '';
  const isActive = location.pathname === item.push ? styles.active : '';
  const btnClassNames = `${styles['sub-container']} ${isEnabled} ${isActive}`;

  return (
    <div
      role="button"
      className={`${btnClassNames}`}
      onClick={async () => {
        if (item.enabled) {
          if (pageName === 'setting' && item.name !== 'Log out') {
            if (
              settingChanged &&
              window.confirm('Do you really want to save changes?')
            ) {
              await dispatch(actionSetting.saveSettingServerThunk());
              alert('The settings have been reset successfully.');
            } else {
              // log out button인 경우 setting init 제외
              await dispatch(actionSetting.initSettingThunk());
            }
          }

          item.callBack();
          history.push(item.push);
        }
      }}
    >
      <div className={`${styles['menu-icon']}`}>
        {location.pathname === item.push ? (
          <img src={item.activeIcon} alt={`active icon`} />
        ) : (
          <img src={item.unactiveIcon} alt={`inactive icon`} />
        )}
      </div>
      <div className={`${styles['menu-name']}`}>{item.name}</div>
    </div>
  );
}
