import React from 'react';
import styles from './HomePassButton.module.scss';

export default function HomePassButton({ item }) {
  return (
    <div className={`${styles[`${item.btnClassName}`]}`}>
      <button
        onClick={item.processFunc}
        style={{
          background: item.propsStyle?.background
            ? item.propsStyle?.background
            : '',
        }}
      >
        {item.title}
      </button>
    </div>
  );
}
