/**
 * Worklist에서 generate a report 버튼을 누르면
 * GlobalLayout 컴포넌트에서는 ReportWrapper 컴포넌트를 렌더링하고
 * ReportWrapper 컴포넌트는 ReportPages 컴포넌트를 렌더링한다.
 */
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useInterval, useToggle } from 'react-use';
import { updateLayoutState } from '1_reduxs/reducers/layoutReducer';
import { openSpinner } from '1_reduxs/reducers/modalReducer';
import { selectFilesByIds } from '1_reduxs/reducers/filesReducer';
import { exportPDF } from '4_routers/6_Report/makePDF';
import { ReportPages } from '5_components';
import styles from './GlobalLayout.module.scss';

export default function ReportWrapper(props) {
  const { selectFileIds } = props;
  const dispatch = useDispatch();
  const { product: productName } = useParams();
  const imageLoadQueue = useSelector((state) => state.modal.imageLoadQueue);
  const [onExportPDF, toggleExportPDF] = useToggle(true);
  const setting = useSelector((state) => state.setting);
  const settingOfProduct = setting[productName];

  const [secondCount, setSecondCount] = useState(0);
  const selectedFileList = useSelector((state) =>
    selectFilesByIds(state, selectFileIds),
  );
  const completedFiles = selectedFileList.filter(
    (item) => item.Complete === true,
  );

  const selectedFileIDsForProduct = completedFiles.map(({ fileID }) => fileID);

  const toggleShowReport = useCallback(
    (updateValue) => {
      dispatch(
        updateLayoutState({ optionName: 'isReportShowForPrint', updateValue }),
      );
    },
    [dispatch],
  );

  useInterval(() => {
    const spinnerDispatch = ({ status, message, percent, target }) => {
      dispatch(
        openSpinner({
          status,
          percent,
          message,
          target,
        }),
      );
    };

    if (secondCount > 3 && imageLoadQueue.length === 0 && onExportPDF) {
      toggleExportPDF();
      exportPDF({
        spinnerDispatch,
        fileList: completedFiles,
        productName,
        settingOfProduct,
        isUploadToPacs: false,
        toggleShowReport, // close reportWrapper
        isReportWrapper: true,
      });
    }

    setSecondCount(secondCount + 1);
  }, 1000);

  return (
    <div className={styles.reportContainer} style={{ overflow: 'scroll' }}>
      <div className={styles.buttons} onClick={() => toggleShowReport(false)}>
        <span>X</span>
      </div>

      {selectedFileIDsForProduct.map((fileID, i) => (
        <div
          key={`report_container_${fileID}`}
          className={`a4-pages-container_${fileID}_worklist`}
        >
          <ReportPages key={fileID} fileID={fileID} isReportWrapper={true} />
        </div>
      ))}
    </div>
  );
}
