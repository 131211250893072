import { createSlice } from '@reduxjs/toolkit';
import { removesessionStorage } from '0_variables/utils';

const initialState = {
  username: 'Anonymous',
  token: '',
  refreshToken: '',
  expire: '',
  logged: false,
  hasNewJob: false, // token 갱신 작업이 진행 중일 경우 true
  finishedTime: '', // 최근 token 갱신 작업이 종료된 일시(ISO string)
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginState: (state, action) => {
      const { username, token, expire, refreshToken, logged } = action.payload;
      if (!logged) {
        removesessionStorage();
      }
      state.username = username;
      state.token = token;
      state.refreshToken = refreshToken;
      state.expire = expire;
      state.logged = logged;
    },
    addRefreshTokenJobInfo: (state, action) => {
        const { hasNewJob, finishedTime } = action.payload;
        state.hasNewJob = hasNewJob;
        state.finishedTime = finishedTime;
    },
  },
});

export const { setLoginState, addRefreshTokenJobInfo } = loginSlice.actions;
export default loginSlice.reducer;
