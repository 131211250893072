import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { PacsTable } from '5_components';
import styles from './UploadingStep2.module.scss';

export default function UploadingStep2({
  stepIndex,
  isActivate,
  uploadingStepProps,
}) {
  const { findResult: searchResults } = uploadingStepProps;
  const date0 = new Date();
  const currentDate =
    date0.getFullYear() +
    ('0' + (date0.getMonth() + 1)).slice(-2) +
    ('0' + date0.getDate()).slice(-2);
  const location = useLocation();
  const { product: productName } = useParams();
  const productCoreItem = coreItem[productName];
  const pageName = location.pathname.split('/')[2];
  const tableHead = productCoreItem[pageName].bottom.uploadingTableHead2;
  const [sortClick, setSortClick] = useState({});
  const [sortedList, setSortedList] = useState([]); // 정렬된 리스트
  const [searchForm, setSearchForm] = useState({
    PatientID: '', //사용할 문자열들을 저장하는 객체 형태로 관리!
    StudyDate: currentDate,
    StudyDescription: '',
  });

  useEffect(() => {
    const tempList = searchResults.sort((a, b) => {
      if (Object.keys(sortClick).length !== 0) {
        const targetKey = tableHead.find(
          (v) => v.title === Object.keys(sortClick)[0],
        ).fieldName;
        if (Object.values(sortClick)[0] === 'ascend')
          return a[targetKey] < b[targetKey] ? -1 : 1;
        else if (Object.values(sortClick)[0] === 'descend') {
          return b[targetKey] < a[targetKey] ? -1 : 1;
        } else return b.id - a.id;
      } else {
        return b.id - a.id;
      }
    });

    setSortedList([...tempList]);
  }, [searchResults, sortClick, tableHead]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchForm({
      ...searchForm,
      [name]: value,
    });
  };

  return (
    <>
      <div
        className={styles.boxContainer}
        ref={(el) => uploadingStepProps.handleRef(stepIndex, el)}
      >
        <span
          className={styles.backButton}
          onClick={() => uploadingStepProps.executeScroll(stepIndex - 1)}
        >
          {'\u1438 Back'}
        </span>

        <div className={styles.boxTitle}>
          <div>
            <label htmlFor="PatientID">
              Patient ID
              <input
                id="PatientID"
                value={searchForm.PatientID}
                name="PatientID"
                placeholder="0000-0000"
                onChange={handleChange}
                tabIndex={0}
              />
            </label>
          </div>
          <div>
            <label htmlFor="StudyDate">
              Study Date
              <input
                id="StudyDate"
                value={searchForm.StudyDate}
                name="StudyDate"
                placeholder="YYYYMMDD - YYYYMMDD"
                onChange={handleChange}
                tabIndex={1}
              />
            </label>
          </div>
          <div>
            <label htmlFor="StudyDescription">
              Study Description
              <input
                id="StudyDescription"
                value={searchForm.StudyDescription}
                name="StudyDescription"
                placeholder="Tracer Memo"
                onChange={handleChange}
                tabIndex={2}
              />
            </label>
          </div>
          <div
            className={styles.searchBtn}
            role="button"
            onClick={() => uploadingStepProps.findHandler(searchForm)}
            tabIndex={3}
            onKeyDown={(e) => {
              e.key === 'Enter' && uploadingStepProps.findHandler(searchForm);
            }}
          >
            Search
          </div>
        </div>

        <div className={styles.boxContent} id="pacs_table">
          {isActivate && (
            <PacsTable
              productName={productName}
              tableHead={tableHead}
              sortedList={sortedList}
              sortClick={sortClick}
              setSortClick={setSortClick}
              deleteHandler={(item, studyID, seriesID) => {
                uploadingStepProps._deleteFindHandler(item, studyID, seriesID);
              }}
              selectHandler={(item, studyID, seriesID) => {
                uploadingStepProps._selectFindHandler(item, studyID, seriesID);
              }}
              clickHandler={(v1) => {}}
              doubleClickHandler={(v1) => {}}
              foldable={true}
              selectedItem={null}
              updateSelectPatient={uploadingStepProps.updateSelectPatient}
              updateTracerHandeler={(v1) => {}}
            />
          )}
        </div>

        <div className={styles.buttonGroup}>
          <div
            className={styles.nextButton}
            role="button"
            onClick={() => {
              uploadingStepProps.getHandler();
              uploadingStepProps.executeScroll(stepIndex + 1);
            }}
          >
            {'Download \u1433'}
          </div>
        </div>
      </div>
    </>
  );
}
