import React from 'react';
import styles from './InputText.module.scss';

export default function InputText({
  title,
  name,
  placeholder,
  value,
  setValue,
  type,
  isSignin = false,
}) {
  const HTMLInputID = `${name}-input`;
  return (
    <div className={`${styles.inputTextContainer}`}>
      <label
        htmlFor={HTMLInputID}
        className={`${styles.inputTitle}`}
        style={isSignin ? { fontSize: '1vw' } : {}}
      >
        {title}
      </label>
      <input
        id={HTMLInputID}
        className={`${styles.inputContent}`}
        type={type}
        name={name}
        style={isSignin ? { fontSize: ' 0.9vw', lineHeight: '3vw' } : {}}
        value={value?.[name]}
        autoComplete="off"
        onChange={(e) => {
          setValue({ ...value, [e.target.name]: e.target.value });
        }}
        placeholder={placeholder}
      />
    </div>
  );
}
