import { openSpinner } from '1_reduxs/reducers/modalReducer';
import {
  addAndRemoveWorklist,
  fetchFilesStore,
  removeFiles,
  selectFilesByIds,
  updateFile,
} from '1_reduxs/reducers/filesReducer';
import * as filesApi from '2_services/filesApi';
import { runFile } from '2_services/uploadApi';
import { refreshLicenseInfo } from './loginAction';

export function checkFileThunk({ check_list }) {
  return async (dispatch, getState) => {
    try {
      const { data: check_array } = await filesApi.check_file({ check_list });
      const completedFiles = check_array.filter((el) => el.Complete);
      const updateFileIds = completedFiles.map((el) => el.fileID);

      if (completedFiles.length > 0) {
        const state = getState();

        const prevItems = selectFilesByIds(state, updateFileIds);

        prevItems.forEach((item) => {
          const updatedItem = completedFiles.find(
            ({ fileID }) => fileID === item.fileID,
          );
          if (updatedItem === undefined) return;

          dispatch(updateFile({ ...updatedItem }));
        });
      }
    } catch (err) {
      // alert("filesAction/fetch_api error");
      // dispatch(openSpinner({status:false, percent:0}));
    } finally {
      // dispatch(openSpinner({status:false, percent:0}));
      // dispatch(openSpinner({status:false, percent:0, message:""}));
    }
  };
}

export function getFilesThunk() {
  return async (dispatch) => {
    try {
      dispatch(
        openSpinner({
          status: true,
          percent: 0,
          message: 'Loading...',
        }),
      );
      const res = await filesApi.fetchFiles();
      dispatch(fetchFilesStore({ items: res.data }));
    } catch (err) {
      // alert("filesAction/fetch_api error");
      // dispatch(openSpinner({status:false, percent:0}));
    } finally {
      dispatch(openSpinner({ status: false, percent: 0 }));
      // dispatch(openSpinner({status:false, percent:0, message:""}));
    }
  };
}

export function updateWorklistThunk(status, selectedFiles) {
  return async (dispatch, getState) => {
    const token = sessionStorage.getItem('token');
    try {
      await filesApi.update_worklist({
        token: token,
        obj: selectedFiles,
        addToWorklist: status,
      });
      const targetFileIDs = selectedFiles.map((el) => el.fileID);
      dispatch(addAndRemoveWorklist({ status, targetFileIDs }));
    } catch (err) {
      alert('filesAction/update_worklist error');
    }
  };
}

export function deleteApiThunk(removeItems, productName) {
  return async (dispatch) => {
    try {
      await filesApi.deleteSelection({
        obj: removeItems,
        product: productName,
      });

      // remove file from store
      dispatch(removeFiles(removeItems.map((el) => el.fileID)));
      // dispatch(fetchFilesStore({ items: res.data }));
    } catch (err) {
      alert(
        'Connection lost. Please check your connection to the server, and try logging in again.',
      );
    }
  };
}

export function runFileThunk(data, productName) {
  return async (dispatch) => {
    try {
      const result = await runFile(data);

      dispatch(refreshLicenseInfo(productName));

      return result;
    } catch (err) {
      alert('filesAction/runFile error');
    }
  };
}
