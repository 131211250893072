import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useToggle } from 'react-use';
import { coreItem, SPECT_status, initProductInfo } from '0_variables/coreItem';
import { closeSingleFile, getLicenseInfo } from '0_variables/utils';
import { updateLicenseAll } from '1_reduxs/reducers/licenseReducer';
import { setSelectedFileID } from '1_reduxs/reducers/controllerReducer';
import { DropDownDiv } from '5_components';
import { info as MEDIA_INFO } from '6_media';
import styles from './Header.module.scss';

const TabContainer = ({
  item,
  selectedFileID,
  tracerColor,
  currentPage,
  isTabEnabled,
  moveTabCallback,
  closeTabCallback,
}) => {
  const { fileID, Tracer, PatientName } = item;

  return (
    <div
      role="button"
      className={selectedFileID === fileID ? styles.activeTab : ''}
      style={{
        opacity: isTabEnabled ? 1 : 0.4,
      }}
      onClick={() => {
        moveTabCallback(currentPage, item);
      }}
    >
      <span
        className={styles.fileInfo}
        style={{
          borderColor: tracerColor[Tracer],
        }}
      >
        <span>{PatientName}</span>
      </span>
      <span
        role="button"
        className={styles.closeIcon}
        onClick={(event) => {
          closeTabCallback(event, item);
        }}
      >
        <AiOutlineClose />
      </span>
    </div>
  );
};

function ProductDropdown() {
  const history = useHistory();
  const { product: productName } = useParams();
  const licenseState = useSelector((state) => state.license);
  const { changed: settingIsChanged } = useSelector((state) => state.setting);
  const [productSelected, setProductSelected] = useState(
    coreItem[productName].productName,
  );

  const productDropdown = {
    options: Object.keys(licenseState)
      .filter((productName) => {
        const licenseInfoByProduct = licenseState[productName];
        const isPerfusion = productName === 'perfusion';
        const isVisiable = SPECT_status || !isPerfusion;

        return licenseInfoByProduct.valid && isVisiable;
      })
      .map((productName) => coreItem[productName].productName), // 제품이름 정의된 case로 보여주기
    selectItem: productSelected,
    setItem: (val) => {
      if (settingIsChanged) {
        alert('Please save the changes before leaving the page.');
      } else {
        const productName = val.toLowerCase();
        history.push(`/${productName}/upload`);
        setProductSelected(val);
      }
    },
  };

  return (
    <DropDownDiv
      style={{ background: '#202223' }}
      item={productDropdown}
      styleName={'headerStyle'}
    />
  );
}

export default function Header() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { product: productName } = useParams();
  const currentPage = location.pathname.split('/')[2].toLowerCase();
  const targetFileId = location.pathname.split('/')[3];
  const selectedFileID = useSelector((state) => state.control.selectedFileID);
  const { openedFiles } = useSelector((state) => state.control[productName]);
  const openedFilesArray = Object.values(openedFiles);
  const licenseState = useSelector((state) => state.license);
  const { CTN_Mode, Cloud_Mode, tracers } = coreItem[productName];
  // const tracerList = tracers.map((v) => v.shortname);
  const tracerColor = tracers.reduce((obj, item) => {
    obj[item.shortname] = item.color;
    return obj;
  }, {});

  const [prevPage, setPrevPage] = useState('analysis');
  const [licenseRequired, toggleLicenseRequirement] = useToggle(true);

  const isTabEnabled = ['view', 'analysis', 'report'].includes(currentPage);
  const isShowBackBtn = ['report'].includes(currentPage);

  const updateLicenseInfoALL = async (dispatch, productShortNames) => {
    const licenseResult = await getLicenseInfo(productShortNames);
    dispatch(updateLicenseAll(licenseResult));
  };

  const moveTabCallback = useCallback(
    (currentPage, currentFile) => {
      const selectedFileID = currentFile.fileID;

      if (isTabEnabled) {
        dispatch(setSelectedFileID({ selectedFileID }));
        history.push(`/${productName}/${currentPage}/${selectedFileID}`);
      }
    },
    [dispatch, history, isTabEnabled, productName],
  );

  const closeTabCallback = useCallback(
    (event, currentFile) => {
      event.stopPropagation();
      closeSingleFile(dispatch, currentFile, productName);
    },
    [dispatch, productName],
  );

  useEffect(() => {
    if (licenseRequired && licenseState[productName].isInitailized === false) {
      const productShortNames = initProductInfo.map((v) => v.productShortName);
      toggleLicenseRequirement();
      updateLicenseInfoALL(dispatch, productShortNames);
    }
  }, [
    licenseRequired,
    licenseState,
    productName,
    dispatch,
    toggleLicenseRequirement,
  ]);

  useEffect(() => {
    if (['view', 'analysis'].includes(currentPage)) {
      setPrevPage(currentPage);
    }
  }, [currentPage]);

  return (
    <div className={styles.container}>
      <div className={styles.backBtnBox}>
        {isShowBackBtn && (
          <span
            onClick={() =>
              history.push(`/${productName}/${prevPage}/${targetFileId}`)
            }
            style={{ paddingBottom: '0.4vw' }}
          >
            <IoMdArrowRoundBack size={'1.5vw'} />
            <div style={{ marginLeft: '0.5vw' }}>{' back to ' + prevPage}</div>
          </span>
        )}
      </div>

      <div className={styles.tabgroup}>
        {openedFilesArray
          // .filter(({ Tracer }) => tracerList.includes(Tracer))
          .map((item) => (
            <TabContainer
              key={item.fileID}
              item={item}
              selectedFileID={selectedFileID}
              tracerColor={tracerColor}
              currentPage={currentPage}
              isTabEnabled={isTabEnabled}
              moveTabCallback={moveTabCallback}
              closeTabCallback={closeTabCallback}
            />
          ))}
      </div>
      <div className={styles.infogroup}>
        {!CTN_Mode && (
          <div className={styles.product}>
            {Cloud_Mode && (
              <div>
                <img
                  src={MEDIA_INFO}
                  data-tip
                  data-for="tooltip"
                  alt="infomation"
                />
              </div>
            )}
            <ProductDropdown />
          </div>
        )}
      </div>
    </div>
  );
}
