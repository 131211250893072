import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { logo as MEDIA_logo } from '6_media';
import styles from './ReportLayout.module.scss';

export default function ReportLayout(props) {
  const { children, selectedFile, pageNumber, PatientID } = props;
  const { product: productName } = useParams();
  const productCoreItem = coreItem[productName];
  const today = new Date();
  const date = today.toJSON().slice(0, 10);
  const nDate =
    date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);
  const setting = useSelector((state) => state.setting);
  const tracerName = selectedFile['Tracer'];

  return (
    <div className={styles.ReportLayoutContainer}>
      {/* TODO: 환자정보의 내용이 길어서 줄바꿈이 생기면 페이지가 밑으로 늘어나는 상황 발견: 페이지 높이 고정 필요할듯... */}
      <div className={styles.top}>
        {productName === 'perfusion' && (
          <span className={styles.researchInfo}>
            {
              'This product has not been approved by the MFDS and FDA and is not intended to diagnose, treat, cure, or prevent any disease.'
            }
          </span>
        )}
        <span className={styles.date}>
          {'Last Printed'}
          <br />
          {nDate}
        </span>
      </div>

      <div className={styles.head}>
        <div className={styles.title}>
          <span>
            {'BTXBrain-'}
            {productCoreItem.productShortName}
          </span>
          {productName !== 'perfusion' && (
            <span>{`AI-based Automatic ${productCoreItem.productFullName} PET Quantification Report`}</span>
          )}
          {productName === 'perfusion' && (
            <span>{`AI-based Automatic ${productCoreItem.productFullName} SPECT Quantification Report`}</span>
          )}
        </div>
      </div>

      <div
        className={
          productName !== 'perfusion' ? styles.body : styles.body_spect
        }
      >
        {children}
        <div className={styles.overlay}></div>
      </div>

      <div className={styles.foot}>
        <span>{`Quantification analysis details`}</span>
        <span>{`Atlas used: ${setting[productName].defaultAtlas} / Reference region: ${setting[productName].defaultRef[tracerName]}`}</span>
      </div>

      <div className={styles.bottom}>
        <span>
          <img src={MEDIA_logo} alt="logo" />
        </span>
        <div>
          <span>{`PatientID : ${PatientID}`}</span>
          <span>{`page ${pageNumber}`}</span>
        </div>
      </div>
    </div>
  );
}
