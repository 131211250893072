import React from 'react';
import { sandsClock as MEDIA_sandsClock } from '6_media';
import styles from './ExpiredModal.module.scss';

export default function ExpiredModal(props) {
  return (
    <div
      className={styles.container}
      onClick={() => {
        props.closeModal();
      }}
    >
      <div className={styles.licenseBox}>
        <div>
          <img src={MEDIA_sandsClock} alt="check info" />
        </div>
        <div>Your license has expired.</div>
        <div>Remained count : {props.remainCounts}</div>
        <div>Expired date : {props.endDate}</div>
        {/* <div>
          Your email address : {'test @ test.net'}
        </div> */}
        <div>Please contact support@brtnx.com</div>
      </div>
    </div>
  );
}
