import React from 'react';
import { coreItem } from '0_variables/coreItem';
import {
  Check as MEDIA_Check,
  unCheck as MEDIA_unCheck,
  processing as MEDIA_processing,
} from '6_media';
import styles from './FileTable.module.scss';

const FileTableRow = (props) => {
  const {
    productName,
    identifier,
    isTFP,
    fileData,
    slidingTargetID,
    setSlidingTargetID,
    isSelected = false,
  } = props;

  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  // const isSelected = fileData['Select'];

  return (
    <>
      <td className={styles.Select}>
        <img
          src={isSelected ? MEDIA_Check : MEDIA_unCheck}
          alt={isSelected ? 'Selected item' : 'Unselected item'}
          role="checkbox"
          aria-checked={isSelected}
        />
      </td>
      <td className={styles.Tracer}>
        {/* Tau, FDG, Perfusion */}
        {isTFP && fileData.Complete && (
          <div
            className={styles.TracerMarker}
            style={{
              borderColor: fileData.TracerColor,
            }}
          >
            {fileData.tracerName}
          </div>
        )}
        {isTFP && fileData.Complete === false && (
          <div
            className={styles.TracerMarker}
            style={{
              borderColor: fileData.TracerColor,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span id="processing">processing</span>
            <img
              className={styles.processingIcon}
              src={MEDIA_processing}
              alt="is processing"
            />
          </div>
        )}
        {/* Amyloid, DAT */}
        {isTFP === false && (
          <div
            className={styles.TracerMarker}
            style={{
              borderColor: fileData.TracerColor,
            }}
          >
            {fileData.Tracer}
          </div>
        )}
      </td>
      {/* worklist에서 hidden CTN_MODE 에서 hidden */}
      {CTN_Mode === false &&
        productName === 'amyloid' &&
        identifier === 'upload' && (
          <td className={styles.Centiloid}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {fileData.Complete ? (
                <>
                  <div
                    style={{
                      width: '40%',
                      textAlign: 'center',
                    }}
                  >
                    {fileData.Composite_C?.toFixed(1)}
                  </div>
                  <div className={styles.CentiloidBar}>
                    <div
                      style={{
                        width: `${Math.min(
                          100,
                          Math.max(0, fileData.Composite_C),
                        )}%`,
                      }}
                    ></div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span id="processing">processing</span>
                  <img
                    className={styles.processingIcon}
                    src={MEDIA_processing}
                    alt="is processing"
                  />
                </div>
              )}
            </div>
          </td>
        )}
      {/* worklist에서 hidden */}
      {productName === 'dat' && identifier === 'upload' && (
        <td className={styles.SBR}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {fileData.Complete ? (
              <>{fileData.DAT_Dorsal_striatum?.toFixed(1)}</>
            ) : (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span id="processing">processing</span>
                <img
                  className={styles.processingIcon}
                  src={MEDIA_processing}
                  alt="is processing"
                />
              </div>
            )}
          </div>
        </td>
      )}

      <td className={styles.Patient_name}>
        <div
          className="tooltip-file-table"
          data-tooltip-content={fileData['OriginalFileName']}
          data-tooltip-place="top"
        >
          <div
            className={
              slidingTargetID === fileData.id ? `${styles.slideContainer}` : ''
            }
          >
            <span
              onMouseEnter={(event) => {
                const currentWidth = event.target.offsetWidth;
                const parentWidth = event.target.parentElement.offsetWidth;
                if (currentWidth > parentWidth) {
                  setSlidingTargetID(fileData.id);
                }
              }}
              onMouseLeave={() => setSlidingTargetID('')}
            >
              {fileData.PatientName}
            </span>
          </div>
        </div>
      </td>
      <td className={styles.Patient_ID}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {CTN_Mode ? (
            fileData.Complete ? (
              <>{fileData.PatientID}</>
            ) : (
              <>
                <span id="processing">processing</span>
                <img
                  className={styles.processingIcon}
                  src={MEDIA_processing}
                  alt=""
                />
              </>
            )
          ) : (
            <>{fileData.PatientID}</>
          )}
        </div>
      </td>
      <td className={styles.DOB}>
        <div>{fileData.Age.toString()}</div>
      </td>
      <td className={styles.Sex}>
        <div>{fileData.Sex}</div>
      </td>
      {identifier === 'upload' && (
        <td className={styles.Scan_date}>
          <div>{fileData.AcquisitionDateTime}</div>
        </td>
      )}
      <td className={styles.Uploaded_date}>
        <div>{fileData.Update}</div>
      </td>
    </>
  );
};

export default FileTableRow;
