import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import chroma from 'chroma-js';
import { gsap } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineDragIndicator, MdOutlineRefresh } from 'react-icons/md';
import { PiPlugsConnectedLight, PiPlugsLight } from 'react-icons/pi';
import { IoMdArrowDropleft, IoMdArrowDropdown } from 'react-icons/io';
import { throttle } from 'lodash';
import {
  DEFAULT_WINDOW_MAX,
  DEFAULT_WINDOW_MIN,
} from '0_variables/btxConstants';
import { calculatePixelInfo, clamp } from '0_variables/utils';
import { coreItem } from '0_variables/coreItem';
import {
  updateViewOption,
  updateWindowMinMax,
} from '1_reduxs/reducers/viewOptionReducer';
import styles from './ColorbarWrapper.module.scss';

import * as cornerstone from 'cornerstone-core';

const ColorbarLabel = ({ position, value, horizontal }) => (
  <div
    className={`${styles.colorbarLabel} ${horizontal && styles.horizontal}`}
    style={{ [horizontal ? 'left' : 'top']: `${position}%` }}
  >
    {horizontal ? (
      <IoMdArrowDropdown size={'1.2vw'} />
    ) : (
      <IoMdArrowDropleft size={'1.2vw'} />
    )}
    <span>{value}</span>
  </div>
);

const ColorbarArrow = ({
  positionName, // head, tail
  pressedCallback,
  active,
  horizontal,
}) => (
  <div
    className={`${styles.colorbarArrow} ${horizontal && styles.horizontal} ${
      styles[positionName]
    }`}
    style={{ color: active ? styles.activeColor : 'red' }}
    onMouseDown={(e) => {
      pressedCallback(e, positionName);
    }}
  >
    <MdOutlineDragIndicator
      size={'1vw'}
      style={{ transform: horizontal ? '' : 'rotate(90deg)' }}
    />
  </div>
);

const useColorbarLogic = (viewerType, showHorizontal) => {
  const dispatch = useDispatch();
  const commonViewOption = useSelector((state) => state.viewOption);
  const viewOptionByType = useSelector((state) => state.viewOption[viewerType]);
  const [colorbarState, setColorbarState] = useState({
    startPos: 0,
    currentWindowCenter: 16384,
    pressedPosition: 'none',
  });

  const { showInverted, showNormalized, syncWindowMinMax } = commonViewOption;
  const {
    colorMap: originColorMapName,
    window_min_out,
    window_max_out,
    window_min_in,
    window_max_in,
  } = viewOptionByType;

  const windowMin = showNormalized ? window_min_out : window_min_in;
  const windowMax = showNormalized ? window_max_out : window_max_in;

  const windowCenter = (windowMax + windowMin) / 2;
  const windowWidth = windowMax - windowMin;
  let centerPercent = (windowCenter / 32767) * 100;
  const widthPercent = (windowWidth / 32767) * 100;

  let bgColorDividePosition = centerPercent;

  if (showHorizontal) {
    centerPercent = 100 - centerPercent;
    bgColorDividePosition = 100 - centerPercent;
  }

  const setWindowMinMax = (WMin, WMax) => {
    dispatch(
      updateWindowMinMax({
        viewerType,
        windowMin: WMin,
        windowMax: WMax,
      }),
    );
  };

  const toggleWindowMinMaxSync = (updateValue) => {
    dispatch(
      updateViewOption({
        optionName: 'syncWindowMinMax',
        updateValue,
      }),
    );
  };

  const colorMapName = originColorMapName.replace('custom_', '');
  const cornerstonColormap = cornerstone.colors.getColormap(colorMapName);

  const cmapCode = {
    name: colorMapName,
    from: 1,
    to: 255,
    code: showInverted
      ? [...Array(256).keys()]
          .reverse()
          .map((i) =>
            chroma(
              cornerstonColormap
                .getColor(i)
                .map((el, idx) => (idx !== 3 ? el : el / 255)),
            ).name(),
          )
      : [...Array(256).keys()].map((i) =>
          chroma(
            cornerstonColormap
              .getColor(i)
              .map((el, idx) => (idx !== 3 ? el : el / 255)),
          ).name(),
        ),
  };

  const initColorbarPressed = (e) => {
    e.stopPropagation();
    setColorbarState({
      startPos: showHorizontal ? e.clientX : e.clientY,
      currentWindowCenter: windowCenter,
      pressedPosition: 'none',
    });
  };

  const beginColorbarPressed = (e, position) => {
    e.stopPropagation();
    setColorbarState({
      startPos: showHorizontal ? e.clientX : e.clientY,
      currentWindowCenter: windowCenter,
      pressedPosition: position,
    });
  };

  const handleColorBar = useCallback(
    (e, bodyNode, colorbarState, showHorizontal, windowMax, windowMin) => {
      e.stopPropagation();
      const { startPos, currentWindowCenter, pressedPosition } = colorbarState;

      const dispatchWindowMinMax = (WMin, WMax) => {
        dispatch(
          updateWindowMinMax({
            viewerType,
            windowMin: WMin,
            windowMax: WMax,
          }),
        );
      };

      const calculatePositionPercent = (clientRect, position) => {
        const dimension = showHorizontal ? clientRect.width : clientRect.height;
        const offset = showHorizontal ? clientRect.left : clientRect.top;
        const rawPercent = showHorizontal
          ? (position - offset) / dimension
          : 1 - (position - offset) / dimension; // colorbar is reversed

        return clamp(rawPercent, 0, 1);
      };

      if (pressedPosition !== 'none') {
        const container = bodyNode.parentElement;
        const containerBounds = container.getBoundingClientRect();

        const updatePercent = calculatePositionPercent(
          containerBounds,
          showHorizontal ? e.clientX : e.clientY,
        );

        if (pressedPosition === 'center') {
          const startPercent = calculatePositionPercent(
            containerBounds,
            startPos,
          );
          const currentCenterPercent = currentWindowCenter / 32767;
          const offsetFromCenter = startPercent - currentCenterPercent;
          const updatedWC = (updatePercent - offsetFromCenter) * 32767;
          const windowWidth = windowMax - windowMin;
          const currentWidth = clamp(windowWidth, 0, 32767);
          const updateWMax = clamp(updatedWC + currentWidth / 2, 2000, 32767);
          const updateWMin = clamp(
            updatedWC - currentWidth / 2,
            0,
            32767 - 2000,
          );
          dispatchWindowMinMax(updateWMin, updateWMax);
        } else if (pressedPosition === 'head') {
          const updateWMax = clamp(updatePercent * 32767, 0, 32767);
          dispatchWindowMinMax(windowMin, updateWMax);
        } else if (pressedPosition === 'tail') {
          const updateWMin = clamp(updatePercent * 32767, 0, 32767);
          dispatchWindowMinMax(updateWMin, windowMax);
        }
      }
    },
    [dispatch, viewerType],
  );

  const throttledHandleColorBar = useMemo(() => {
    return throttle(handleColorBar, 100);
  }, [handleColorBar]);

  return {
    windowMin,
    windowMax,
    windowCenter,
    // windowWidth,
    centerPercent,
    widthPercent,
    cmapCode,
    syncWindowMinMax,
    throttledHandleColorBar,
    initColorbarPressed,
    beginColorbarPressed,
    setWindowMinMax,
    toggleWindowMinMaxSync,
    colorbarState,
    bgColorDividePosition,
  };
};

function SlimColorbar({ viewerType, showHorizontal, pixelInfo }) {
  const {
    windowMin,
    windowMax,
    centerPercent,
    widthPercent,
    cmapCode,
    syncWindowMinMax,
    throttledHandleColorBar,
    initColorbarPressed,
    beginColorbarPressed,
    setWindowMinMax,
    toggleWindowMinMaxSync,
    colorbarState,
    bgColorDividePosition,
  } = useColorbarLogic(viewerType, showHorizontal);

  const bodyRef = useRef(null);
  const windowMaxRatio = windowMax / 32767;
  const windowMinRatio = windowMin / 32767;

  const refreshIconRotate = ({ currentTarget }) => {
    gsap.to(currentTarget, { rotation: '+=360' });
  };

  return (
    <div
      className={`${styles.colorbarContainer} ${styles.isSlimMode} ${
        showHorizontal && styles.horizontal
      } `}
    >
      <span style={{ width: '3vw', textAlign: 'center' }}>
        {(showHorizontal
          ? windowMinRatio * pixelInfo.width
          : windowMaxRatio * pixelInfo.width
        ).toFixed(2)}
      </span>
      <div
        className={`${styles.labelColorbarBox} ${
          showHorizontal && styles.horizontal
        }`}
      >
        <div
          className={styles.colorbarDragArea}
          onMouseMove={(e) => {
            throttledHandleColorBar(
              e,
              bodyRef.current,
              colorbarState,
              showHorizontal,
              windowMax,
              windowMin,
            );
          }}
          onMouseUp={initColorbarPressed}
          onMouseLeave={initColorbarPressed}
          style={{
            background: `linear-gradient(to ${
              showHorizontal ? 'right' : 'top'
            }, ${cmapCode.code[cmapCode.from]} ${bgColorDividePosition}%, ${
              cmapCode.code[cmapCode.to]
            } ${bgColorDividePosition}%)`,
          }}
        >
          <div
            ref={bodyRef}
            className={`${styles.colorbarBody} ${
              showHorizontal && styles.horizontal
            }`}
            style={{
              [showHorizontal ? 'left' : 'top']: `${100 - centerPercent}%`,
              [showHorizontal ? 'width' : 'height']: `${widthPercent}%`,
              background: `linear-gradient(to ${
                showHorizontal ? 'right' : 'top'
              }, ${cmapCode.code.slice(cmapCode.from, cmapCode.to).join(',')})`,
            }}
            onMouseDown={(e) => {
              beginColorbarPressed(e, 'center');
            }}
          >
            <ColorbarArrow
              positionName="head"
              pressedCallback={beginColorbarPressed}
              active={
                colorbarState.pressedPosition === 'center' ||
                colorbarState.pressedPosition === 'head'
              }
              horizontal={showHorizontal}
            />
            <ColorbarArrow
              positionName="tail"
              pressedCallback={beginColorbarPressed}
              active={
                colorbarState.pressedPosition === 'center' ||
                colorbarState.pressedPosition === 'tail'
              }
              horizontal={showHorizontal}
            />
          </div>
        </div>
      </div>
      <span
        style={{
          width: '3vw',
          textAlign: 'center',
        }}
      >
        {(showHorizontal
          ? windowMaxRatio * pixelInfo.width
          : windowMinRatio * pixelInfo.width
        ).toFixed(2)}
      </span>
      <span
        className={styles.refreshButton}
        onClick={() => {
          setWindowMinMax(DEFAULT_WINDOW_MIN, DEFAULT_WINDOW_MAX);
        }}
      >
        <MdOutlineRefresh
          color={'white'}
          size={'1vw'}
          className="reactIcon"
          onClick={refreshIconRotate}
        />
      </span>
      <span
        className={styles.refreshButton}
        onClick={() => {
          toggleWindowMinMaxSync(!syncWindowMinMax);
        }}
      >
        {syncWindowMinMax && (
          <PiPlugsConnectedLight
            color={'white'}
            size={'1vw'}
            className="reactIcon"
            onClick={refreshIconRotate}
          />
        )}
        {syncWindowMinMax === false && (
          <PiPlugsLight
            color={'white'}
            size={'1vw'}
            className="reactIcon"
            onClick={refreshIconRotate}
          />
        )}
      </span>
    </div>
  );
}

function RegularColorbar({ viewerType, showHorizontal, pixelInfo }) {
  const { product: productName } = useParams();
  const bodyRef = useRef(null);
  const {
    windowMin,
    windowMax,
    windowCenter,
    centerPercent,
    widthPercent,
    cmapCode,
    throttledHandleColorBar,
    initColorbarPressed,
    beginColorbarPressed,
    colorbarState,
    bgColorDividePosition,
  } = useColorbarLogic(viewerType, showHorizontal);

  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;

  const windowMaxRatio = windowMax / 32767;
  const windowCenterRatio = windowCenter / 32767;
  const windowMinRatio = windowMin / 32767;

  let headArrowPosition = clamp((1 - windowMaxRatio) * 100, 0, 100);
  let centerArrowPosition = clamp((1 - windowCenterRatio) * 100, 0, 100);
  let tailArrowPosition = clamp((1 - windowMinRatio) * 100, 0, 100);

  if (showHorizontal) {
    headArrowPosition = clamp(windowMaxRatio * 100, 0, 100);
    centerArrowPosition = clamp(windowCenterRatio * 100, 0, 100);
    tailArrowPosition = clamp(windowMinRatio * 100, 0, 100);
  }

  return (
    <div
      className={`${styles.colorbarContainer} ${
        showHorizontal && styles.horizontal
      } `}
    >
      <div
        className={`${styles.labelColorbarBox} ${
          showHorizontal && styles.horizontal
        }`}
      >
        <div
          className={`${styles.lableArea} ${
            showHorizontal && styles.horizontal
          }`}
        >
          <ColorbarLabel
            position={headArrowPosition}
            value={
              CTN_Mode
                ? Math.floor(windowMaxRatio * pixelInfo.width)
                : (windowMaxRatio * pixelInfo.width).toFixed(2)
            }
            horizontal={showHorizontal}
          />
          <ColorbarLabel
            position={centerArrowPosition}
            value={
              CTN_Mode
                ? Math.floor(windowCenterRatio * pixelInfo.width)
                : (windowCenterRatio * pixelInfo.width).toFixed(2)
            }
            horizontal={showHorizontal}
          />
          <ColorbarLabel
            position={tailArrowPosition}
            value={
              CTN_Mode
                ? Math.floor(windowMinRatio * pixelInfo.width)
                : (windowMinRatio * pixelInfo.width).toFixed(2)
            }
            horizontal={showHorizontal}
          />
        </div>

        <div
          className={styles.colorbarDragArea}
          onMouseMove={(e) => {
            throttledHandleColorBar(
              e,
              bodyRef.current,
              colorbarState,
              showHorizontal,
              windowMax,
              windowMin,
            );
          }}
          onMouseUp={initColorbarPressed}
          onMouseLeave={initColorbarPressed}
          style={{
            background: `linear-gradient(to ${
              showHorizontal ? 'right' : 'top'
            }, ${cmapCode.code[cmapCode.from]} ${bgColorDividePosition}%, ${
              cmapCode.code[cmapCode.to]
            } ${bgColorDividePosition}%)`,
          }}
        >
          <div
            ref={bodyRef}
            className={`${styles.colorbarBody} ${
              showHorizontal && styles.horizontal
            }`}
            style={{
              [showHorizontal ? 'left' : 'top']: `${100 - centerPercent}%`,
              [showHorizontal ? 'width' : 'height']: `${widthPercent}%`,
              background: `linear-gradient(to ${
                showHorizontal ? 'right' : 'top'
              }, ${cmapCode.code.slice(cmapCode.from, cmapCode.to).join(',')})`,
            }}
            onMouseDown={(e) => {
              beginColorbarPressed(e, 'center');
            }}
          >
            <ColorbarArrow
              positionName="head"
              pressedCallback={beginColorbarPressed}
              active={
                colorbarState.pressedPosition === 'center' ||
                colorbarState.pressedPosition === 'head'
              }
              horizontal={showHorizontal}
            />
            <ColorbarArrow
              positionName="tail"
              pressedCallback={beginColorbarPressed}
              active={
                colorbarState.pressedPosition === 'center' ||
                colorbarState.pressedPosition === 'tail'
              }
              horizontal={showHorizontal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function ColorbarWrapper(props) {
  const {
    fileRFSubset,
    viewerType, // VIEWER_TYPE
    showHorizontal,
    isSlimMode = false,
  } = props;

  const { product: productName } = useParams();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const tracerName = fileRFSubset['Tracer'];
  const settingOfProduct = useSelector((state) => state.setting[productName]);
  const showNormalized = useSelector(
    (state) => state.viewOption.showNormalized,
  );
  const refName = settingOfProduct.defaultRef[tracerName];

  const pixelInfo = calculatePixelInfo({
    selectedFile: fileRFSubset,
    productName,
    refName,
    CTN_Mode,
    showNormalized,
  });

  return isSlimMode ? (
    <SlimColorbar
      fileRFSubset={fileRFSubset}
      viewerType={viewerType}
      showHorizontal={showHorizontal}
      pixelInfo={pixelInfo}
    />
  ) : (
    <RegularColorbar
      fileRFSubset={fileRFSubset}
      viewerType={viewerType}
      showHorizontal={showHorizontal}
      pixelInfo={pixelInfo}
    />
  );
}

export default ColorbarWrapper;
