import { configureStore } from '@reduxjs/toolkit';
import allReducers from '1_reduxs/reducers';

const createStore = (preloadedState) => {
  return configureStore({
    reducer: allReducers,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // state 가 너무 커서 warning 발생하는것으로 보임, warning 끔
        // ImmutableStateInvariantMiddleware took 45ms, which is more than the warning threshold of 32ms
        immutableCheck: false,
        // SerializableStateInvariantMiddleware took 62ms, which is more than the warning threshold of 32ms.
        serializableCheck: false,
      }),
    devTools: process.env.NODE_ENV !== 'production',
  });
};

const store = createStore({});

export default createStore;
export { store };
