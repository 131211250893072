import React, { useEffect, useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { coreItem } from '0_variables/coreItem';
import { metaDataLoader } from '0_variables/utils';
import { getFilesThunk } from '1_reduxs/actions/filesAction';
import { initControl } from '1_reduxs/reducers/controllerReducer';
import { initSettingThunk } from '1_reduxs/actions/settingAction';
import {
  Dashboard,
  Upload,
  View,
  Analysis,
  Setting,
  ReportContainer,
} from '4_routers';
import {
  Header,
  Menu,
  Worklist,
  SpinnerModal,
  ScrollToTop,
  VersionModal,
} from '5_components';
import styles from './GlobalLayout.module.scss';
import ReportWrapper from './ReportWrapper';
import { changeCurrentProduct } from '1_reduxs/reducers/filesReducer';

export default function GlobalLayout() {
  // Routing Hooks
  const { path } = useRouteMatch();
  const { product: productName } = useParams();

  // redux hooks
  const dispatch = useDispatch();
  const spinnerModal = useSelector((state) => state.modal.spinner);
  const versionModal = useSelector((state) => state.modal.versionModal);

  const { isReportShowForPrint, printReportFileIds } = useSelector(
    (state) => state.layoutState,
  );

  const [isFileLoadComplete, setIsFileLoadComplete] = useState(false);

  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;

  // init
  useEffect(() => {
    async function init() {
      // load meta data
      await metaDataLoader();
      // get files
      dispatch(initSettingThunk());
      dispatch(initControl());
      await dispatch(getFilesThunk());

      setIsFileLoadComplete(true);

      // change current product
      dispatch(changeCurrentProduct(productName));
    }
    init();
  }, [dispatch, productName]);

  return (
    <>
      <ScrollToTop />
      <div className={`${styles['container']}`}>
        <Switch>
          <Route exact path={`${path}/dashboard`}>
            <Dashboard isBeginRender={isFileLoadComplete} />
          </Route>
          <Route exact path={`${path}/upload`}>
            <Upload />
          </Route>

          <Route
            exact
            path={`${path}/view/:fileID`}
            render={(props) => <View {...props} />}
          ></Route>
          <Route exact path={`${path}/analysis/:fileID`}>
            <Analysis />
          </Route>
          <Route exact path={`${path}/setting`}>
            <Setting />
          </Route>
          <Route exact path={`${path}/report/:fileID`}>
            <ReportContainer />
          </Route>
        </Switch>
      </div>
      <Header />
      <Menu />
      {!CTN_Mode && <Worklist />}
      {/* TODO: worklist에서 선택목록 묶음 다운받기 기능 검토중 2022.04.11 (월) */}
      {/* TODO: 선택했을때 리포트를 띄우지 말고, 다운로드 버튼 눌렀을때에만 리포트를 띄우고 다운로드 종료후에 리포트 닫기 */}

      {isReportShowForPrint && (
        <ReportWrapper selectFileIds={printReportFileIds} />
      )}
      {spinnerModal.status && <SpinnerModal />}
      {versionModal.status && <VersionModal />}
    </>
  );
}
