import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isReportShowForPrint: false,
  printReportFileIds: [],
  isOpenedWorklist: false,
  remainCounts: 'inf', // 'inf' or Number
  endDate: 'inf', // 'inf' or Date
};

const layoutSlice = createSlice({
  name: 'layoutState',
  initialState,
  reducers: {
    resetLayoutState: () => {
      return initialState;
    },
    updateLayoutState: (state, action) => {
      const { optionName, updateValue } = action.payload;
      state[optionName] = updateValue;
    },
    openReportForPrint: (state, action) => {
      const { fileIds } = action.payload;
      state.printReportFileIds = fileIds;
      state.isReportShowForPrint = true;
    },
  },
});

export const { resetLayoutState, updateLayoutState, openReportForPrint } =
  layoutSlice.actions;

export default layoutSlice.reducer;
