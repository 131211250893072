import React, { useEffect, useState } from 'react';
import styles from './Buttons.module.scss';
import { io } from 'socket.io-client';

const ConnectToPACSButton = (props) => {
  const { dateValid, countValid } = props;
  const [pacsHover, setPacsHover] = useState(false);
  const [n_niis, set_n_niis] = useState('0');

  useEffect(() => {
    if (process.env.REACT_APP_IS_OCI !== 'true') {
      // Cloud 버전은 socket.io 사용 않음
      if (props.title === 'Connect to PACS') {
        const socket = io(process.env.REACT_APP_BASE_URL, { secure: false });
        socket.removeAllListeners();
        socket.on('ping', (output) => {
          set_n_niis(output.n_niis);
        });
        return () => {
          socket.off('ping');
        };
      }
    }
  }, [props.title]);

  return (
    <div
      onMouseEnter={() => setPacsHover(true)}
      onMouseLeave={() => setPacsHover(false)}
      className={styles[props.styleName]}
      style={{ opacity: props.Enable ? 1 : 0.3 }}
    >
      <>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: `${pacsHover ? '-100%' : '0%'}`,
            display: props.Cloud_Mode ? 'none' : '',
          }}
          onClick={() => {
            if (dateValid && countValid) {
              if (n_niis === '0') alert('No dicom received.');
              else props.func.onClickHandler2();
            } else props.openModal('expired');
          }}
          role="button"
        >
          <label>{'Received Dicom'}</label>
          <div
            style={{
              borderRadius: '50%',
              background: 'red',
              width: '1vw',
              height: '1vw',
              position: 'absolute',
              left: '-10px',
              top: '-10px',
              zIndex: '2',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              visibility: `${n_niis === '0' ? 'hidden' : ''}`,
            }}
          >
            <span
              style={{
                fontSize: '0.5vw',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {n_niis}
            </span>
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0%',
            display: props.Cloud_Mode ? 'none' : '',
          }}
          onClick={() => {
            if (dateValid && countValid) props.func.onClickHandler();
            else props.openModal('expired');
          }}
          role="button"
        >
          <label>{'Connect to PACS'}</label>
        </div>
      </>
    </div>
  );
};

const UploadButton = (props) => {
  const { dateValid, countValid } = props;
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={styles[props.styleName]}
      style={{ opacity: props.Enable ? 1 : 0.3 }}
      aria-label="File selection buttons"
      role="button"
    >
      <>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: `${isHover ? '-100%' : '0%'}`,
          }}
          role="button"
        >
          <label
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {'Select a folder'}
            {dateValid && countValid ? (
              <input
                style={{ display: 'none' }}
                multiple
                type="file"
                webkitdirectory="true"
                onChange={(e) => {
                  props.func.onChangeHandler2(e);
                }}
                onClick={(e) => {
                  e.target.value = null;
                  props.func.onClickHandler();
                }}
              />
            ) : (
              <input
                style={{ display: 'none' }}
                onClick={() => {
                  props.openModal('expired');
                }}
              />
            )}
          </label>
        </div>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0%',
          }}
          role="button"
        >
          <label
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {'Select files'}
            {dateValid && countValid ? (
              <input
                style={{ display: 'none' }}
                multiple
                type="file"
                accept=".nii, .dicom, .dcm"
                onChange={(e) => {
                  // debugger;
                  props.func.onChangeHandler(e);
                }}
                onClick={(event) => {
                  try {
                    event.target.value = null;
                  } catch (error) {
                    // jest userevent는 mocking된 event 객체를 사용하기 때문에 target value를 null로 바꿀 수 없다.
                    if (
                      error.message.includes(
                        "Cannot use 'in' operator to search for 'Symbol(Displayed value in UI)' in null",
                      ) === false
                    ) {
                      console.warn(error);
                    }
                  }
                  props.func.onClickHandler();
                }}
              />
            ) : (
              <input
                style={{ display: 'none' }}
                onClick={() => {
                  props.openModal('expired');
                }}
              />
            )}
          </label>
        </div>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0%',
            display: `${isHover ? 'none' : ''}`,
          }}
        >
          <label>{props.title}</label>
        </div>
      </>
    </div>
  );
};

const DownloadSamplesButton = (props) => {
  return (
    <div
      role="button"
      className={styles[props.styleName]}
      style={{ display: props.Cloud_Mode ? '' : 'none' }}
      onClick={() => {
        props.func.onClickHandler();
      }}
    >
      <label>{props.title}</label>
    </div>
  );
};

const DefaultButton = (props) => {
  const {
    styleName,
    Enable: isEnable,
    func: funcObj,
    imgSrc,
    title,
    alt,
  } = props;
  return (
    <div
      role="button"
      aria-label={alt}
      className={styles[styleName]}
      style={{ opacity: isEnable ? 1 : 0.3 }}
      onClick={() => {
        isEnable ? funcObj.onClickHandler() : alert('Please select file(s).');
      }}
    >
      <div>{imgSrc}</div>
      <div>{title}</div>
    </div>
  );
};

export default function Buttons(props) {
  const { endDate, remainCounts, title } = props;

  const today = new Date();
  const expiredDate = new Date(endDate);
  const dateValid = endDate?.trim() === 'inf' ? true : today < expiredDate;
  const countValid = remainCounts?.trim() === 'inf' ? true : remainCounts > 0;

  if (title === 'Connect to PACS') {
    return (
      <ConnectToPACSButton
        {...props}
        dateValid={dateValid}
        countValid={countValid}
      />
    );
  } else if (title === 'Upload') {
    return (
      <UploadButton {...props} dateValid={dateValid} countValid={countValid} />
    );
  } else if (title === 'Download samples') {
    return <DownloadSamplesButton {...props} />;
  } else {
    return <DefaultButton {...props} />;
  }
}
