export const AnalysisTableForm = [
  {
    title: 'Regional SUVR',
    tableHead: ['Region', 'Total', 'Left', 'Right'],
  },
  {
    title: 'Regional SBR',
    tableHead: ['Region', 'Total', 'Left', 'Right'],
  },
  {
    title: 'Regional Centiloid',
    tableHead: ['Region', 'Total', 'Left', 'Right', 'Centiloid'],
  },
  {
    title: 'Semiquantification',
    tableHead: ['Measure', 'Total', 'Left', 'Right'],
  },
  {
    title: 'Regional Z-Score',
    tableHead: ['Region', 'Total', 'Left', 'Right'],
  },
];
