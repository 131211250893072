import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { getLicenseInfo } from '0_variables/utils';
import { SPECT_status, initProductInfo } from '0_variables/coreItem';
import { updateLicenseAll } from '1_reduxs/reducers/licenseReducer';
import styles from './SelectProduct.module.scss';

export default function SelectProduct() {
  const history = useHistory();
  const dispatch = useDispatch();
  const licenseState = useSelector((state) => state.license);
  const [licenseRequired, toggleLicenseRequirement] = useToggle(true);
  const [productInfo, setProductInfo] = useState(initProductInfo);
  const [hoverState, setHoverState] = useState({
    amyloid: false,
    dat: false,
    fdg: false,
    tau: false,
    perfusion: false,
  });

  useEffect(() => {
    async function updateLicenseInfoALL() {
      const productShortNames = initProductInfo.map((v) => v.productShortName);
      const licenseResult = await getLicenseInfo(productShortNames);
      dispatch(updateLicenseAll(licenseResult));
    }

    if (licenseRequired) {
      toggleLicenseRequirement();
      updateLicenseInfoALL();
    }
  }, [dispatch, licenseRequired, productInfo, toggleLicenseRequirement]);

  useEffect(() => {
    setProductInfo((prevProductInfo) => {
      return prevProductInfo.map((product) => {
        const { productShortName } = product;
        const isVisiable = SPECT_status || productShortName !== 'perfusion';

        const productWithVisibility = { ...product, isVisiable };

        if (licenseState[productShortName]) {
          const { until, valid } = licenseState[productShortName];
          return { ...productWithVisibility, validDate: until, license: valid };
        }

        return productWithVisibility;
      });
    });
  }, [licenseState]);

  return (
    <>
      <div
        className={styles.container}
        style={{ paddingTop: `${SPECT_status ? '15%' : '25%'}` }}
      >
        <div className={styles.boxContainer} id="selectproduct">
          {productInfo.map((product) => {
            const { productShortName, isVisiable } = product;
            if (!isVisiable) return null;
            return (
              <div className={styles.box} key={productShortName}>
                <div
                  role="button"
                  data-brtnx-product-active={product.license ? 'true' : 'false'}
                  className={styles.boxContents}
                  style={{
                    cursor: 'pointer',
                    border: `4px solid ${product.color}`,
                    opacity: `${product.license ? '' : '30%'}`,
                  }}
                  onClick={() => {
                    if (product.license) {
                      history.push(`/${product.productShortName}/dashboard`);
                    } else {
                      if (product.validDate) {
                        //license는 있지만 기간이 경과한 경우 제품 진입 가능
                        history.push(`/${product.productShortName}/dashboard`);
                      } else {
                        alert(
                          'A product license is required to use this service.',
                        );
                      }
                    }
                  }}
                  onMouseEnter={(e) => {
                    const updateHoverState = {};
                    updateHoverState[product.productShortName] = true;
                    const update = { ...hoverState, ...updateHoverState };
                    // console.log(update)
                    setHoverState(update);
                    // debugger;
                  }}
                  onMouseLeave={(e) => {
                    const updateHoverState = {};
                    updateHoverState[product.productShortName] = false;
                    const update = { ...hoverState, ...updateHoverState };
                    // console.log(update)
                    setHoverState(update);
                  }}
                >
                  <div style={{ opacity: `${product.license ? '' : '30%'}` }}>
                    {(() => {
                      if (product.license) {
                        if (product.validDate === 'inf') {
                          return (
                            <>
                              {hoverState[product.productShortName] ? (
                                <div style={{ fontSize: '1vw' }}>Activated</div>
                              ) : (
                                product.productFullName
                              )}
                            </>
                          );
                        } else {
                          return (
                            <>
                              {hoverState[product.productShortName] ? (
                                <div style={{ fontSize: '1vw' }}>
                                  Valid until {product.validDate}
                                </div>
                              ) : (
                                product.productFullName
                              )}
                            </>
                          );
                        }
                      } else {
                        return (
                          <>
                            {hoverState[product.productShortName] ? (
                              <div className={styles.licenseInfo}>
                                A product license is required to use this
                                service.
                              </div>
                            ) : (
                              product.productFullName
                            )}
                          </>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div style={{fontSize:"0.6vw", opacity:"50%"}}>This product has not been approved by the MFDS and FDA and is not intended to diagnose, treat, cure, or prevent any disease.</div> */}
      </div>
    </>
  );
}
