import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import {
  getTargetPathOnWorklist,
  openGroupFiles,
  isAllCompleteandSelected,
  isAnySelected,
  closeSingleFile,
} from '0_variables/utils';
import { updateWorklistThunk } from '1_reduxs/actions/filesAction';
import {
  openReportForPrint,
  updateLayoutState,
} from '1_reduxs/reducers/layoutReducer';
import { Buttons, Burger, FileTable } from '5_components';
import { minus as MEDIA_minus } from '6_media';
import styles from './Worklist.module.scss';
import {
  selectFilesByIds,
  selectWorklistFiles,
  updateWorklistPagination,
} from '1_reduxs/reducers/filesReducer';

export default function Worklist() {
  const history = useHistory();
  const location = useLocation();
  const { product: productName } = useParams();
  const dispatch = useDispatch();
  const IS_ENABLE_PAGINATION =
    process.env.REACT_APP_ENABLE_PAGINATION === 'true';

  const [selectFileIds, setSelectFileIds] = useState([]);
  const prevCurrentPageRef = useRef(1); // for selectFileIds clear
  const prevProductName = useRef(productName); // for selectFileIds clear

  const pagination = useSelector((state) => state.fileList.worklistPagination);
  const { items: filteredFileList, totalSize } =
    useSelector(selectWorklistFiles);
  const selectedFileList = useSelector((state) =>
    selectFilesByIds(state, selectFileIds),
  );
  const { isOpenedWorklist } = useSelector((state) => state.layoutState);

  const setSortClick = (tableHead) => {
    const { title, fieldName } = tableHead;
    const { orderBy, orderDirection } = pagination;

    if (title === 'Select') {
      return;
    }
    if (orderBy === fieldName) {
      dispatch(
        updateWorklistPagination({
          ...pagination,
          orderDirection: orderDirection === 'asc' ? 'desc' : 'asc',
        }),
      );
    } else {
      dispatch(
        updateWorklistPagination({
          ...pagination,
          orderBy: fieldName,
          orderDirection: 'desc',
        }),
      );
    }
  };

  const tableHead = [
    { title: 'Select', fieldName: 'Select' },
    { title: 'Tracer', fieldName: 'Tracer' },
    // {title: 'Centiloid', fieldName:'Global_C'},
    { title: 'Patient name', fieldName: 'PatientName' },
    { title: 'Patient ID', fieldName: 'PatientID' },
    { title: 'DOB', fieldName: 'Age' },
    { title: 'Sex', fieldName: 'Sex' },
    // {title: 'Scan data', fieldName:'AcquisitionDateTime'},
    { title: 'Uploaded date', fieldName: 'Update' },
  ];

  const toggleShowWorkList = (updateValue) => {
    dispatch(
      updateLayoutState({ optionName: 'isOpenedWorklist', updateValue }),
    );
  };

  const toggleShowReport = (fileIds) => {
    dispatch(openReportForPrint({ fileIds }));
  };

  const burgerFuncs = {
    downloadPDF: () => {
      const selectedFiles = selectedFileList.filter(
        (file) => file.Complete === true,
      );

      if (selectedFiles.length === 0) {
        alert('No file is seleted.');
      } else {
        // report rendering
        toggleShowReport(selectFileIds);
      }
    },
  };

  const deleteFromWorklist = (status) => {
    dispatch(updateWorklistThunk(status, selectedFileList));
    selectedFileList.forEach((item) => {
      setSelectFileIds((prev) => prev.filter((id) => id !== item.fileID));
    });
  };

  const openHandler = () => {
    const targetPage = getTargetPathOnWorklist(
      location.pathname.split('/')[2].toLowerCase(),
    );

    const completedFiles = selectedFileList.filter(
      (file) => file.Complete === true,
    );

    const selectedFileID = completedFiles.at(-1).fileID;

    openGroupFiles({
      targetPage,
      completedFiles,
      selectedFileID,
      productName,
      dispatch,
      history,
    });
    toggleShowWorkList(false);
  };

  const closeHandler = () => {
    selectedFileList.forEach((item) => {
      closeSingleFile(dispatch, item, productName);
    });
  };

  const funcSelector = {
    Worklist: () => {
      deleteFromWorklist(false);
    },
    Open: () => {
      openHandler();
    },
    Close: () => {
      closeHandler();
    },
  };

  const burgerProps = {
    Enable: isAllCompleteandSelected(selectedFileList),
    isViewContainer: false,
    isWorklist: true,
    pdfExportWorklist: burgerFuncs.downloadPDF,
    selectFileIds,
  };

  useEffect(() => {
    if (prevCurrentPageRef.current !== pagination.currentPage) {
      setSelectFileIds([]);
      prevCurrentPageRef.current = pagination.currentPage;
    }
    if (prevProductName.current !== productName) {
      setSelectFileIds([]);
      prevProductName.current = productName;
    }
  }, [pagination, productName]);

  return (
    <>
      {isOpenedWorklist && (
        <div
          className={styles.bg_worklist}
          onClick={() => toggleShowWorkList(!isOpenedWorklist)}
        ></div>
      )}
      <div
        className={`${styles.container} ${
          isOpenedWorklist ? styles.opened : styles.closed
        }`}
      >
        <div
          className={styles.worklistHandle}
          onClick={() => toggleShowWorkList(!isOpenedWorklist)}
        >
          <div>WORKLIST</div>
        </div>
        <div className={styles.worklistBox}>
          <div className={styles.head}>
            <div>{'WORKLIST'}</div>
            <Burger {...burgerProps} />
          </div>
          <div className={styles.body}>
            <FileTable
              identifier={'worklist'}
              productName={productName}
              tableHead={tableHead}
              FileList={filteredFileList}
              totalSize={totalSize}
              setSortClick={setSortClick}
              selectFileIds={selectFileIds}
              setSelectFileIds={setSelectFileIds}
              pagination={pagination}
            />
          </div>
          <div className={styles.foot}>
            <div>
              <Buttons
                {...{
                  Enable: isAnySelected(selectedFileList),
                  styleName: 'worklistBtn',
                  imgSrc: <img src={MEDIA_minus} alt="removeByWorklist" />,
                  title: 'Worklist',
                  alt: 'remove Worklist',
                  func: { onClickHandler: funcSelector['Worklist'] },
                }}
              />
            </div>
            <div>
              <Buttons
                {...{
                  Enable: isAnySelected(selectedFileList),
                  styleName: 'worklistBtn',
                  imgSrc: '',
                  title: 'Open',
                  alt: 'open file By Worklist',
                  func: { onClickHandler: funcSelector['Open'] },
                }}
              />
              {IS_ENABLE_PAGINATION === false && (
                <Buttons
                  {...{
                    Enable: isAnySelected(selectedFileList),
                    styleName: 'worklistBtn',
                    imgSrc: '',
                    title: 'Close',
                    alt: 'close tabs',
                    func: { onClickHandler: funcSelector['Close'] },
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
