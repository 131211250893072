import axios from 'axios';

export function zipNiftiSUVR(selectedList) {
  // debugger;
  return axios.put('testing/export_nifti_suvr/', {
    selectedList: selectedList,
  });
}

export function zipNiftiIntensity(selectedList) {
  return axios.put('testing/export_nifti_intensity/', {
    selectedList: selectedList,
  });
}
