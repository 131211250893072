import React, { useState } from 'react';
import styles from './PieGraph.module.scss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';

export default function PieGraph({ isBeginRender }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const { product: productName } = useParams();

  const fileList = useSelector(
    (state) => state.fileList.totalFiles[productName],
  );

  const productCoreItem = coreItem[productName];
  const tracerList = coreItem[productName].tracers;
  const COLORS = tracerList.map(({ color }) => color);
  const data = productCoreItem.tracers.map(({ shortname }) => {
    if (isBeginRender) {
      return {
        name: shortname,
        value: fileList.filter(({ Tracer }) => {
          return Tracer === shortname;
        }).length,
      };
    } else {
      return {
        name: shortname,
        value: 0,
      };
    }
  });

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const onPieLeave = (_, index) => {
    setActiveIndex(null);
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      percent,
      value,
      name,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          fontSize="15px"
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="white"
        >{`${name
          .replace('18', '\u00B9\u2078')
          .replace('99mtc', '\u2079\u2079\u1d50Tc')} ${value}`}</text>
        <text
          fontSize="15px"
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="white"
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  return (
    <div className={styles['container']}>
      <div className={styles['graphContainer']}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width="100%" height="100%">
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius="40%"
              outerRadius="55%"
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={onPieEnter}
              onMouseLeave={onPieLeave}
              stroke="none"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className={styles['textContainer']}>
          <span>
            {data.reduce((acc, v) => {
              acc += v.value;
              return acc;
            }, 0)}
          </span>
          <span>{'TOTAL'}</span>
        </div>
      </div>

      <div className={styles['legendContainer']}>
        {tracerList.map((v, i) => (
          <div key={i} style={{ borderLeft: `6px ${v.color} solid` }}>
            {v.shortname
              .replace('18', '\u00B9\u2078')
              .replace('99mtc', '\u2079\u2079\u1d50Tc')}
          </div>
        ))}
      </div>
    </div>
  );
}
