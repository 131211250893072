import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import _ from 'lodash';
import { coreItem } from '0_variables/coreItem';
import { getTargetPathOnWorklist, openGroupFiles } from '0_variables/utils';
import styles from './FileTable.module.scss';
import FileTableHead from './FileTableHead';
import FileTableRow from './FileTableRow';
import BtxPagination from './BtxPagination';

export default function FileTable({
  identifier,
  productName,
  tableHead,
  FileList: fileList,
  totalSize,
  setSortClick,
  selectFileIds = [],
  setSelectFileIds = (fileIds) => {},
  filter = {
    searchType: 'PatientName', // PatientID, PatientName, AcquisitionDateTime (scan date)
    searchKeyword: '',
  },
  pagination = {
    orderBy: 'FileName',
    orderDirection: 'desc',
    currentPage: 1,
    itemsPerPage: 10,
  },
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const IS_ENABLE_PAGINATION =
    process.env.REACT_APP_ENABLE_PAGINATION === 'true';
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const TracerColorMap = productCoreItem.tracers.reduce((map, tracer) => {
    map[tracer.shortname] = tracer.color;
    return map;
  }, {});

  const [slidingTargetID, setSlidingTargetID] = useState('');
  const [lastClickFileID, setLastClickFileID] = useState();

  const isTFP =
    productName === 'tau' ||
    productName === 'fdg' ||
    productName === 'perfusion';
  const isSelectAll = selectFileIds.length;
  const filteredTableHead = tableHead.filter((v) =>
    CTN_Mode ? v.title !== 'Centiloid' : true,
  );

  const toggleSelectFileId = (toggleFileId) => {
    const updatedSelectFileIds = selectFileIds.includes(toggleFileId)
      ? selectFileIds.filter((fileId) => fileId !== toggleFileId)
      : [...selectFileIds, toggleFileId];

    setSelectFileIds(updatedSelectFileIds);
  };

  const handleSelectAll = () => {
    if (isSelectAll) {
      setSelectFileIds([]);
    } else {
      const allFileIDs = fileList.map((file) => file.fileID);
      setSelectFileIds(allFileIDs);
    }
  };

  const shiftClickHandelr = (fileID) => {
    const index1 = fileList.findIndex((v) => v.fileID === fileID);
    const index2 = fileList.findIndex((v) => v.fileID === lastClickFileID);
    const idxs = [index1, index2];
    const minID = Math.min(...idxs);
    const maxID = Math.max(...idxs);

    const newFileList = fileList
      .filter((v, index) => index >= minID && index <= maxID)
      .map((v) => v.fileID);
    setSelectFileIds(newFileList);
  };

  const fileClickHandler = (fileData) => {
    toggleSelectFileId(fileData.fileID);
  };

  const fileDoubleClickHandler = (singleFile) => {
    if (singleFile.Complete) {
      const targetPage = getTargetPathOnWorklist(
        location.pathname.split('/')[2].toLowerCase(),
      );

      openGroupFiles({
        targetPage, // view or analysis
        completedFiles: [singleFile],
        selectedFileID: singleFile.fileID,
        productName,
        dispatch,
        history,
      });
    }
  };

  const processedFileList = fileList.map((file) => {
    const { Update, Tracer } = file;

    // Composite_C - centiloid [amyloid]
    const Composite_C = productName === 'amyloid' ? file['Composite_C'] : 0;
    // DAT_Dorsal_striatum - SBR
    const DAT_Dorsal_striatum =
      productName === 'dat' ? file['DAT_Dorsal_striatum'] : 0;
    const formattedTracerName = Tracer.replaceAll(
      '18',
      '\u00B9\u2078',
    ).replaceAll('99mtc', '\u2079\u2079\u1d50Tc');

    const selectedFields = _.pick(file, [
      'id',
      'fileID',
      'Complete',
      'PatientName',
      'OriginalFileName',
      'PatientID',
      'Age', // DOB
      'Sex',
      'AcquisitionDateTime', //scan date
    ]);

    return {
      ...selectedFields,
      Opened: true, // TODO 제거예정
      Update: Update.substring(0, 16),
      TracerColor: TracerColorMap[Tracer],
      Tracer: Tracer,
      tracerName: formattedTracerName,
      Composite_C, // amyloid
      DAT_Dorsal_striatum, // DAT
    };
  });

  return (
    <>
      <table className={styles.table} aria-label={`${identifier} table`}>
        <thead>
          <tr>
            {filteredTableHead.map((head, idx) => (
              <th
                key={idx}
                className={`${styles[head.title.replaceAll(' ', '_')]}`}
                onClick={() => setSortClick(head)}
              >
                <FileTableHead
                  columnTitle={head.title}
                  selectedStatus={isSelectAll}
                  isSortClicked={pagination.orderBy === head.fieldName}
                  orderDirection={pagination.orderDirection}
                  handleSelectAll={handleSelectAll}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody id="fileTable_body">
          {processedFileList.map((fileData) => (
            <tr
              key={fileData.fileID}
              style={{
                // 기존에는 열려있던 파일이면 배경색을 바꿔주는 로직이었으나
                // 이제는 선택된 파일의 배경색을 바꿔주는 로직으로 변경함 임시로
                background: selectFileIds.includes(fileData.fileID)
                  ? styles['opened-color']
                  : '',
                color: fileData.Complete
                  ? 'rgba(255,255,255,1)'
                  : 'rgba(255,255,255,0.3)',
              }}
              className={styles.trMain}
              onClick={(e) => {
                if (e.shiftKey) {
                  shiftClickHandelr(fileData.fileID);
                } else {
                  setLastClickFileID(fileData.fileID);
                  fileClickHandler(fileData);
                }
              }}
              onDoubleClick={() => {
                fileDoubleClickHandler(fileData);
              }}
            >
              <FileTableRow
                key={fileData.fileID}
                productName={productName}
                identifier={identifier}
                isTFP={isTFP}
                fileData={fileData}
                slidingTargetID={slidingTargetID}
                setSlidingTargetID={setSlidingTargetID}
                isSelected={selectFileIds.includes(fileData.fileID)}
              />
            </tr>
          ))}
        </tbody>
      </table>

      <ReactTooltip anchorSelect=".tooltip-file-table" opacity="1" />

      {IS_ENABLE_PAGINATION && (
        <BtxPagination
          totalSize={totalSize}
          pagination={pagination}
          identifier={identifier}
          filter={filter}
        />
      )}
    </>
  );
}
