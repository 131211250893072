import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import InputRange from 'react-input-range';
import { MdOutlineRefresh } from 'react-icons/md';
import { coreItem } from '0_variables/coreItem';
import {
  DEFAULT_WINDOW_MAX,
  DEFAULT_WINDOW_MIN,
} from '0_variables/btxConstants';
import { updateWindowMinMax } from '1_reduxs/reducers/viewOptionReducer';
import styles from './../ViewControlPanel.module.scss';
import '5_components/14_InputRange/InputRange.scss';

const WindowRangeBar = (props) => {
  const { viewerType, iconScaleUp, iconScaleReset, refreshIconRotate } = props;

  const dispatch = useDispatch();
  const { product: productName } = useParams();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const showNormalized = useSelector(
    (state) => state.viewOption.showNormalized,
  );

  const { window_max_out, window_min_out, window_max_in, window_min_in } =
    useSelector((state) => state.viewOption[viewerType]);

  const windowMin = showNormalized ? window_min_out : window_min_in;
  const windowMax = showNormalized ? window_max_out : window_max_in;

  const changeWindowMinMax = (WMin, WMax) => {
    dispatch(
      updateWindowMinMax({
        viewerType,
        windowMin: WMin,
        windowMax: WMax,
      }),
    );
  };

  return (
    <div className={styles.intensity}>
      <span>
        {CTN_Mode ? 'Intensity' : productName === 'dat' ? 'SBR' : 'SUVR'}
      </span>
      <span
        className={styles.reactIcon}
        onClick={() => {
          changeWindowMinMax(DEFAULT_WINDOW_MIN, DEFAULT_WINDOW_MAX);
        }}
      >
        <MdOutlineRefresh
          color={'white'}
          size={'1.7vw'}
          className="reactIcon"
          onMouseEnter={iconScaleUp}
          onMouseLeave={iconScaleReset}
          onClick={refreshIconRotate}
        />
      </span>
      <span className={styles.inputRange}>
        <div className="doubleSidedInput">
          <InputRange
            draggableTrack
            step={10}
            maxValue={DEFAULT_WINDOW_MAX}
            minValue={DEFAULT_WINDOW_MIN}
            value={{ min: windowMin, max: windowMax }}
            onChange={(value) => {
              const min = Math.floor(Math.max(DEFAULT_WINDOW_MIN, value.min));
              const max = Math.floor(Math.min(DEFAULT_WINDOW_MAX, value.max));

              changeWindowMinMax(min, max);
            }}
          />
        </div>
      </span>
    </div>
  );
};

export default WindowRangeBar;
