import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import InputRange from 'react-input-range';
import { MdOutlineRefresh } from 'react-icons/md';
import { coreItem } from '0_variables/coreItem';
import { updateViewOption } from '1_reduxs/reducers/viewOptionReducer';
import {
  viewOverlayOff as MEDIA_viewOverlayOff,
  viewOverlayOn as MEDIA_viewOverlayOn,
} from '6_media';
import styles from './../ViewControlPanel.module.scss';
import '5_components/14_InputRange/InputRange.scss';

const OpacityControl = (props) => {
  const { iconScaleUp, iconScaleReset, refreshIconRotate } = props;

  const dispatch = useDispatch();
  const opacity = useSelector((state) => state.viewOption.opacity);
  const { product: productName } = useParams();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;

  const defaultOpacity = CTN_Mode ? 1 : productName === 'dat' ? 1 : 0.6;

  const [opacityBackup, setOpacityBackup] = useState(opacity);

  const changeViewOption = (optionName, updateValue) => {
    dispatch(
      updateViewOption({
        optionName,
        updateValue,
      }),
    );
  };

  return (
    <div className={styles.overlay}>
      <span>Template</span>
      <span
        className={styles.reactIcon}
        onClick={() => {
          if (opacity === 1) {
            changeViewOption('opacity', opacityBackup);
          } else {
            changeViewOption('opacity', 1);
            setOpacityBackup(opacity);
          }
        }}
        style={{ border: '0px solid red' }}
        role="button"
        aria-label="opacity on/off button"
      >
        <img
          className={styles.overlayOnIcon}
          src={opacity < 1 ? MEDIA_viewOverlayOff : MEDIA_viewOverlayOn}
          onMouseEnter={iconScaleUp}
          onMouseLeave={iconScaleReset}
          alt="overlay"
        />
      </span>
      <span
        className={styles.reactIcon}
        onClick={() => {
          changeViewOption('opacity', defaultOpacity);
        }}
        role="button"
        aria-label="opacity set default value button"
      >
        <MdOutlineRefresh
          className="reactIcon"
          color={'white'}
          size={'1.7vw'}
          onMouseEnter={iconScaleUp}
          onMouseLeave={iconScaleReset}
          onClick={refreshIconRotate}
        />
      </span>
      <span className={styles.inputRange}>
        <div className="singleSidedInput">
          {/* step을 0.05로 하면 맥스 1로 올라가질 않음.
                ex : 0.9500000000000001 */}
          <InputRange
            step={5}
            maxValue={100}
            minValue={0}
            value={opacity * 100}
            onChange={(updatedValue) => {
              changeViewOption('opacity', updatedValue / 100);
            }}
          />
        </div>
      </span>
    </div>
  );
};

export default OpacityControl;
