import { coreItem } from '0_variables/coreItem';
import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_CORONAL_INDEX = 128 / 2 + 11;
const DEFAULT_SAGITTAL_INDEX = 130 / 2;
const DEFAULT_AXIAL_INDEX = 45 - 8;

// NOTE 열린파일들은 여기서 관리
const initialState = {
  selectedFileID: null,

  ...Object.keys(coreItem).reduce((obj, productName) => {
    obj[productName] = {
      // fileID를 key로 들어가는 객체로 변경
      openedFiles: {},
      reportItems: {},
    };
    return obj;
  }, {}),
};

const controllerSlice = createSlice({
  name: 'controller',
  initialState,
  reducers: {
    initControl: () => {
      return initialState;
    },
    setSelectedFileID: (state, { payload }) => {
      const { selectedFileID } = payload;
      state.selectedFileID = selectedFileID;
    },
    closeFile: (state, { payload }) => {
      const { productName, singleFile } = payload;
      const currentFileID = state.selectedFileID;
      const productState = state[productName];
      const { openedFiles } = productState;

      delete openedFiles[singleFile.fileID];

      // selectedFileID가 제거된 fileID와 같다면 다음 fileID로 변경
      const openedFileKeys = Object.keys(openedFiles);

      if (
        openedFileKeys.length > 0 &&
        (currentFileID === singleFile.fileID || currentFileID === null)
      ) {
        state.selectedFileID = openedFileKeys[0];
      } else if (openedFileKeys.length === 0) {
        state.selectedFileID = null;
      }
    },
    addGroupOpenFiles: (state, { payload }) => {
      const { productName, selectedFiles } = payload;
      const openedFiles = state[productName].openedFiles;

      const filteredFiles = selectedFiles
        .filter((file) => !openedFiles.hasOwnProperty(file.fileID))
        .map((file) => ({
          fileID: file.fileID,
          Tracer: file.Tracer,
          PatientName: file.PatientName,
          // 각 이미지의 초기 slice index 설정
          base: {
            coronal: DEFAULT_CORONAL_INDEX,
            sagittal: DEFAULT_SAGITTAL_INDEX,
            axial: DEFAULT_AXIAL_INDEX,
          },
          // mipIndex: 0,
        }));

      filteredFiles.forEach((file) => {
        openedFiles[file.fileID] = file;
      });
    },
    updateSliceIndex: (state, { payload }) => {
      const { productName, fileID, viewerType, direction, sliceIndex } =
        payload;
      const openedFiles = state[productName].openedFiles;

      const directions = ['coronal', 'sagittal', 'axial'];

      if (directions.includes(direction) === false) {
        return;
      }

      const fileControlData = openedFiles[fileID][viewerType];

      if (fileControlData) {
        fileControlData[direction] = sliceIndex;
      }
    },
    updateAllSliceIndices: (state, { payload }) => {
      const { productName, fileID, coronalIndex, sagittalIndex, axialIndex } =
        payload;
      const openedFiles = state[productName].openedFiles;

      const tempFileValue = openedFiles[fileID];

      if (tempFileValue) {
        tempFileValue['coronal'] = coronalIndex;
        tempFileValue['sagittal'] = sagittalIndex;
        tempFileValue['axial'] = axialIndex;

        openedFiles[fileID] = tempFileValue;
      }
    },
  },
});

export const {
  initControl,
  setSelectedFileID,
  closeFile,
  addGroupOpenFiles,
  updateSliceIndex,
  updateAllSliceIndices,
} = controllerSlice.actions;

export default controllerSlice.reducer;
