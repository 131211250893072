import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { changeSettings } from '1_reduxs/reducers/settingReducer';
import {
  Check as MEDIA_Check,
  unCheck as MEDIA_unCheck,
  justCheck as MEDIA_justCheck,
} from '6_media';
import styles from './CSVFormat.module.scss';

export default function CSVFormat() {
  const { product: productName } = useParams();
  const dispatch = useDispatch();
  const settingByProduct = useSelector((state) => state.setting[productName]);
  const csvFormat = settingByProduct.defaultCSVFormat;
  const [includeCheckBox, setIncludeCheckBox] = useState(csvFormat[0].state);

  //TODO : 상태 반영하기
  const csvFormatSet = (state) => {
    const updateSet = csvFormat.map(
      (obj) =>
        obj.title === 'Include data' && {
          ...csvFormat[0],
          state: state,
          subOption: [
            { title: 'Total', state: state },
            { title: 'Left & Right', state: state },
          ],
        },
    )[0];
    return updateSet;
  };

  const updateSettingToStore = (updatedSettingValue) => {
    const updatedSettings = {
      ...settingByProduct,
      ...updatedSettingValue,
    };
    dispatch(changeSettings({ productName, updatedSettings }));
  };

  return (
    <div className={styles.container}>
      {csvFormat?.map((el, cvsFormatIdx) => {
        return (
          <div
            key={`csv_${cvsFormatIdx}`}
            style={{
              display: `${
                productName !== 'amyloid' && el.title === 'Centiloid'
                  ? 'none'
                  : ''
              }`,
            }}
          >
            <div className={styles.title}>
              <div
                onClick={() => {
                  //TODO : 요기가 문제
                  setIncludeCheckBox(!includeCheckBox);
                  const updatedCSVFormat = csvFormat.map((obj) =>
                    obj.title === el.title
                      ? obj.title === 'Include data'
                        ? csvFormatSet(!includeCheckBox)
                        : { ...obj, state: !el.state }
                      : { ...obj },
                  );

                  updateSettingToStore({ defaultCSVFormat: updatedCSVFormat });
                }}
              >
                <img src={el.state ? MEDIA_Check : MEDIA_unCheck} alt="" />
                {/* <img src={el.state? MEDIA_Check : MEDIA_unCheck} alt=''/> */}
              </div>
              <div className={styles.optionContainer}>
                <div>
                  {el.title === 'SUVR' && productName === 'dat'
                    ? 'SBR'
                    : el.title}
                </div>
                <div className={styles.options}>
                  {el.subOption.length > 1 &&
                    el.subOption.map((v, subOptIdx) => (
                      <div
                        key={`csv_subopt_${subOptIdx}`}
                        className={styles.option}
                        onClick={() => {
                          const updatedSubOption = csvFormat.map((obj) =>
                            obj.subOption.map((obj2) =>
                              obj2.title === v.title
                                ? { title: obj2.title, state: !obj2.state }
                                : { title: obj2.title, state: obj2.state },
                            ),
                          );
                          const includeState = updatedSubOption[0]
                            .map((el) => el.state)
                            .some((state) => state === true);
                          const updatedCSVFormat = csvFormat.map((obj) =>
                            obj.title === el.title
                              ? {
                                  ...obj,
                                  state: includeState,
                                  subOption: updatedSubOption[0],
                                }
                              : { ...obj },
                          );

                          setIncludeCheckBox(includeState);
                          updateSettingToStore({
                            defaultCSVFormat: updatedCSVFormat,
                          });
                        }}
                      >
                        <div>{v.title}</div>
                        <img
                          src={MEDIA_justCheck}
                          alt=""
                          style={{
                            visibility: `${v.state ? '' : 'hidden'}`,
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
