import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputRange from 'react-input-range';
import { MdPlayArrow, MdPause } from 'react-icons/md';
import { updateViewOption } from '1_reduxs/reducers/viewOptionReducer';
import styles from './../ViewControlPanel.module.scss';
import '5_components/14_InputRange/InputRange.scss';

const MipPlayControl = (props) => {
  const { iconScaleUp, iconScaleReset, isForModal = false } = props;

  const dispatch = useDispatch();
  const clipConfigStatus = useSelector(
    (state) => state.viewOption.clipConfigStatus,
  );

  const clipConfigSpeed = useSelector(
    (state) => state.viewOption.clipConfigSpeed,
  );

  const changeViewOption = useCallback(
    (optionName, updateValue) => {
      dispatch(
        updateViewOption({
          optionName,
          updateValue,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      changeViewOption('clipConfigStatus', false);
    };
  }, [changeViewOption]);

  return (
    <div className={`${styles.mip}`}>
      {isForModal === false && <span>MIP </span>}
      <span
        className={styles.reactIcon}
        onClick={() => changeViewOption('clipConfigStatus', !clipConfigStatus)}
        role="button"
        aria-label="mip play/pause button"
      >
        {clipConfigStatus ? (
          <MdPause
            className="reactIcon"
            onMouseEnter={iconScaleUp}
            onMouseLeave={iconScaleReset}
            size={'1.7vw'}
          />
        ) : (
          <MdPlayArrow
            className="reactIcon"
            onMouseEnter={iconScaleUp}
            onMouseLeave={iconScaleReset}
            size={'1.7vw'}
          />
        )}
      </span>
      <span className={styles.inputRange}>
        <div className="singleSidedInput">
          <InputRange
            step={1}
            maxValue={50}
            minValue={0}
            value={clipConfigSpeed}
            onChange={(value) => {
              changeViewOption('clipConfigSpeed', value);
            }}
          />
        </div>
      </span>
    </div>
  );
};

export default MipPlayControl;
