import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BrainLobeFormats } from '0_variables/analysisConfigs';
import { RefFactor, dataFormatForBrainTable } from '0_variables/utils';
import { selectFileById } from '1_reduxs/reducers/filesReducer';
import { BrainTable } from '5_components';
import styles from './BrainLobarCard.module.scss';

export default function BrainLobarCard() {
  const { product: productName, fileID: targetFildId } = useParams();
  const settingOfProduct = useSelector((state) => state.setting[productName]);
  const selectedFile = useSelector((state) =>
    selectFileById(state, targetFildId),
  );

  const tracerName = selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName];

  const { legend, colorcode, content } = BrainLobeFormats[productName];

  const RF = RefFactor({
    productName,
    refName,
    ponsRF: selectedFile['ratio_gry2pons'],
    crblRF: selectedFile['ratio_gry2crbl'],
    wmRF: selectedFile['ratio_gry2wm'],
    cgRF: selectedFile['ratio_gry2cg'],
    global: 1 / selectedFile['Global'],
  });

  const items = dataFormatForBrainTable({
    productName,
    columnList: content,
    selectedFile,
    settingOfProduct,
    RF,
  });

  return (
    <div className={styles.lobar_content}>
      <BrainTable
        borderEnable={true}
        legend={legend}
        colorcode={colorcode}
        items={items}
        refName={refName}
      />
    </div>
  );
}
