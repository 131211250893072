import React, { useEffect, useRef } from 'react';
import styles from './App.module.scss';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { setLoginThunk } from '1_reduxs/actions/loginAction';
import { store } from '1_reduxs/store';
import { Home } from './4_routers';
import { GlobalLayout } from '5_components';

function App() {
  const loginState = useSelector((state) => state.login);
  const productCoreItem = coreItem['amyloid'];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const sessionUserName = sessionStorage.getItem('username');
  const { dispatch } = store;

  const isLogin = loginState.logged || !!sessionUserName;
  const timeoutRef = useRef(null);
  const INACTIVITY_TIME_LIMIT = 60 * 60 * 1000; // 페이지 열어 두고 1시간 동안 활동 없으면 로그아웃 처리

  const handleLogout = () => {
    dispatch(
      setLoginThunk({
        username: '',
        token: '',
        expire: '',
        refreshToken: '',
        logged: false,
      }),
    )
  };

  const resetTimer = () => {
    // 이전에 설정한 타이머가 있다면 제거
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    // 새로운 타이머 설정 (1시간 후 로그아웃)
    timeoutRef.current = setTimeout(() => {
      handleLogout();
    }, INACTIVITY_TIME_LIMIT);
  };

  useEffect(() => {
    if (process.env.REACT_APP_USE_AUTO_LOGOUT === 'true') {
      // 사용자의 액션을 감지할 이벤트들
      const events = ['click', 'keydown', 'scroll', 'touchstart'];

      // 각 이벤트 발생 시 타이머 리셋
      events.forEach((event) => {
        window.addEventListener(event, resetTimer);
      });

      // 초기 타이머 시작
      resetTimer();

      // Cleanup: 컴포넌트 언마운트 시 이벤트 제거와 타이머 정리
      return () => {
        events.forEach((event) => {
          window.removeEventListener(event, resetTimer);
        });
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      };
    }
  }, []);

  return (
    <>
      {isLogin && (
        <>
          {CTN_Mode && (
            <div
              className={`${
                sessionUserName
                  ? styles.menuCTNIndicator
                  : styles.homeCTNIndicator
              }`}
            >
              <div>CTN project viewer only</div>
              {/* <div style={{display:`${loginStateFromsessionStorage ?  "none": ""}`}}>기존 버전과 다른 username으로 계정 생성 및 로그인해주세요</div> */}
            </div>
          )}
          <Switch>
            <Route
              exact
              path={['/signin', '/signup', '/forgot', '/selectProduct']}
            >
              <Home />
            </Route>
            <Route path="/:product">
              <GlobalLayout />
            </Route>
            <Route path="/">
              <Redirect to={{ pathname: '/signin' }} />
            </Route>
          </Switch>
        </>
      )}
      {!isLogin && (
        <Switch>
          <Route exact path={['/signin', '/signup', '/forgot']}>
            <Home />
          </Route>
          <Route path="/">
            <Redirect to={{ pathname: '/signin' }} />
          </Route>
        </Switch>
      )}
    </>
  );
}

export default App;
