import React from 'react';

export default function BrtnxLogo() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 166.5 32.534"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="110.326"
          y1="18.634"
          x2="116.033"
          y2="14.286"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset=".154" stopColor="#fff" stopOpacity=".013" />
          <stop offset=".284" stopColor="#fff" stopOpacity=".054" />
          <stop offset=".404" stopColor="#fff" stopOpacity=".122" />
          <stop offset=".519" stopColor="#fff" stopOpacity=".218" />
          <stop offset=".631" stopColor="#fff" stopOpacity=".343" />
          <stop offset=".739" stopColor="#fff" stopOpacity=".495" />
          <stop offset=".845" stopColor="#fff" stopOpacity=".676" />
          <stop offset=".947" stopColor="#fff" stopOpacity=".88" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
      </defs>
      <path
        d="M0,.989c1.189-.24,3.572-.447,5.859-.447,2.771,0,4.467.27,5.924,1.131,1.398.744,2.381,2.113,2.381,3.958,0,1.757-1.041,3.393-3.272,4.255v.06c2.29.597,3.987,2.353,3.987,4.94,0,1.845-.834,3.274-2.083,4.255-1.458,1.161-3.899,1.815-7.887,1.815-2.232,0-3.897-.149-4.909-.297V.989ZM4.493,8.636h1.485c2.414,0,3.662-.982,3.662-2.471,0-1.516-1.16-2.32-3.214-2.32-1.013,0-1.577.06-1.934.148v4.643ZM4.493,17.533c.446.089.981.089,1.757.089,2.052,0,3.868-.803,3.868-2.914,0-2.023-1.816-2.828-4.078-2.828h-1.547v5.653Z"
        fill="#fff"
      />
      <path
        d="M17.976.989c1.458-.27,3.6-.447,6.038-.447,2.977,0,5.062.447,6.489,1.606,1.218.954,1.875,2.351,1.875,4.196,0,2.53-1.816,4.286-3.544,4.91v.089c1.402.565,2.173,1.875,2.678,3.72.627,2.292,1.224,4.911,1.611,5.684h-4.644c-.296-.565-.806-2.201-1.369-4.67-.564-2.531-1.428-3.184-3.304-3.215h-1.337v7.885h-4.493V.989ZM22.469,9.588h1.785c2.262,0,3.6-1.13,3.6-2.887,0-1.815-1.248-2.768-3.333-2.768-1.102,0-1.726.06-2.052.148v5.506Z"
        fill="#fff"
      />
      <path d="M40.174.69v20.057h-4.522V.69h4.522Z" fill="#fff" />
      <path
        d="M60.854,19.826c-1.396.476-4.048,1.13-6.694,1.13-3.659,0-6.308-.922-8.153-2.678-1.815-1.756-2.827-4.375-2.827-7.32.029-6.667,4.883-10.476,11.458-10.476,2.587,0,4.583.507,5.563.952l-.951,3.662c-1.103-.507-2.443-.865-4.643-.865-3.779,0-6.666,2.143-6.666,6.488,0,4.166,2.618,6.575,6.338,6.575,1.014,0,1.846-.118,2.203-.268v-4.225h-3.098v-3.542h7.47v10.566Z"
        fill="#fff"
      />
      <path
        d="M68.951.69v7.738h7.469V.69h4.523v20.057h-4.523v-8.36h-7.469v8.36h-4.523V.69h4.523Z"
        fill="#fff"
      />
      <path
        d="M88.801,4.498h-5.416V.69h15.415v3.809h-5.478v16.248h-4.521V4.498Z"
        fill="#fff"
      />
      <path
        d="M121.684,20.746V.69h5.299l4.164,7.35c1.192,2.113,2.353,4.614,3.272,6.876h.06c-.268-2.648-.357-5.357-.357-8.392V.69h4.166v20.057h-4.76l-4.285-7.736c-1.187-2.172-2.529-4.732-3.48-7.082h-.12c.147,2.679.208,5.505.208,8.779v6.039h-4.166Z"
        fill="#fff"
      />
      <path d="M146.77.69v20.057h-4.52V.69h4.52Z" fill="#fff" />
      <path
        d="M161.266,20.746l-1.818-3.63c-.744-1.399-1.219-2.44-1.783-3.601h-.059c-.416,1.161-.925,2.201-1.577,3.601l-1.639,3.63h-5.175l5.801-10.146-5.593-9.911h5.206l1.755,3.66c.596,1.221,1.043,2.203,1.519,3.333h.059c.476-1.28.861-2.171,1.367-3.333l1.698-3.66h5.177l-5.654,9.791,5.951,10.266h-5.234Z"
        fill="#fff"
      />
      <g>
        <path d="M2.041,25.116v7.29H.549v-7.29h1.492Z" fill="#fff" />
        <path
          d="M29.981,32.406v-4.519c0-.328.02-.834.061-1.516h-.029c-.085.419-.17.712-.242.894l-2.041,5.14h-1.136l-2.067-5.098c-.053-.13-.133-.444-.241-.936h-.035c.035.539.059,1.125.059,1.756v4.278h-1.356v-7.29h2.142l1.808,4.541c.145.377.246.71.295.999h.036c.075-.284.186-.622.323-1.008l1.816-4.532h2.085v7.29h-1.477Z"
          fill="#fff"
        />
        <path
          d="M58.294,32.406h-1.629l-.624-1.745h-2.893l-.608,1.745h-1.626l2.85-7.29h1.723l2.808,7.29ZM55.67,29.543l-.943-2.639c-.036-.107-.08-.309-.118-.607h-.035c-.032.223-.073.42-.134.595l-.947,2.651h2.178Z"
          fill="#fff"
        />
        <path
          d="M85.326,31.91c-.759.414-1.672.623-2.745.623-1.189,0-2.142-.329-2.847-.982-.705-.657-1.057-1.545-1.057-2.677s.383-2.065,1.149-2.79c.77-.726,1.784-1.089,3.05-1.089.824,0,1.539.113,2.144.338v1.371c-.595-.34-1.32-.511-2.188-.511-.763,0-1.383.242-1.866.715-.479.478-.718,1.107-.718,1.895,0,.796.217,1.418.647,1.861.436.445,1.029.667,1.781.667.463,0,.846-.072,1.15-.213v-1.611h-1.58v-1.151h3.078v3.555Z"
          fill="#fff"
        />
        <path d="M110.146,25.116v7.29h-1.492v-7.29h1.492Z" fill="#fff" />
        <path
          d="M140.16,32.406h-1.562l-3.406-4.938c-.145-.199-.258-.391-.348-.562h-.026c.032.232.047.609.047,1.129v4.372h-1.427v-7.29h1.67l3.287,4.81c.166.244.29.43.365.562h.02c-.029-.127-.042-.469-.042-1.018v-4.355h1.423v7.29Z"
          fill="#fff"
        />
        <path
          d="M166.031,31.91c-.757.414-1.671.623-2.74.623-1.193,0-2.146-.329-2.851-.982-.706-.657-1.055-1.545-1.055-2.677s.386-2.065,1.149-2.79c.77-.726,1.785-1.089,3.047-1.089.825,0,1.54.113,2.144.338v1.371c-.592-.34-1.319-.511-2.185-.511-.76,0-1.385.242-1.865.715-.479.478-.721,1.107-.721,1.895,0,.796.217,1.418.65,1.861.432.445,1.026.667,1.777.667.463,0,.848-.072,1.15-.213v-1.611h-1.579v-1.151h3.077v3.555Z"
          fill="#fff"
        />
      </g>
      <g>
        <path
          d="M109.549,16.497h0v4.247h0c5.727,0,10.374-4.643,10.374-10.371h-4.247c0,3.382-2.743,6.124-6.127,6.124Z"
          fill="url(#linear-gradient)"
        />
        <path
          d="M109.552,0h0c-5.728.001-10.374,4.644-10.374,10.373h.001c0,5.727,4.643,10.374,10.371,10.374v-4.247c-3.382,0-6.124-2.743-6.124-6.127h-.001c0-3.382,2.743-6.124,6.127-6.124h0c3.382-.001,6.124,2.742,6.124,6.125h4.247c0-5.727-4.643-10.374-10.371-10.374Z"
          fill="#fff"
        />
        <path
          d="M115.689,10.356l1.934-2.639c.01-.013.029-.014.04-.001l2.246,2.641c.008.01.008.024,0,.033l-1.95,2.146c-.01.011-.026.011-.036.001l-2.23-2.148c-.009-.009-.01-.023-.003-.033Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
