import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import { store } from '1_reduxs/store';
import {
  refreshTokens,
  setLoginThunk,
  waitForRefreshTokenJobInfoCondition,
} from '1_reduxs/actions/loginAction';
import { ScrollToTop } from '5_components';
import './custom.scss'; // Import custom SCSS file
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { datadogRum } from '@datadog/browser-rum';

if (process.env.REACT_APP_USE_DATADOG_RUM === 'true') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: process.env.REACT_APP_DATADOG_RUM_SITE,
    service: process.env.REACT_APP_DATADOG_RUM_SERVICE,
    env: process.env.REACT_APP_DATADOG_RUM_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_DATADOG_RUM_VERSION,
    sessionSampleRate: Number(process.env.REACT_APP_DATADOG_RUM_SESSION_SAMPLE_RATE),
    sessionReplaySampleRate: Number(process.env.REACT_APP_DATADOG_RUM_REPLAY_SAMPLE_RATE),
    trackUserInteractions: process.env.REACT_APP_DATADOG_RUM_TRACK_USER_INTERACTIONS === 'true',
    trackResources: process.env.REACT_APP_DATADOG_RUM_TRACK_RESOURCES === 'true',
    trackLongTasks: process.env.REACT_APP_DATADOG_RUM_TRACKLONG_TASKS === 'true',
    defaultPrivacyLevel: process.env.REACT_APP_DATADOG_RUM_DEFAULT_PRIVACY_LEVEL,
    allowedTracingUrls: [process.env.REACT_APP_BASE_URL, (url) => url.startsWith(process.env.REACT_APP_BASE_URL)],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
reportWebVitals();

// axios config
const UNAUTHORIZED = 401;
// direct access to redux store.
const { dispatch } = store;

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(
  async (config) => {
    // 만약 token 갱신 작업 중이면 완료될 때까지 대기
    if (process.env.REACT_APP_USE_REFRESH_TOKEN === 'true') {
      const { hasNewJob } = store.getState().login;

      if (hasNewJob) {
        await waitForRefreshTokenJobInfoCondition(
          store,
          (state) => state.login.hasNewJob === false,
        );
      }
    }

    const token = sessionStorage.getItem('token');

    try {
      if (token !== '') {
        config.headers.Authorization = `jwt ${token}`;
      }
      return config;
    } catch (err) {
      console.error('[axios request config error]' + err);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response && error.response.status) {
      const { status } = error.response;
      const config = error.config;

      if (!config.doNotRefreshToken) { // 무한루프 방지용 플래그가 없으면 토큰 갱신 시도할 수 있음
        let currentToken = sessionStorage.getItem('token');
        let refreshToken = sessionStorage.getItem('refreshToken');
        let username = sessionStorage.getItem('username');
        
        if (status === UNAUTHORIZED && !config.url.endsWith('testing/refresh_token/')) {
          if (process.env.REACT_APP_USE_REFRESH_TOKEN === 'true') {
            if (error.response.data.detail === 'Signature has expired.') { // access token 만료 시
              // 만약 다른 API 호출에 의해 이미 토큰 갱신 작업 중이면 경우 잠시 대기하고,
              // 실행 중이 아닐 때 finishedTime 값을 이용해서 토큰 갱신 여부 결정
              const { hasNewJob } = store.getState().login;
              let finishedTime = '';
              
              if (hasNewJob) {
                finishedTime = (await waitForRefreshTokenJobInfoCondition(
                  store,
                  (state) => state.login.hasNewJob === false,
                )).finishedTime;
              }

              let shouldRefreshToken = false; // token을 갱신해야 하는지 여부 체크용(true = 갱신해야 함)

              if (finishedTime !== '' && new Date() - new Date(finishedTime) <= 60 * 1000) {
                // 최근 토큰 갱신 시간이 1분이 넘은 경우 처리
                
                if (config.headers.Authorization !== `jwt ${currentToken}`) {
                  // 처음 이 Axios 호출을 시도했을 때와 token이 달라진 게 확실한지 확인되면
                  // 새 token으로 다시 Axios 호출하고 이 함수 종료
                  config.headers.Authorization = `jwt ${currentToken}`;
                  return axios.request(config);
                } else {
                  // 최근에 토큰 갱신이 없거나 최근 토큰 갱신 시간이 1분이 넘었으면
                  // token을 다시 갱신해야 하는 상황으로 간주함
                  shouldRefreshToken = true;
                }
              } else {
                // 최근에 토큰 갱신한 적이 없거나 최근 토큰 갱신 시간이 1분이 넘은 경우
                // token을 다시 갱신해야 하는 상황으로 간주함
                shouldRefreshToken = true;
              }

              if (shouldRefreshToken && refreshToken !== '' && username !== '') {
                // 위에서 token을 재발급해야 한다고 판단했고
                // refresh token과 username이 있는 경우
                // 토큰 갱신 시도
                const rtRes = await dispatch(
                  refreshTokens({
                    username,
                    refresh_token: refreshToken,
                  }),
                );

                if(rtRes.code === 200) { // token 재발급 성공
                  config.headers.Authorization = `jwt ${rtRes.token}`;
                  config.doNotRefreshToken = true; // 무한루프 방지용 플래그 설정
                  return await axios.request(config); // token을 바꿔서 원래의 Axios 호출을 재시도
                } // else: token 재발급 실패로 로그아웃 처리함
              } // else: token 재발급 실패로 로그아웃 처리함
            } // else: access token 만료가 아닌 경우 로그아웃 처리함
          } // else: REACT_APP_USE_REFRESH_TOKEN='false' 환경에서 UNAUTHORIZED 에러 발생 시 무조건 로그아웃
          
          // 로그아웃 처리
          username = sessionStorage.getItem('username'); // 현재 시점에서 로그아웃 여부 다시 체크
          if (username !== '') {
            // 아직 로그인 상태면 로그아웃 처리
            dispatch(
              setLoginThunk({
                username: '',
                token: '',
                expire: '',
                refreshToken: '',
                logged: false,
              }),
            );

            // 로그아웃 처리 후 에러 메시지 출력
            alert(
              "You've been logged out. This may have been caused by another account logged in at the same time. Please use one account at a time. ",
            );
          } // else: 이미 다른 API 호출 등으로 로그아웃된 상태이므로 reject 처리만 함
        } // else: UNAUTHORIZED가 아니면 바로 reject 처리함
      }
    }
    return Promise.reject(error);
  },
);
