import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectFileById } from '1_reduxs/reducers/filesReducer';
import styles from './BrainSurfaceCard.module.scss';

export default function BrainSurfaceCard() {
  const { product: productName, fileID: targetFildId } = useParams();
  const settingOfProduct = useSelector((state) => state.setting[productName]);
  const selectedFile = useSelector((state) =>
    selectFileById(state, targetFildId),
  );

  const tracerName = selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName];

  const [currentStepIndex, setCurrentStepIndex] = useState(1.6);
  const [targetTracerRef, setTargetTracerRef] = useState('wcrbll');
  const username = sessionStorage.getItem('username');

  const refStepIndex = {
    crbllGM: {
      ref: 'crbllGM',
      min: 1.0,
      max: 2.2,
      step: 0.2,
      default: 1.6,
      th_percent: 50,
    },
    crbllGM_spect: {
      ref: 'crbllGM',
      min: 0.5,
      max: 1.1,
      step: 0.1,
      default: 0.8,
      th_percent: 25,
    },
    wcrbll: {
      ref: 'wcrbll',
      min: 0.75,
      max: 1.65,
      step: 0.15,
      default: 1.2,
      th_percent: 50,
    },
    wcrbll_spect: {
      ref: 'wcrbll',
      min: 0.3,
      max: 0.9,
      step: 0.1,
      default: 0.6,
      th_percent: 25,
    },
    pons: {
      ref: 'pons',
      min: 0.5,
      max: 1.1,
      step: 0.1,
      default: 0.8,
      th_percent: 50,
    },
    cerebralWM: {
      ref: 'cerebralWM',
      min: 0.5,
      max: 1.1,
      step: 0.1,
      default: 0.8,
      th_percent: 50,
    },
  };

  const generateImageUrl = (stepIndex, type) => {
    return (
      process.env.REACT_APP_BASE_URL +
      'result/download/' +
      username +
      '/database/' +
      selectedFile.fileID +
      `/_${type}_` +
      parseFloat(stepIndex).toFixed(1) +
      '.png'
    );
  };

  useEffect(() => {
    if (refName === 'Whole cerebellum') {
      if (productName === 'perfusion') setTargetTracerRef('wcrbll_spect');
      else setTargetTracerRef('wcrbll');
    } else if (refName === 'Pons') {
      setTargetTracerRef('pons');
    } else if (refName === 'Cerebellar gray matter') {
      if (productName === 'perfusion') setTargetTracerRef('crbllGM_spect');
      else setTargetTracerRef('crbllGM');
    } else if (refName === 'Cerebral white matter') {
      setTargetTracerRef('cerebralWM');
    }
  }, [refName, productName]);

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.imgContainer}>
          <div className={styles.imgContainer_subContainer}>
            <div className={styles.imgBox}>
              <img
                src={generateImageUrl(currentStepIndex, 'rlat')}
                alt="rlat"
              />
              <span>Right Lateral</span>
            </div>
            <div className={styles.imgBox}>
              <img
                src={generateImageUrl(currentStepIndex, 'llat')}
                alt="llat"
              />
              <span>Left Lateral</span>
            </div>
          </div>
          <div className={styles.imgContainer_subContainer}>
            <div className={styles.imgBox}>
              <img
                src={generateImageUrl(currentStepIndex, 'rmed')}
                alt="rmed"
              />
              <span>Right Medial</span>
            </div>
            <div className={styles.imgBox}>
              <img
                src={generateImageUrl(currentStepIndex, 'lmed')}
                alt="lmed"
              />
              <span>Left Medial</span>
            </div>
          </div>
        </div>
        <div className={styles.indicatorContainer}>
          <div>SUVR</div>
          <div>
            <input
              type="range"
              min={`${refStepIndex['crbllGM']?.min}`}
              max={`${refStepIndex['crbllGM']?.max}`}
              step={`${refStepIndex['crbllGM']?.step}`}
              value={currentStepIndex}
              onInput={(e) => setCurrentStepIndex(e.currentTarget.value)}
            />
          </div>
          <div>
            <span>
              &nbsp;
              {(() => {
                // crbllGM
                // wcrbll
                // pons
                // cerebralWM
                const objValue = {
                  '1.00': {
                    crbllGM: '1.00',
                    crbllGM_spect: '0.50',
                    wcrbll: '0.75',
                    wcrbll_spect: '0.30',
                    pons: '0.50',
                    cerebralWM: '0.50',
                  },
                  '1.20': {
                    crbllGM: '1.20',
                    crbllGM_spect: '0.60',
                    wcrbll: '0.90',
                    wcrbll_spect: '0.40',
                    pons: '0.60',
                    cerebralWM: '0.60',
                  },
                  '1.40': {
                    crbllGM: '1.40',
                    crbllGM_spect: '0.70',
                    wcrbll: '1.05',
                    wcrbll_spect: '0.50',
                    pons: '0.70',
                    cerebralWM: '0.70',
                  },
                  '1.60': {
                    crbllGM: '1.60',
                    crbllGM_spect: '0.80',
                    wcrbll: '1.20',
                    wcrbll_spect: '0.60',
                    pons: '0.80',
                    cerebralWM: '0.80',
                  },
                  '1.80': {
                    crbllGM: '1.80',
                    crbllGM_spect: '0.90',
                    wcrbll: '1.35',
                    wcrbll_spect: '0.70',
                    pons: '0.90',
                    cerebralWM: '0.90',
                  },
                  '2.00': {
                    crbllGM: '2.00',
                    crbllGM_spect: '1.00',
                    wcrbll: '1.50',
                    wcrbll_spect: '0.80',
                    pons: '1.00',
                    cerebralWM: '1.00',
                  },
                  '2.20': {
                    crbllGM: '2.20',
                    crbllGM_spect: '1.10',
                    wcrbll: '1.65',
                    wcrbll_spect: '0.90',
                    pons: '1.10',
                    cerebralWM: '1.10',
                  },
                };
                const srcValue = parseFloat(currentStepIndex).toFixed(2);
                const dstValue = objValue[srcValue][targetTracerRef];
                return dstValue;
              })()}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
