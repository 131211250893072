import React from 'react';
import styles from './TextDisplay.module.scss';

export default function TextDisplay(props) {
  const { title, value, name, style: styleOptions } = props;
  return (
    <div
      className={styles.textDisplay}
      style={{
        ...styleOptions,
        pointerEvent: 'none',
        userSelect: 'none',
      }}
    >
      {name === 'direction' ? title : `${title}: ${value}`}
    </div>
  );
}
