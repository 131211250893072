import axios from 'axios';
import BTXDB from '2_services/btxdb';

export async function getSlices(fileID) {
  // return axios.get('testing/viewer/' + data.fileID + '/');
  const putSlicedDBData = async (dataArray, fileID) => {
    await BTXDB.slices.bulkPut(
      dataArray.map((item) => ({ ...item, CaseID: Number(fileID) })),
    );
  };

  const dbResult = await BTXDB.slices
    .where({ CaseID: Number(fileID) })
    .toArray();

  if (dbResult.length) {
    return dbResult;
  } else {
    const apiResult = await axios.get(`testing/viewer/${fileID}/`);
    // 정상적인 데이터 길이는 792이다. 대부분은 http 404에러가 발생한다.
    if (apiResult.data.length !== 792) {
      throw new Error('Invalid data length');
    }
    await putSlicedDBData(apiResult.data, fileID);
    return apiResult.data;
  }
}

export function getReportImage(data) {
  // return axios.get(
  //   'testing/report/' + data.fileID + '/mip/' + data.imageID + '/',
  // );
  return axios.get(`testing/report/${data.fileID}/mip/${data.imageID}/`);
}

export function getSlice(data) {
  // return axios.get(
  //   'testing/slice/' +
  //     data.fileID +
  //     '/' +
  //     data.Direction +
  //     '/' +
  //     data.sliceID +
  //     '/',
  // );
  return axios.get(
    `testing/slice/${data.fileID}/${data.Direction}/${data.sliceID}/`,
  );
}
