import axios from 'axios';

export function check_file(check_list) {
  return axios.post('testing/check_file/', { check_list });
}

export function fetchFiles() {
  return axios.get('testing/');
}

export function update_worklist(data) {
  return axios.put('testing/update_filelist/', {
    obj: data.obj,
    addToWorklist: data.addToWorklist,
  });
}

export async function deleteSelection(data) {
  const payload = {
    data: null,
  };

  // Kevin: Payload should be alwyays delivered as an object
  payload.data = {
    files: data.obj,
    product: data.product,
  };

  const results = await axios.delete('testing/', payload);
  return results;
}
