import React from 'react';
import { isNil } from 'lodash';
import ViewerGroup from './ViewerGroup';
import { ViewControlPanel } from './ViewControlPanel';
import styles from './Container.module.scss';

export default function Container(props) {
  const { productName, fileRFSubset, selectedFileID, targetFileID } = props;

  const isShowTripleViewer = isNil(targetFileID) === false;

  return (
    <div className={`${styles['container']}`}>
      <ViewControlPanel isSubtractMode={isShowTripleViewer} />

      <div className={styles.viewGroup}>
        <ViewerGroup
          fileID={selectedFileID}
          productName={productName}
          fileRFSubset={fileRFSubset}
        />
      </div>
    </div>
  );
}
