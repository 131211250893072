import React, { useEffect } from 'react';
import styles from './ReportPages.module.scss';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { subRegions } from '0_variables/subRegions';
import { RefFactor, limitNum as UTILS_LIMITNUM } from '0_variables/utils';
import { selectFileById } from '1_reduxs/reducers/filesReducer';
import { ReportPage1, ReportPage2, ReportPage3 } from './components';

export default function ReportPages(props) {
  const { fileID: currentFileId, isReportWrapper = false } = props;
  const history = useHistory();
  const { product: productName } = useParams();
  const setting = useSelector((state) => state.setting);
  const fileList = useSelector(
    (state) => state.fileList.totalFiles[productName],
  );
  const selectedFile = useSelector((state) =>
    selectFileById(state, currentFileId),
  );

  useEffect(() => {
    if (fileList.length === 0) {
      history.push(`/${productName}/upload`);
    }
  }, [fileList, history, productName]);

  if (!selectedFile) return null;

  const settingOfProduct = setting[productName];
  const tracerName = selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName];

  const RF = RefFactor({
    productName,
    refName,
    ponsRF: selectedFile['ratio_gry2pons'],
    crblRF: selectedFile['ratio_gry2crbl'],
    wmRF: selectedFile['ratio_gry2wm'],
    cgRF: selectedFile['ratio_gry2cg'],
    global: 1 / selectedFile['Global'],
  });

  const RFforReport = (() => {
    if (refName === 'Pons') return 'pons';
    else if (refName === 'Whole cerebellum') return 'wc';
    else if (refName === 'Cerebellar gray matter') return 'cg';
    else if (refName === 'Cerebral white matter') return 'wm';
    else return 'cg';
  })();

  const defaultRegionLength = subRegions[productName]
    .map((v) => v.level)
    .filter((lev) => lev === 0).length;
  const settingRegionLength = settingOfProduct.list
    .map((v) => v.level)
    .filter((lev) => lev === 0).length;
  const limitNum = UTILS_LIMITNUM;

  const pageLength =
    settingRegionLength - defaultRegionLength > limitNum[productName]
      ? selectedFile.Tracer === '[18F]FPN'
        ? 3
        : 4
      : 3;

  return (
    <>
      <div className={styles.A4pages}>
        <ReportPage1
          selectedFile={selectedFile}
          fileID={currentFileId}
          pageNumber={'1/' + pageLength}
        />
      </div>
      <div className={styles.A4pages}>
        <ReportPage2
          selectedFile={selectedFile}
          fileID={currentFileId}
          pageNumber={'2/' + pageLength}
          RF={RF}
          pageLength={pageLength}
        />
      </div>
      {pageLength === 4 && (
        <div className={styles.A4pages}>
          <ReportPage2
            selectedFile={selectedFile}
            fileID={currentFileId}
            pageNumber={'3/' + pageLength}
            RF={RF}
            pageLength={pageLength}
          />
        </div>
      )}

      <div className={styles.A4pages}>
        <ReportPage3
          selectedFile={selectedFile}
          fileID={currentFileId}
          pageNumber={`${pageLength === 3 ? '3/' : '4/'}` + pageLength}
          refName={RFforReport}
          pageLength={pageLength}
          isReportWrapper={isReportWrapper}
        />
      </div>
    </>
  );
}
