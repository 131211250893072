import React from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { gsap } from 'gsap';
import { coreItem } from '0_variables/coreItem';
import { COLOR_MAPS, VIEWER_TYPE } from '0_variables/btxConstants';
import { updateColorMap } from '1_reduxs/reducers/viewOptionReducer';
import { Information, DropDownDiv, Burger } from '5_components';
import MipPlayControl from './components/MipPlayControl';
import OpacityControl from './components/OpactityControl';
import WindowRangeBar from './components/WindowRangeBar';
import ViewCtrlBtnGroup from './components/ViewCtrlBtnGroup';
import styles from './ViewControlPanel.module.scss';
import '5_components/14_InputRange/InputRange.scss';

export const ViewControlPanel = (props) => {
  const { isSubtractMode } = props;
  const dispatch = useDispatch();

  const baseColorMap = useSelector(
    (state) => state.viewOption[VIEWER_TYPE.BASE].colorMap,
  );

  const subColorMap = useSelector(
    (state) => state.viewOption[VIEWER_TYPE.SUBTRACTION_RESULT].colorMap,
  );

  const { product: productName } = useParams();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;

  const changeColorMap = (viewerType, updateValue) => {
    dispatch(
      updateColorMap({
        viewerType,
        updateValue,
      }),
    );
  };

  const burgerProps = {
    Enable: true,
    isViewContainer: true,
    isWorklist: false,
  };

  const cmapDropdown = {
    selectItem: baseColorMap,
    options: [
      COLOR_MAPS.INVERT_GRAY,
      COLOR_MAPS.HOT,
      COLOR_MAPS.JET,
      COLOR_MAPS.PET,
    ],
    // options: ['invertGray', 'hot', 'jet', 'pet'],
    setItem: (val) => {
      changeColorMap(VIEWER_TYPE.BASE, val);
      changeColorMap(VIEWER_TYPE.SUBTRACTION_TARGET, val);
    },
  };

  const subColorDropdown = {
    selectItem: subColorMap,
    options: [COLOR_MAPS.CUSTOM_HOT_METAL_BLUE, COLOR_MAPS.CUSTOM_COOL_WARM],
    setItem: (val) => {
      changeColorMap(VIEWER_TYPE.SUBTRACTION_RESULT, val);
    },
  };

  const iconScaleUp = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1.2 });
  };

  const iconScaleReset = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1 });
  };

  const refreshIconRotate = ({ currentTarget }) => {
    gsap.to(currentTarget, { rotation: '+=360' });
  };

  return (
    <div>
      <div className={styles.viewController}>
        {isSubtractMode === false && (
          <MipPlayControl
            iconScaleUp={iconScaleUp}
            iconScaleReset={iconScaleReset}
          />
        )}
        {!CTN_Mode && (
          <OpacityControl
            iconScaleUp={iconScaleUp}
            iconScaleReset={iconScaleReset}
            refreshIconRotate={refreshIconRotate}
          />
        )}
        {isSubtractMode === false && (
          <WindowRangeBar
            viewerType={VIEWER_TYPE.BASE}
            iconScaleUp={iconScaleUp}
            iconScaleReset={iconScaleReset}
            refreshIconRotate={refreshIconRotate}
          />
        )}
        <ViewCtrlBtnGroup
          isSubtractMode={isSubtractMode}
          iconScaleUp={iconScaleUp}
          iconScaleReset={iconScaleReset}
        />
        <div
          style={{
            width: isSubtractMode ? '18%' : '15%',
          }}
        >
          {isSubtractMode && (
            <div
              style={{
                textAlign: 'center',
                alignContent: 'center',
              }}
            >
              SUVR
            </div>
          )}
          <span>
            <DropDownDiv
              style={{
                width: isSubtractMode ? '10vw' : '13vw',
                height: '100%',
                background: '#34383C',
              }}
              item={cmapDropdown}
              styleName="view_drop_container"
            />
          </span>
        </div>

        {isSubtractMode && (
          <div
            style={{
              width: isSubtractMode ? '18%' : '15%',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                alignContent: 'center',
              }}
            >
              Subtraction
            </div>
            <span>
              <DropDownDiv
                style={{
                  width: isSubtractMode ? '10vw' : '13vw',
                  height: '100%',
                  background: '#34383C',
                }}
                item={subColorDropdown}
                styleName="view_drop_container"
              />
            </span>
          </div>
        )}

        {CTN_Mode === false && isSubtractMode === false && (
          <div className={styles.burger}>
            <Burger {...burgerProps} />
          </div>
        )}
      </div>

      <ReactTooltip anchorSelect=".tooltip-target" place="bottom" opacity="1" />
      <Information burgerProps={null} isSubtractMode={isSubtractMode} />
    </div>
  );
};
