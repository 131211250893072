import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { info as MEDIA_info } from '6_media';
import styles from './CardLayout.module.scss';

const CardTitleDiv = ({ title }) => {
  const SBR_MESSAGE = [
    'When using atlases other than Melbourne subcortical atlas,',
    'anterior and posterior portions of putamen illustrated below display',
    'SBR of the whole putamen.',
  ];

  const CENTILOID_MESSAGE = [
    'Centiloid is calculated using whole cerebellum as a reference region.',
  ];
  if (title === 'Striatal SBR' || title === 'Regional Centiloid') {
    return (
      <div className={styles.infoWrapper}>
        <div data-tooltip-id="tooltip_card_info">
          <span>{title}</span>
          <img src={MEDIA_info} alt="info icon" />
        </div>
        <ReactTooltip id="tooltip_card_info" place="right">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {title === 'Striatal SBR' &&
              SBR_MESSAGE.map((message, idx) => (
                <span key={idx}>{message}</span>
              ))}
            {title === 'Regional Centiloid' &&
              CENTILOID_MESSAGE.map((message, idx) => (
                <span key={idx}>{message}</span>
              ))}
          </div>
        </ReactTooltip>
      </div>
    );
  }
  return title;
};

export default function CardLayout({ cards, width = '50%', height = '90%' }) {
  const [expandedComponent, setExpandedComponent] = useState('');
  const EXPENDED_HEIGHT = '100%';

  const isCollapsedCard =
    cards.length > 1 &&
    cards.some(
      ({ title }) =>
        title === 'Regional Centiloid' || title === 'Semiquantification',
    );

  return (
    <div
      className={styles['container_wrapper']}
      style={{
        width: width,
        height: height,
        paddingRight: `${cards[0].componentName === 'MainConfig' ? '0' : ''}`,
      }}
    >
      {cards.map((card, idx) => {
        const { componentName, title, Date: cardDate, content } = card;

        let cardHeight = EXPENDED_HEIGHT;
        if (isCollapsedCard && expandedComponent !== '') {
          if (expandedComponent === title) {
            cardHeight = EXPENDED_HEIGHT;
          } else {
            cardHeight = '10%';
          }
        }

        let containerClassName = 'container';
        if (
          componentName === 'CSVFormat' ||
          componentName === 'ReportFormats'
        ) {
          containerClassName =
            componentName === 'CSVFormat'
              ? 'container_setting_card2_1'
              : 'container_setting_card2_2';
        }

        return (
          <div
            key={idx}
            className={styles[`${containerClassName}`]}
            style={{ height: cardHeight }}
          >
            <div className={styles['top']}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CardTitleDiv title={title} />

                {isCollapsedCard && (
                  <span
                    style={{
                      fontSize: '1.5vw',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    className={styles.plusMinusIcon}
                    onClick={() => {
                      if (expandedComponent === title) {
                        setExpandedComponent('');
                      } else {
                        setExpandedComponent(title);
                      }
                    }}
                  >
                    {expandedComponent === ''
                      ? '+'
                      : cardHeight === EXPENDED_HEIGHT
                      ? '\u2013'
                      : '+'}
                  </span>
                )}
              </div>
              {cardDate && <div>{cardDate}</div>}
            </div>
            <div className={styles['bottom']}>{content}</div>
          </div>
        );
      })}
    </div>
  );
}
