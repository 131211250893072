import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Dropdown,
  Form,
  InputGroup,
  Pagination,
  Button,
} from 'react-bootstrap';
import {
  updateFilter,
  updatePagination,
  updateWorklistPagination,
} from '1_reduxs/reducers/filesReducer';
import styles from './BtxPagination.module.scss';
import { BiSearch } from 'react-icons/bi';

const BtxPagination = (props) => {
  const {
    totalSize = 0,
    identifier = 'upload', // upload, worklist
    filter,
    pagination,
  } = props;

  const dispatch = useDispatch();
  const searchInputRef = React.createRef();

  const handlePaginationClick = (pageNumber) => {
    if (identifier === 'upload')
      dispatch(
        updatePagination({
          ...pagination,
          currentPage: pageNumber,
        }),
      );
    else if (identifier === 'worklist')
      dispatch(
        updateWorklistPagination({
          ...pagination,
          currentPage: pageNumber,
        }),
      );
  };

  const handleViewCount = (itemPerPage) => {
    if (identifier === 'upload')
      dispatch(
        updatePagination({
          ...pagination,
          itemsPerPage: itemPerPage,
          currentPage: 1, // reset page number
        }),
      );
    else if (identifier === 'worklist')
      dispatch(
        updateWorklistPagination({
          ...pagination,
          itemsPerPage: itemPerPage,
          currentPage: 1, // reset page number
        }),
      );
  };

  const handleFilterType = (searchType) => {
    if (identifier === 'upload')
      dispatch(
        updateFilter({
          ...filter,
          searchType,
          searchKeyword: '',
        }),
      );
  };

  const handleFilterKeyword = (event) => {
    if (identifier === 'upload' && event.key === 'Enter') {
      const keyword = event.target.value;
      dispatch(
        updateFilter({
          ...filter,
          searchKeyword: keyword,
        }),
      );
    }
  };

  const handlePagination = (action) => {
    switch (action) {
      case 'first':
        handlePaginationClick(1);
        break;
      case 'prev':
        if (currentPage > 1) {
          handlePaginationClick(currentPage - 1);
        }
        break;
      case 'next':
        if (currentPage < totalPages) {
          handlePaginationClick(currentPage + 1);
        }
        break;
      case 'last':
        handlePaginationClick(totalPages);
        break;
      default:
        break;
    }
  };

  let paginationItems = [];
  const VIEW_ITEM_COUNTS = [10, 25, 50, 100];
  const FILTER_TYPES = [
    { title: 'Patient name', searchType: 'PatientName' },
    { title: 'Patient ID', searchType: 'PatientID' },
    { title: 'Scan date', searchType: 'AcquisitionDateTime' },
  ];
  const totalPages = Math.ceil(totalSize / pagination.itemsPerPage);
  const currentPage = pagination.currentPage;
  const maxPaginationItems = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxPaginationItems / 2),
  );
  const endPage = Math.min(totalPages, startPage + maxPaginationItems - 1);

  const startItemIndex = (currentPage - 1) * pagination.itemsPerPage + 1;
  const endItemIndex = Math.min(
    currentPage * pagination.itemsPerPage,
    totalSize,
  );
  const paginationMessage = `result : ${startItemIndex}-${endItemIndex} of ${totalSize} items`;

  for (let pageNumber = startPage; pageNumber <= endPage; pageNumber++) {
    paginationItems.push(
      <Pagination.Item
        key={pageNumber}
        active={pageNumber === currentPage}
        onClick={() => handlePaginationClick(pageNumber)}
      >
        {pageNumber}
      </Pagination.Item>,
    );
  }

  const getFilterTypeLabel = (value) => {
    const filterType = FILTER_TYPES.find((type) => type.searchType === value);
    return filterType ? filterType.title : '';
  };

  return (
    <div className={styles.container}>
      <Dropdown onSelect={handleViewCount}>
        <Dropdown.Toggle variant="dark" id="dropdown-pagecount">
          {pagination.itemsPerPage}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {VIEW_ITEM_COUNTS.map((item) => (
            <Dropdown.Item
              key={item}
              eventKey={item}
              active={pagination.itemsPerPage === item}
            >
              {item}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {identifier === 'upload' && (
        <InputGroup>
          <Dropdown onSelect={handleFilterType}>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-filter">
              {getFilterTypeLabel(filter.searchType)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {FILTER_TYPES.map(({ searchType }) => (
                <Dropdown.Item
                  key={searchType}
                  eventKey={searchType}
                  active={pagination.itemsPerPage === searchType}
                >
                  {getFilterTypeLabel(searchType)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Form.Control
            ref={searchInputRef}
            aria-label="Text input with dropdown button"
            placeholder='"Enter" to filter'
            defaultValue={filter.searchKeyword}
            onKeyDown={handleFilterKeyword}
          />

          <Button
            variant="primary"
            onClick={() => {
              const keyword = searchInputRef.current.value;

              dispatch(
                updateFilter({
                  ...filter,
                  searchKeyword: keyword,
                }),
              );
            }}
          >
            <BiSearch />
          </Button>
        </InputGroup>
      )}
      {totalPages > 1 && (
        <Pagination>
          <Pagination.First onClick={() => handlePagination('first')} />
          <Pagination.Prev onClick={() => handlePagination('prev')} />
          {/* <Pagination.Ellipsis /> */}

          {paginationItems}

          {/* <Pagination.Ellipsis /> */}
          <Pagination.Next onClick={() => handlePagination('next')} />
          <Pagination.Last onClick={() => handlePagination('last')} />
        </Pagination>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <span>{paginationMessage}</span>
      </div>
    </div>
  );
};

export default BtxPagination;
