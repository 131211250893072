import {
  COLOR_MAPS,
  DEFAULT_WINDOW_MAX,
  DEFAULT_WINDOW_MIN,
  VIEWER_TYPE,
} from '0_variables/btxConstants';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clipConfigStatus: false,
  clipConfigSpeed: 20,
  opacity: 0.6,
  showCrosshair: true,
  showNormalized: true,
  showInverted: false,

  showAxial: true,
  showCoronal: true,
  showSagittal: true,

  // for subtraction mode
  layoutPivot: false,
  syncCrosshair: true,
  syncWindowMinMax: false,

  [VIEWER_TYPE.BASE]: {
    colorMap: COLOR_MAPS.JET,
    window_max_out: DEFAULT_WINDOW_MAX,
    window_min_out: DEFAULT_WINDOW_MIN,
    window_max_in: DEFAULT_WINDOW_MAX,
    window_min_in: DEFAULT_WINDOW_MIN,
  },
  [VIEWER_TYPE.SUBTRACTION_TARGET]: {
    colorMap: COLOR_MAPS.JET,
    window_max_out: DEFAULT_WINDOW_MAX,
    window_min_out: DEFAULT_WINDOW_MIN,
    window_max_in: DEFAULT_WINDOW_MAX,
    window_min_in: DEFAULT_WINDOW_MIN,
  },
  [VIEWER_TYPE.SUBTRACTION_RESULT]: {
    colorMap: COLOR_MAPS.CUSTOM_HOT_METAL_BLUE,
    window_max_out: DEFAULT_WINDOW_MAX,
    window_min_out: DEFAULT_WINDOW_MIN,
    window_max_in: DEFAULT_WINDOW_MAX,
    window_min_in: DEFAULT_WINDOW_MIN,

    cutoff: {
      leftPos: 0,
      rightPos: 0,
    },
  },
};

const viewOptionsSlice = createSlice({
  name: 'viewOptions',
  initialState,
  reducers: {
    resetOptions: () => initialState,
    updateColorMap: (state, action) => {
      const { viewerType, updateValue } = action.payload;
      state[viewerType].colorMap = updateValue;
    },
    updateViewOption: (state, action) => {
      const { optionName, updateValue } = action.payload;
      state[optionName] = updateValue;

      if (optionName === 'syncWindowMinMax') {
        // NOTE: syncWindowMinMax 가 true 일 경우  base와 target 동기화
        if (updateValue) {
          state[VIEWER_TYPE.SUBTRACTION_TARGET] = state[VIEWER_TYPE.BASE];
        }
      }
    },
    updateViewOptions: (state, action) => {
      const updateList = action.payload;

      updateList.forEach((update) => {
        const { optionName, updateValue } = update;
        state[optionName] = updateValue;
      });
    },
    updateWindowMinMax: (state, action) => {
      const { viewerType, windowMin, windowMax } = action.payload;
      const roundedMin = Math.round(windowMin);
      const roundedMax = Math.round(windowMax);

      const { window_min_out, window_max_out, window_min_in, window_max_in } =
        state[viewerType];

      const syncViewTypeName = state.syncWindowMinMax
        ? viewerType === VIEWER_TYPE.SUBTRACTION_TARGET
          ? VIEWER_TYPE.BASE
          : VIEWER_TYPE.SUBTRACTION_TARGET
        : null;

      //  갱신이 너무 빈번해서 값이 변경되었을때에만 갱신하도록 수정
      if (state.showNormalized) {
        if (roundedMin !== window_min_out) {
          state[viewerType].window_min_out = roundedMin;
          if (syncViewTypeName)
            state[syncViewTypeName].window_min_out = roundedMin;
        }
        if (roundedMax !== window_max_out) {
          state[viewerType].window_max_out = roundedMax;
          if (syncViewTypeName)
            state[syncViewTypeName].window_max_out = roundedMax;
        }
      } else {
        if (roundedMin !== window_min_in) {
          state[viewerType].window_min_in = roundedMin;
          if (syncViewTypeName)
            state[syncViewTypeName].window_min_in = roundedMin;
        }
        if (roundedMax !== window_max_in) {
          state[viewerType].window_max_in = roundedMax;
          if (syncViewTypeName)
            state[syncViewTypeName].window_max_in = roundedMax;
        }
      }
    },
    updateSubtractionCutoff: (state, action) => {
      const { leftPos, rightPos } = action.payload;
      const { leftPos: prveLeftPos, rightPos: prevRightPos } =
        state[VIEWER_TYPE.SUBTRACTION_RESULT].cutoff;

      if (leftPos !== prveLeftPos) {
        state[VIEWER_TYPE.SUBTRACTION_RESULT].cutoff.leftPos = leftPos;
      }
      if (rightPos !== prevRightPos) {
        state[VIEWER_TYPE.SUBTRACTION_RESULT].cutoff.rightPos = rightPos;
      }
    },
  },
});

export const {
  resetOptions,
  updateColorMap,
  updateViewOption,
  updateViewOptions,
  updateWindowMinMax,
  updateSubtractionCutoff,
} = viewOptionsSlice.actions;

export default viewOptionsSlice.reducer;
