import React, { useState } from 'react';
import { useInterval } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { openSpinner } from '1_reduxs/reducers/modalReducer';
import { deleteFilefromPacs } from '2_services/uploadApi';
import {
  spinnerV2 as MEDIA_spinnerV2,
  spinnerV2_complete as MEDIA_spinnerV2_complete,
  spinnerV2_check as MEDIA_spinnerV2_check,
} from '6_media';
import styles from './SpinnerModal.module.scss';

export default function SpinnerModal() {
  const dispatch = useDispatch();
  const {
    target,
    length,
    message,
    percent: percentState,
  } = useSelector((state) => state.modal.spinner);
  const [secondCount, setSecondCount] = useState(0);
  useInterval(() => {
    setSecondCount(secondCount + 1);
  }, 1000);

  const PacsSpinner = () => {
    const extractItemLength = parseInt(length.seriesNum);
    const speed = parseFloat(length.speed);
    return (
      <React.Fragment>
        <span className={styles.msgText}>{message}</span>
        <span className={styles.msgText_percent}>
          {(((secondCount * speed) / extractItemLength) * 100).toFixed(0) >= 100
            ? '100%'
            : isNaN(
                (((secondCount * speed) / extractItemLength) * 100).toFixed(0),
              )
            ? 0
            : (((secondCount * speed) / extractItemLength) * 100).toFixed(0) +
              ' %'}
        </span>
      </React.Fragment>
    );
  };

  const UploadSpinner = () => {
    const extractItemLength = parseInt(length.FilesMBSize);
    const speed = parseFloat(length.speed);

    return (
      <React.Fragment>
        <span className={styles.msgText}>{message}</span>
        <span className={styles.msgText_percent}>
          {(((secondCount * speed) / extractItemLength) * 100).toFixed(0) >= 100
            ? '100%'
            : isNaN(
                (((secondCount * speed) / extractItemLength) * 100).toFixed(0),
              )
            ? 0
            : (((secondCount * speed) / extractItemLength) * 100).toFixed(0) +
              ' %'}
        </span>
      </React.Fragment>
    );
  };

  const UploadProgressSpinner = () => {
    const { totalCount, currentValue, processMessage } = length;
    const isConverting = currentValue >= totalCount - 1;

    const percent = (((currentValue + 1) / totalCount) * 100).toFixed(0);
    return (
      <div className={styles.uploadingProgress}>
        <div className={styles.messageContainer}>
          <span className={styles.msgText}>{message}</span>
          <div className={styles.count}>
            {currentValue + 1} / {totalCount}
          </div>
        </div>

        <div className={styles.progressBarContainer}>
          <div
            className={`${styles.progressBarBody} ${
              isConverting && styles.isConverting
            }`}
            style={{ width: `${percent}%` }}
          ></div>
          <div className={styles.progressBarPercent}>{percent}%</div>
        </div>
        <div className={styles.progressBarText}>
          <div className={styles.message}>{processMessage}</div>
        </div>
      </div>
    );
  };

  const SimpleProgressSpinner = () => {
    return (
      <div className={styles.uploadingProgress}>
        <div className={styles.messageContainer}>
          <span className={styles.msgText}>loading...</span>
        </div>

        <div className={styles.progressBarContainer}>
          <div
            className={`${styles.progressBarBody} ${styles.isConverting}`}
            style={{ width: `100%` }}
          ></div>
        </div>
        <div className={styles.progressBarText}>
          <div className={styles.message}>{message}</div>
        </div>
      </div>
    );
  };

  const PdfJpgSpinner = () => {
    return (
      <React.Fragment>
        <span className={styles.msgText}>{message}</span>
        <span className={styles.msgText_percent}>{percentState + ' %'}</span>
        {percentState === 100 && (
          <span className={styles.msgText_count}>Please wait a moment...</span>
        )}
      </React.Fragment>
    );
  };

  const CompleteSpinner = () => {
    return (
      <React.Fragment>
        <span className={styles.msgText_complete}>{message}</span>
        <span className={styles.msgText_check}>
          <img src={MEDIA_spinnerV2_check} alt="spinnerImage" />
        </span>
      </React.Fragment>
    );
  };

  const getSpinnerComponent = (target) => {
    switch (target) {
      case 'PACS':
        return <PacsSpinner />;
      case 'UPLOAD':
        return <UploadSpinner />;
      case 'UPLOAD_PROGRESS_BAR':
        return <UploadProgressSpinner />;
      case 'PDF':
      case 'JPEG':
        return <PdfJpgSpinner />;
      case 'COMPLETE':
        return <CompleteSpinner />;
      case 'SIMPLE_PROGRESS_BAR':
        return <SimpleProgressSpinner />;
      default:
        return (
          <span className={styles.msgText_report}>
            {message === 0 ? '' : message}
          </span>
        );
    }
  };

  return (
    <div
      className={`${styles.container}`}
      id={`spinner-popup`}
      role="dialog"
      aria-label="spinner popup"
    >
      <span
        className={styles.exitButton}
        onClick={async () => {
          if (window.confirm('Are you sure to close this process?')) {
            const token = sessionStorage.getItem('token');
            dispatch(
              openSpinner({
                status: false,
                length: 0,
                message: '',
                target: '',
              }),
            );
            await deleteFilefromPacs({ token: token });
          }
        }}
      >
        {'X'}
      </span>
      <div className={styles.spinnerImg}>
        <img
          src={
            target === 'COMPLETE' ? MEDIA_spinnerV2_complete : MEDIA_spinnerV2
          }
          style={{ width: '10vw' }}
          alt="spinnerV2"
        />
      </div>
      {getSpinnerComponent(target)}
    </div>
  );
}
