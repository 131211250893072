import React from 'react';
import styles from './PageLayout.module.scss';

export default function PageLayout(props) {
  return (
    <div className={`${styles['container']}`}>
      <div className={`${styles['top']}`}>{props.topContent()}</div>

      <div className={`${styles['bottom']}`}>{props.bottomContent()}</div>
    </div>
  );
}
