import React, { useCallback, useRef, useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { coreItem } from '0_variables/coreItem';
import {
  calculatePixelInfo,
  clamp,
  updateSliceIndexForFile,
} from '0_variables/utils';
import { updateSliceIndex } from '1_reduxs/reducers/controllerReducer';
import { updateWindowMinMax } from '1_reduxs/reducers/viewOptionReducer';
import TextDisplay from '../TextDisplay/TextDisplay';
import './StackViewer.scss';

import * as cornerstone from 'cornerstone-core';
import * as cornerstoneMath from 'cornerstone-math';
import * as cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

const DirectionIndicators = (props) => {
  const {
    targetElement,
    fileID,
    direction,
    productName,
    fileRFSubset,
    positionFn,
    isHideSUVRValue,
    viewerType,
  } = props;

  const settingOfProduct = useSelector((state) => state.setting[productName]);
  const showNormalized = useSelector(
    (state) => state.viewOption.showNormalized,
  );
  const openFile = useSelector(
    (state) => state.control[productName].openedFiles[fileID],
  );

  if (!openFile) return null;

  const fileControlData = openFile[viewerType];

  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const tracerName = fileRFSubset['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName];

  const pixelInfo = calculatePixelInfo({
    selectedFile: fileRFSubset,
    productName,
    refName,
    CTN_Mode,
    showNormalized,
  });

  try {
    const enabledElement = cornerstone.getEnabledElement(targetElement);
    const viewport = enabledElement.viewport;

    if (viewport === undefined) {
      throw new Error('viewport is undefined');
    }
  } catch (e) {
    console.info('Dir Indicators: viewport is not ready');
    return null;
  }

  if (!fileControlData) return null;

  const zPosition = fileControlData[`${direction}`];

  const x = positionFn.getX(fileControlData);
  const y = positionFn.getY(fileControlData);

  const getSuvrValue = (pixelInfo) => {
    const pixel = cornerstone.getPixels(targetElement, x, y, 1, 1);

    return ((pixel / pixelInfo.regularizedMax) * pixelInfo.width).toFixed(2);
  };

  const suvrValue = isHideSUVRValue ? 0 : getSuvrValue(pixelInfo);

  const valueTitle = {
    amyloid: CTN_Mode ? 'Raw intensity' : 'SUVR',
    dat: 'SBR',
    fdg: 'SUVR',
    tau: 'SUVR',
    perfusion: 'SUVR',
  };
  const rePos = {
    coronal: {
      title: CTN_Mode ? 'Voxel Position' : 'MNI y',
      a: CTN_Mode ? 1 : 2,
      b: CTN_Mode ? 0 : -150,
    },
    sagittal: {
      title: CTN_Mode ? 'Voxel Position' : 'MNI x',
      a: CTN_Mode ? 1 : -2,
      b: CTN_Mode ? 0 : +130,
    },
    axial: {
      title: CTN_Mode ? 'Voxel Position' : 'MNI z',
      a: CTN_Mode ? 1 : 2,
      b: CTN_Mode ? 0 : -74,
    },
  };

  const direction_indicator = {
    coronal: { top: 'S', bottom: 'I', left: 'R', right: 'L' },
    sagittal: { top: 'S', bottom: 'I', left: 'P', right: 'A' },
    axial: { top: 'A', bottom: 'P', left: 'R', right: 'L' },
  };

  const mniPos = rePos[direction]?.a * zPosition + rePos[direction]?.b;

  return (
    <>
      {!isHideSUVRValue && (
        <TextDisplay
          title={valueTitle[productName]}
          value={CTN_Mode ? Math.floor(suvrValue) : suvrValue}
          name={'SUVR'}
          style={{
            bottom: '28px',
            left: '20px',
          }}
        />
      )}
      <TextDisplay
        title={rePos[direction]?.title}
        value={mniPos}
        name={'MNI'}
        style={{
          bottom: '10px',
          left: '20px',
        }}
      />
      <TextDisplay
        title={direction_indicator[direction]?.top}
        value={mniPos}
        name={'direction'}
        style={{
          top: '10px',
          left: '48.5%',
        }}
      />
      <TextDisplay
        title={direction_indicator[direction]?.bottom}
        value={mniPos}
        name={'direction'}
        style={{
          bottom: '10px',
          left: '48.5%',
        }}
      />
      <TextDisplay
        title={direction_indicator[direction]?.left}
        value={mniPos}
        name={'direction'}
        style={{
          top: '50%',
          left: '10px',
        }}
      />
      <TextDisplay
        title={direction_indicator[direction]?.right}
        value={mniPos}
        name={'direction'}
        style={{
          top: '50%',
          right: '10px',
        }}
      />
    </>
  );
};

const StackViewer = (props) => {
  const {
    selectedFileId,
    Stacks: initialStacks,
    type, // 'mip', 'slice'
    synchronizerProps,
    fileRFSubset,
    direction,
    productName,
    width,
    height,
    isHideSUVRValue,
    isSubViewer,
    positionFn,
    viewerType,
  } = props;

  const dispatch = useDispatch();

  // viewoptions
  const {
    showNormalized,
    opacity,
    showInverted,
    clipConfigStatus,
    clipConfigSpeed,
    showCrosshair,
    syncCrosshair,
  } = useSelector((state) => state.viewOption);

  // window min max, colormap
  const {
    colorMap,
    window_max_out,
    window_min_out,
    window_max_in,
    window_min_in,
  } = useSelector((state) => state.viewOption[viewerType]);

  const viewerRef = useRef(null); // cornerstone element ref
  const sliceStackTypeRef = useRef(null);
  const fileIdRef = useRef(null);

  const [isEnabledCornerston, setIsEnabledCornerston] = useState(false);
  // NOTE 컴포넌트가 마운트될 때 실행될 로직
  const [viewOptionForMounted, setViewOptionForMounted] = useState(null);
  const [isRefLineInit, setIsRefLineInit] = useState(false);

  const { sliceIndexSync } = synchronizerProps;

  const setWindowMinMax = useCallback(
    (updateWMin, updateWMax) => {
      dispatch(
        updateWindowMinMax({
          viewerType,
          windowMin: updateWMin,
          windowMax: updateWMax,
        }),
      );
    },
    [dispatch, viewerType],
  );

  const setSliceID = useCallback(
    (updatedSliceId, direction, eventDetail) => {
      const updateDefaultSliceId = debounce((index, direction) => {
        updateSliceIndexForFile(selectedFileId, direction, index);
      }, 200);

      dispatch(
        updateSliceIndex({
          productName,
          fileID: selectedFileId,
          viewerType,
          direction, // 'sagittal', 'axial', 'coronal'
          sliceIndex: updatedSliceId,
        }),
      );

      updateDefaultSliceId(updatedSliceId, direction);

      // mni image update
      if (type === 'slice') {
        const enabledElement = eventDetail.enabledElement;
        const element = eventDetail.element;

        const layers = enabledElement.layers;

        const mniStackLayer = layers.find((layer) =>
          layer.image.imageId.includes('mni'),
        );
        const mniStack = initialStacks.find((stack) => stack.isMNIStack);

        if (mniStack && mniStackLayer) {
          const imageId = mniStack.imageIds[updatedSliceId];

          cornerstone.loadAndCacheImage(imageId).then((image) => {
            cornerstone.setLayerImage(element, image, mniStackLayer.layerId);
            cornerstone.updateImage(element);
          });
        }
      }
    },
    [dispatch, productName, selectedFileId, viewerType, type, initialStacks],
  );

  const getTargetStack = useCallback((stacks, showNormalized) => {
    // NOTE : for triple viewer
    if (
      stacks.length === 2 &&
      stacks[0].stackType === 'MNI_STACK' &&
      stacks[1].stackType.includes('SLICE_STACK_')
    ) {
      return stacks.at(-1);
    }

    const isVisibleStack = (stack, showNormalized) => {
      const matchedType = showNormalized ? 'output' : 'input';
      return stack.stackType.includes(matchedType);
    };

    return stacks.find((stack) => isVisibleStack(stack, showNormalized));
  }, []);

  const loadImages = useCallback((visibleStacks) => {
    const promises = [];
    visibleStacks.forEach((stack) => {
      const currentImageIdIndex = stack.currentImageIdIndex;

      const loadPromise = cornerstone.loadAndCacheImage(
        stack.imageIds[currentImageIdIndex],
      );
      promises.push(loadPromise);
    });

    return Promise.all(promises);
  }, []);

  const cornerstoneInitial = useCallback(
    (targetElement, activeStack, type, wwwcsynchronizer) => {
      if (type === 'mip') {
        cornerstoneTools.addStackStateManager(targetElement, [
          'stack',
          'playClip',
        ]);
      } else if (type === 'slice') {
        cornerstoneTools.addStackStateManager(targetElement, [
          'stack',
          'referenceLines',
          'crosshairs',
        ]);

        // if (viewerType !== VIEWER_TYPE.SUBTRACTION_RESULT)
        cornerstoneTools.wwwcRegion.activate(targetElement, 4); // 4 is right mouse button
        // cornerstoneTools.wwwcRegion.setConfiguration({
        //   minWindowWidth: 10, // cornerstonetools default value
        //   shadow: true,
        //   shadowColor: '#fe2faf',
        //   shadowOffsetX: 2,
        //   shadowOffsetY: 2,
        // });
      }

      cornerstoneTools.addToolState(targetElement, 'stack', activeStack);

      const layers = cornerstone.getLayers(targetElement);
      cornerstone.setActiveLayer(targetElement, layers.at(-1).layerId);
      cornerstone.setDefaultViewport(layers.at(-1).viewport);

      cornerstoneTools.stackScrollWheel.activate(targetElement);
      wwwcsynchronizer.add(targetElement);

      // NOTE stackScroll이 동작 안하고 있음, 주석처리
      // cornerstoneTools.stackScroll.activate(targetElement, 1);

      // NOTE wwwcRegion사용하기위해 주석처리
      // cornerstoneTools.wwwc.activate(targetElement, 4);

      // NOTE viewer에서는 drag probe를 사용하지 않음
      // cornerstoneTools.dragProbe.disable(targetElement);
    },
    [],
  );

  // NOTE for mip viewer
  const playClipInitial = useCallback(
    (element, clipConfigStatus, clipConfigSpeed) => {
      if (clipConfigStatus) {
        cornerstoneTools.playClip(element, clipConfigSpeed);
      } else {
        cornerstoneTools.stopClip(element);
      }
    },
    [],
  );

  const crosshairInitial = useCallback(
    (element, isSubViewer, referenceLinesync, showCrosshair) => {
      if (isSubViewer) {
        // NOTE for subtraction mode
        referenceLinesync.addTarget(element);
      } else {
        referenceLinesync.add(element);
      }

      cornerstoneTools.crosshairs.enable(element, 1, referenceLinesync);
      cornerstoneTools.referenceLines.tool.enable(element, referenceLinesync);

      // cornerstoneTools.toolStyle.setToolWidth(2);
      // cornerstoneTools.toolColors.setActiveColor('#0099ff');

      if (!showCrosshair) {
        cornerstoneTools.referenceLines.tool.disable(element);
      }
      setIsRefLineInit(true);
    },
    [],
  );

  const initToolState = useCallback((activeStack) => {
    const element = viewerRef.current;

    try {
      cornerstone.getEnabledElement(element);
    } catch (e) {
      // NOTE cornerstorn enable element 가 없을때 에러 발생함 무시 이미 제거된 상태
      console.log('cornerstone Error: element not enable');
      return;
    }
    // NOTE toolstate initial할때 mip viewer에서는 playclip을 멈춰야함
    cornerstoneTools.stopClip(element);
    cornerstoneTools.clearToolState(element, 'stack');

    cornerstoneTools.addToolState(element, 'stack', activeStack);
  }, []);

  const viewportUpdate = useCallback(
    (element) => {
      const windowMin = showNormalized ? window_min_out : window_min_in;
      const windowMax = showNormalized ? window_max_out : window_max_in;

      // const elabledElement = cornerstone.getEnabledElement(element);
      const activeLayer = cornerstone.getActiveLayer(element);

      if (!activeLayer) return;

      const activeImageId = activeLayer.image.imageId;
      if (activeImageId.includes('mni') === false) {
        // acitve layer update
        activeLayer.options.opacity = type === 'mip' ? 1 : opacity;

        const viewport = cornerstone.getViewport(element);
        viewport.colormap = colorMap; // NOTE: colormap update

        viewport.invert = showInverted; // NOTE: invert update
        viewport.voi.windowWidth = clamp(windowMax - windowMin, 0, 32767);
        viewport.voi.windowCenter = clamp(
          (windowMax + windowMin) / 2,
          0,
          32767,
        );

        cornerstone.setViewport(element, viewport);
      }
    },
    [
      opacity,
      showInverted,
      showNormalized,
      type,
      window_max_in,
      window_max_out,
      window_min_in,
      window_min_out,
      colorMap,
    ],
  );

  useEffect(() => {
    if (viewOptionForMounted === null || fileIdRef.current !== selectedFileId) {
      fileIdRef.current = selectedFileId;

      setViewOptionForMounted({
        showNormalized,
        opacity,
        colorMap,
        showInverted,
        clipConfigStatus,
        clipConfigSpeed,
        showCrosshair,
        syncCrosshair,
        window_max_out,
        window_min_out,
        window_max_in,
        window_min_in,
      });
    }
  }, [
    selectedFileId,
    viewOptionForMounted,
    showNormalized,
    opacity,
    colorMap,
    showInverted,
    clipConfigStatus,
    clipConfigSpeed,
    showCrosshair,
    syncCrosshair,
    window_max_out,
    window_min_out,
    window_max_in,
    window_min_in,
  ]);

  // NOTE : corenstone 초기화
  useEffect(() => {
    const element = viewerRef.current;

    cornerstone.enable(element);
    cornerstoneTools.mouseInput.enable(element);
    cornerstoneTools.mouseWheelInput.enable(element);

    // NOTE not use keyboard input
    // cornerstoneTools.keyboardInput.enable(element);

    setIsEnabledCornerston(true);

    return () => {
      setIsEnabledCornerston(false);

      cornerstoneTools.stopClip(element);
      cornerstoneTools.clearToolState(element, 'stack');
      cornerstoneTools.clearToolState(element, 'playClip');
      cornerstoneTools.clearToolState(element, 'referenceLines');
      cornerstoneTools.clearToolState(element, 'crosshairs');
      cornerstone.purgeLayers(element);
      cornerstone.disable(element);
    };
  }, []);

  useEffect(() => {
    const element = viewerRef.current;

    const onImageRenderer = debounce((e) => {
      const eventData = e.detail;

      try {
        const { windowWidth, windowCenter } = eventData.viewport.voi;

        const updateWMax = clamp(windowCenter + windowWidth / 2, 0, 32767);
        const updateWMin = clamp(windowCenter - windowWidth / 2, 0, 32767);
        setWindowMinMax(updateWMin, updateWMax);
      } catch (e) {
        console.warn('onImageRendered error');
      }
    }, 10);

    const onNewImage = (e) => {
      try {
        const eventDetail = e.detail;
        const currentImage = eventDetail.image;
        const sliceIdByImage = currentImage.imageId.split('/').at(-1);

        setSliceID(Number(sliceIdByImage), direction, eventDetail);
      } catch (e) {
        console.warn('onNewImageHandle  error');
      }
    };

    const onContextMenu = (e) => {
      const isView = window.location.href.split('/')[4] === 'view';
      if (isView) e.preventDefault();
    };

    element.addEventListener('cornerstoneimagerendered', onImageRenderer);
    element.addEventListener('cornerstonenewimage', onNewImage);
    window.addEventListener('contextmenu', onContextMenu, false);

    return () => {
      element.removeEventListener('cornerstoneimagerendered', onImageRenderer);
      element.removeEventListener('cornerstonenewimage', onNewImage);
      window.removeEventListener('contextmenu', onContextMenu, false);
    };
  }, [setWindowMinMax, setSliceID, direction]);

  useEffect(() => {
    if (!isEnabledCornerston) return;
    if (viewOptionForMounted === null) return;

    const getVisibleStacks = (stacks, showNormalized) => {
      // NOTE : for triple viewer
      if (
        stacks.length === 2 &&
        stacks[0].stackType === 'MNI_STACK' &&
        stacks[1].stackType.includes('SLICE_STACK_')
      ) {
        return stacks;
      }

      // NOTE : for single viewer
      const isVisibleStack = (stack, showNormalized) => {
        const matchedType = showNormalized ? 'output' : 'input';
        return stack.stackType.includes(matchedType) || stack.isMNIStack;
      };

      return stacks.filter((stack) => isVisibleStack(stack, showNormalized));
    };

    const element = viewerRef.current;

    const {
      showNormalized,
      opacity,
      colorMap,
      showInverted,
      clipConfigStatus,
      clipConfigSpeed,
      showCrosshair,
      window_max_out,
      window_min_out,
      window_max_in,
      window_min_in,
    } = viewOptionForMounted;

    const windowMin = showNormalized ? window_min_out : window_min_in;
    const windowMax = showNormalized ? window_max_out : window_max_in;
    const visibleStacks = getVisibleStacks(initialStacks, showNormalized);
    const { referenceLinesync, wwwcsynchronizer } = synchronizerProps;
    const activeStack = visibleStacks.at(-1);

    const initialLoadImage = async (stacks) => {
      const images = await loadImages(stacks);

      // init layer all purge
      cornerstone.purgeLayers(element);

      images.forEach((image, index) => {
        const { isMNIStack } = stacks[index];
        const currentStackType = stacks[index].stackType;

        const updateColorMap = colorMap;

        const sliceLayerOptions = {
          viewport: {
            voi: {
              windowWidth: clamp(windowMax - windowMin, 0, 32767),
              windowCenter: clamp((windowMax + windowMin) / 2, 0, 32767),
            },
            colormap: updateColorMap,
            invert: showInverted,
          },
          opacity: type === 'mip' ? 1 : opacity,
        };

        cornerstone.addLayer(
          element,
          image,
          isMNIStack ? null : sliceLayerOptions,
        );

        if (isMNIStack === false) {
          sliceStackTypeRef.current = currentStackType;
        }

        cornerstone.updateImage(element);
      });

      cornerstoneInitial(element, activeStack, type, wwwcsynchronizer);

      if (type === 'mip')
        playClipInitial(element, clipConfigStatus, clipConfigSpeed);
      if (type === 'slice')
        crosshairInitial(
          element,
          isSubViewer,
          referenceLinesync,
          showCrosshair,
        );
      initToolState(activeStack);
    };

    initialLoadImage(visibleStacks).catch((error) => {
      console.info(
        'initialLoadImage failure: Normal when switching or closing tabs.',
      );
    });

    return () => {
      if (isEnabledCornerston) {
        wwwcsynchronizer.remove(element);
        referenceLinesync.remove(element);
        setIsRefLineInit(false);
      }
    };
  }, [
    initialStacks,
    viewOptionForMounted,
    type,
    viewerType,
    synchronizerProps,
    isSubViewer,
    cornerstoneInitial,
    crosshairInitial,
    initToolState,
    loadImages,
    playClipInitial,
    isEnabledCornerston,
  ]);

  // NOTE layout change
  useEffect(() => {
    // NOTE unmount stackviewer
    if (!isEnabledCornerston) return;

    if (sliceStackTypeRef.current === null) {
      return;
    }

    const element = viewerRef.current;
    const activeLayer = cornerstone.getActiveLayer(element);

    if (!activeLayer) return;

    const activeImageId = activeLayer.image.imageId;
    const currentSliceId = activeImageId.split('/').at(-1);

    const targetStack = getTargetStack(initialStacks, showNormalized);

    if (sliceStackTypeRef.current !== targetStack.stackType) {
      // NOTE : slice stack이 변경됨 slice layer 제거 후 다시 추가

      cornerstone.removeLayer(element, activeLayer.layerId);

      const updateSliceStack = {
        ...targetStack,
        currentImageIdIndex: Number(currentSliceId),
      };

      initToolState(updateSliceStack);

      loadImages([updateSliceStack]).then((images) => {
        const layerIds = images.map((image) => {
          const layerId = cornerstone.addLayer(element, image);
          return layerId;
        });

        cornerstone.updateImage(element);
        cornerstone.setActiveLayer(element, layerIds.at(-1));

        sliceStackTypeRef.current = targetStack.stackType;

        viewportUpdate(element);
      });
    }
  }, [
    viewportUpdate,
    getTargetStack,
    initToolState,
    initialStacks,
    loadImages,
    showNormalized,
    isEnabledCornerston,
  ]);

  useEffect(() => {
    const element = viewerRef.current;

    viewportUpdate(element);
  }, [viewportUpdate]);

  useEffect(() => {
    if (viewOptionForMounted === null) return;

    if (type === 'mip')
      playClipInitial(viewerRef.current, clipConfigStatus, clipConfigSpeed);
  }, [
    playClipInitial,
    clipConfigStatus,
    clipConfigSpeed,
    type,
    viewOptionForMounted,
  ]);

  useEffect(() => {
    if (!isEnabledCornerston) return;

    if (type === 'slice' && isRefLineInit) {
      const element = viewerRef.current;

      try {
        cornerstone.getEnabledElement(element);
        if (showCrosshair) {
          cornerstoneTools.referenceLines.tool.enable(element);
        } else {
          cornerstoneTools.referenceLines.tool.disable(element);
        }
      } catch (e) {
        // NOTE cornerstorn enable element 가 없을때 에러 발생함 무시 이미 제거된 상태
        console.info('cornerstone Error: element not enable');
        return;
      }
    }
  }, [
    isEnabledCornerston,
    isRefLineInit,
    showCrosshair,
    type,
    viewOptionForMounted,
  ]);

  useEffect(() => {
    if (!isEnabledCornerston) return;

    const element = viewerRef.current;

    if (syncCrosshair) {
      sliceIndexSync && sliceIndexSync[direction].add(element);
    } else {
      sliceIndexSync && sliceIndexSync[direction].remove(element);
    }

    return () => {
      sliceIndexSync && sliceIndexSync[direction].remove(element);
    };
  }, [syncCrosshair, direction, sliceIndexSync, isEnabledCornerston]);

  return (
    <div
      className={`${direction} viewerBody`}
      ref={viewerRef}
      style={{
        width: width,
        height: height,
      }}
    >
      {type === 'slice' && isEnabledCornerston && (
        <DirectionIndicators
          targetElement={viewerRef.current}
          fileID={selectedFileId}
          direction={direction}
          productName={productName}
          fileRFSubset={fileRFSubset}
          positionFn={positionFn}
          isHideSUVRValue={isHideSUVRValue}
          viewerType={viewerType}
        />
      )}
    </div>
  );
};

export default StackViewer;
