import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { clamp } from 'lodash';
import { REFERNECE_STEP_MAP } from '0_variables/analysisConfigs';
import styles from './BrainTable.module.scss';
import { BrainLobeWrapper } from './BrainLobeComponent';

function BrainTableRow({ item, colorMap, refStep, borderEnable }) {
  const { lobe: lobeType, Lval, Rval } = item;
  const { product: productName } = useParams();

  const [labelMin, labelMax] = refStep;
  const isGlobalLobe = lobeType === 'Global Lobe';

  const getColorBarRatio = (value) => {
    const denominator = labelMax - labelMin;
    const percentVal = ((value - labelMin) / denominator) * 100;
    return parseInt(clamp(percentVal, 0, 100), 10);
  };

  const leftRatio = getColorBarRatio(Lval);
  const rightRatio = getColorBarRatio(Rval);
  const totalRatio = getColorBarRatio((Lval + Rval) / 2);
  const brainRenderOptions = {
    lobeType,
    colorMap,
    lobe1: totalRatio,
    lobe2: totalRatio,
    lobe3: totalRatio,
    lobe4: totalRatio,
  };

  return (
    <tr>
      <td
        className={styles.svgImage}
        style={{ borderWidth: borderEnable ? '' : '0' }}
      >
        <div>{BrainLobeWrapper(productName, brainRenderOptions)}</div>
        <div>{lobeType}</div>
      </td>
      <td style={{ borderWidth: borderEnable ? '' : '0' }}>
        <div
          style={{
            borderColor: colorMap[leftRatio],
            width: `${leftRatio}%`,
            alignItems: `${isGlobalLobe ? 'end' : 'center'}`,
          }}
        >
          <div
            className={styles.valueText}
            style={{
              textAlign: `${leftRatio < 90 ? 'left' : 'right'}`,
              transform: `${
                leftRatio < 90 ? 'translateX(80px)' : 'translateX(-20px)'
              }`,
            }}
          >
            <div style={{ opacity: '30%' }}>
              {isGlobalLobe ? 'Bilat.' : 'L'}
            </div>
            <div
              style={{
                display: `${isGlobalLobe ? 'none' : ''}`,
              }}
            >
              {Lval.toFixed(2)}
            </div>
          </div>
        </div>
        <div
          style={{
            borderColor: colorMap[rightRatio],
            width: `${rightRatio}%`,
            alignItems: `${isGlobalLobe ? 'start' : 'center'}`,
          }}
        >
          <div
            className={styles.valueText}
            style={{
              textAlign: `${rightRatio < 90 ? 'left' : 'right'}`,
              transform: `${
                rightRatio < 90 ? 'translateX(80px)' : 'translateX(-20px)'
              }`,
            }}
          >
            <div
              style={{
                opacity: '30%',
                display: `${isGlobalLobe ? 'none' : ''}`,
              }}
            >
              R
            </div>
            <div>{Rval.toFixed(2)}</div>
          </div>
        </div>
      </td>
    </tr>
  );
}

function BrainTableLegend({ colorMap, refStep, legend }) {
  const [labelMin, labelMax, labelCount] = refStep;
  const step = (labelMax - labelMin) / (labelCount - 1);
  const colorGradient = colorMap.join(',').toString();

  return (
    <tr>
      <td>{legend}</td>
      <td>
        <div className={styles.divForPadding}>
          <div
            style={{
              background: `linear-gradient(to right, ${colorGradient}`,
            }}
            className={styles.gradeintBar}
          ></div>
        </div>

        <div className={styles.labels}>
          {Array(labelCount)
            .fill(0)
            .map((_, idx) => {
              return (
                <span key={idx}>{(step * idx + labelMin).toFixed(1)}</span>
              );
            })}
        </div>
      </td>
    </tr>
  );
}

export default function BrainTable({
  borderEnable = true,
  legend,
  colorcode: colorMap,
  items,
  refName,
}) {
  const { product: productName } = useParams();
  const [refStep, setRefStep] = useState(REFERNECE_STEP_MAP['wcrbll']);

  useEffect(() => {
    if (productName === 'dat') {
      setRefStep(REFERNECE_STEP_MAP['Occipital']);
    } else {
      if (refName === 'Whole cerebellum') {
        if (productName === 'perfusion') {
          setRefStep(REFERNECE_STEP_MAP['wcrbll_spect']);
        } else {
          setRefStep(REFERNECE_STEP_MAP['wcrbll']);
        }
      } else if (refName === 'Pons') {
        setRefStep(REFERNECE_STEP_MAP['pons']);
      } else if (refName === 'Cerebellar gray matter') {
        if (productName === 'perfusion') {
          setRefStep(REFERNECE_STEP_MAP['crbllGM_spect']);
        } else {
          setRefStep(REFERNECE_STEP_MAP['crbllGM']);
        }
      } else if (refName === 'Cerebral white matter') {
        setRefStep(REFERNECE_STEP_MAP['cerebralWM']);
      } else if (refName === 'Occipital cortex') {
        setRefStep(REFERNECE_STEP_MAP['Occipital']);
      } else if (refName === 'Zscore') {
        setRefStep(REFERNECE_STEP_MAP['Zscore']);
      }
    }
  }, [productName, refName]);

  return (
    <table className={`brain_table ${styles.table}`}>
      <tbody className={styles.tbody}>
        {items.map((item, idx) => (
          <BrainTableRow
            key={`brainTable-${idx}`}
            item={item}
            colorMap={colorMap}
            refStep={refStep}
            borderEnable={borderEnable}
          />
        ))}

        <BrainTableLegend
          colorMap={colorMap}
          refStep={refStep}
          legend={legend}
        />
      </tbody>
    </table>
  );
}
