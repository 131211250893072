import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FaFilePdf, FaUpload } from 'react-icons/fa';
import {
  openSpinner,
  openReportPacsModal,
} from '1_reduxs/reducers/modalReducer';
import { selectFileById } from '1_reduxs/reducers/filesReducer';
import { ReportPages, ReportPacsModal } from '5_components';
import styles from './ReportContainer.module.scss';
import { exportPDF } from './makePDF';

const ReportContainer = () => {
  const myRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { product: productName, fileID: paramFileID } = useParams();
  const modalState = useSelector((state) => state.modal);
  const setting = useSelector((state) => state.setting);
  const selectedFileID = useSelector((state) => state.control.selectedFileID);
  const selectedFile = useSelector((state) =>
    selectFileById(state, paramFileID),
  );
  const settingOfProduct = setting[productName];

  useEffect(() => {
    dispatch(openReportPacsModal({ isOn: false }));
  }, [dispatch]);

  const spinnerDispatch = ({ status = true, message, percent, target }) => {
    dispatch(
      openSpinner({
        status,
        percent,
        message,
        target,
      }),
    );
  };

  useEffect(() => {
    if (paramFileID !== selectedFileID) {
      if (!selectedFileID) {
        history.push(`/${productName}/upload`);
        return;
      }

      history.push(`/${productName}/report/${selectedFileID}`);
      return;
    }

    // scroll to top
    myRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [history, productName, paramFileID, selectedFileID]);

  return (
    <div className={styles.container} ref={myRef}>
      {modalState.reportPacsModal.status && <ReportPacsModal />}
      <div className={styles.buttons}>
        <div
          onClick={() => {
            exportPDF({
              spinnerDispatch,
              fileList: [selectedFile],
              productName,
              settingOfProduct,
              isUploadToPacs: true,
            });
            dispatch(openReportPacsModal({ isOn: true }));
          }}
          role="button"
        >
          <FaUpload size={'0.8vw'} />
          &nbsp;PACS
        </div>
        <div
          onClick={() => {
            exportPDF({
              spinnerDispatch,
              fileList: [selectedFile],
              productName,
              settingOfProduct,
            });
          }}
          role="button"
        >
          <FaFilePdf size={'0.8vw'} />
          &nbsp;PDF
        </div>
      </div>
      <div
        className={`a4-pages-container_${paramFileID} ${styles.reportContainer}`}
      >
        <ReportPages fileID={paramFileID} isReportWrapper={false} />
      </div>
    </div>
  );
};
export default ReportContainer;
