export const VIEWER_TYPE = {
  BASE: 'base',
  SUBTRACTION_TARGET: 'subtractionTarget',
  SUBTRACTION_RESULT: 'subtractionResult',
};

export const DEFAULT_WINDOW_MAX = 32767;
export const DEFAULT_WINDOW_MIN = 0;

export const COLOR_MAPS = {
  INVERT_GRAY: 'invertGray',
  HOT: 'hot',
  JET: 'jet',
  PET: 'pet',
  // NOTE for subtraction result
  CUSTOM_HOT_METAL_BLUE: 'custom_hotMetalBlue',
  CUSTOM_COOL_WARM: 'custom_coolwarm',
  CUSTOM_JET: 'custom_jet',
};
