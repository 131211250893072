import { createSlice } from '@reduxjs/toolkit';
import { coreItem } from '0_variables/coreItem';
import { subRegions } from '0_variables/subRegions';

const getCoreSettings = () => {
  return Object.keys(coreItem).reduce((obj, productName) => {
    obj[productName] = {
      defaultAtlas: coreItem[productName].defaultAtlas,
      defaultRef: coreItem[productName].defaultRef,
      defaultTracer: coreItem[productName].defaultTracer,
      defaultPInfoState: coreItem[productName].defaultPInfoState,
      defaultCSVFormat: coreItem[productName].defaultCSVFormat,
      imageVerticalFlip: coreItem[productName].imageVerticalFlip,
      list: subRegions[productName].map((el) => ({ ...el, isFolded: false })),
    };
    return obj;
  }, {});
};

const defaultSettings = getCoreSettings();

const initialState = {
  changed: false,
  ...defaultSettings,
};

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    getSettingFromServer: (state, action) => {
      const serverSettings = action.payload.customROI;

      return {
        changed: false,
        ...Object.keys(defaultSettings).reduce((obj, productName) => {
          obj[productName] = {
            ...defaultSettings[productName],
            ...serverSettings[productName],
          };
          return obj;
        }, {}),
      };
    },
    resetSetting: (state, action) => {
      const { productName } = action.payload;

      state.changed = false;
      state[productName] = { ...defaultSettings[productName] };
    },
    saveToSettingDone: (state) => {
      state.changed = false;
    },
    changeSettings: (state, action) => {
      const { productName, updatedSettings } = action.payload;

      state.changed = true;
      state[productName] = updatedSettings;
    },
  },
});

export const {
  getSettingFromServer,
  resetSetting,
  saveToSettingDone,
  changeSettings,
} = settingSlice.actions;

export default settingSlice.reducer;
