import { BrainLobe, StriatumLobe } from '6_media';

const GeneralBrainLobe = ({
  lobeType = 'Global Lobe', // "Frontal Lobe", "Parietal Lobe", "Temporal Lobe", "Occipital Lobe"
  colorMap,
  lobe1: frontalRatio,
  lobe2: parietalRatio,
  lobe3: temporalRatio,
  lobe4: occipitalRatio,
}) => {
  const DEFAULT_COLOR = 'gray';
  const getColor = (ratio, type) => {
    return lobeType === 'Global Lobe' || lobeType === type
      ? colorMap[ratio]
      : DEFAULT_COLOR;
  };

  const frontalColor = getColor(frontalRatio, 'Frontal Lobe');
  const parietalColor = getColor(parietalRatio, 'Parietal Lobe');
  const temporalColor = getColor(temporalRatio, 'Temporal Lobe');
  const occipitalColor = getColor(occipitalRatio, 'Occipital Lobe');

  return (
    <div style={{ width: '55%' }}>
      <BrainLobe
        fillFrontal={frontalColor}
        fillParietal={parietalColor}
        fillTemporal={temporalColor}
        fillOccipital={occipitalColor}
      />
    </div>
  );
};

const StriatumLobeDiv = ({
  lobeType = 'Dorsal striatum', // "Dorsal striatum", "Striatum", "Caudate", "Putamen", "Putamen A" , "Putamen P"
  colorMap,
  lobe1: caudateARatio,
  lobe2: caudatePRatio,
  lobe3: putamenARatio,
  lobe4: putamenPRatio,
}) => {
  const DEFAULT_COLOR = 'gray';

  let caudateAColor = DEFAULT_COLOR;
  let caudatePColor = DEFAULT_COLOR;
  let putamenAColor = DEFAULT_COLOR;
  let putamenPColor = DEFAULT_COLOR;

  if (lobeType === 'Dorsal striatum' || lobeType === 'Striatum') {
    caudateAColor = colorMap[caudateARatio];
    caudatePColor = colorMap[caudatePRatio];
    putamenAColor = colorMap[putamenARatio];
    putamenPColor = colorMap[putamenPRatio];
  } else if (lobeType === 'Caudate') {
    caudateAColor = colorMap[caudateARatio];
    caudatePColor = colorMap[caudatePRatio];
  } else if (lobeType === 'Putamen') {
    putamenAColor = colorMap[putamenARatio];
    putamenPColor = colorMap[putamenPRatio];
  } else if (lobeType === 'Putamen A') {
    putamenAColor = colorMap[putamenARatio];
  } else if (lobeType === 'Putamen P') {
    putamenPColor = colorMap[putamenPRatio];
  }

  return (
    <div style={{ width: '55%' }}>
      <StriatumLobe
        fillCaudateA={caudateAColor}
        fillCaudateP={caudatePColor}
        fillPutamenA={putamenAColor}
        fillPutamenP={putamenPColor}
      />
    </div>
  );
};

export const BrainLobeWrapper = (productName, renderOptions) => {
  if (productName === 'dat') {
    return StriatumLobeDiv(renderOptions);
  } else {
    return GeneralBrainLobe(renderOptions);
  }
};
